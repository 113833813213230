import BigNumber from 'bignumber.js';
import moment from 'moment';
import Web3 from 'web3';
import config  from '../../app-config.json';
import { currentAPIUrl } from './env.utils';

BigNumber.config({ ROUNDING_MODE: BigNumber.ROUND_DOWN });
BigNumber.config({ EXPONENTIAL_AT: 100 });

(window as any).ethereum?.on('chainChanged', (chainIdHex: string) => {
  const chainId = parseInt(chainIdHex, 16);
  const env = window.location.href.startsWith('https://test') ? 'test' : 'production';
  const chains = (config as Config).environments[env].chains;

  for (const [chainName, values] of Object.entries(chains)){
    if(+values.VUE_APP_NETWORK_ID === chainId){
      localStorage.setItem('currentChain', chainName);
    }
  }
  window.location.reload();
});

export function getChainName(chainId: number): string {
  const env = window.location.href.startsWith('https://test') ? 'test' : 'production';

  const chains = (config as Config).environments[env].chains;

  for (const [chainName, values] of Object.entries(chains)){
    if(+values.VUE_APP_NETWORK_ID === chainId){
      return chainName;
    }
  }

  return '';
}

export const apiUrl = (url: string) : string => `${currentAPIUrl}/${url}`;

export const fromWeiEther = (value: string | BigNumber): string => {
  return new BigNumber(value).div('1000000000000000000').toFixed();
};

export const toWeiEther = (value: string | BigNumber): string => {
  return new BigNumber(value).multipliedBy('1000000000000000000').toFixed();
};

export function convertStringToDecimal(val: string, maxDecimals: number) : string {
  return new BigNumber(val).toFixed(maxDecimals);
}

export function timeFormat(value: number): string{
  return moment(value).format('h:mm:ss a');
}

export function dateFormat(value:number): string{
  return moment(value).format('dddd, MMMM Do YYYY');
}

export function secondsToDDHHMMSS (sec: number): string {
  const days = Math.floor(sec/(60*60*24));
  const hours = Math.floor(sec/(60*60)%24);
  const minutes = Math.floor(sec/(60))%60;
  const seconds = sec%60;

  return `${days !== 0 && days.toFixed(0) + 'd ' || ''}` + `${hours !== 0 && ('0' + hours).slice(-2) + 'h ' || ''}` +
    `${minutes !== 0 &&('0' + minutes).slice(-2) + 'm ' || ''}` + `${seconds !== 0 && ('0' + seconds).slice(-2) + 's' || ''}`;
}

export const toBN = (value: string | number): BigNumber => {
  const valueString = typeof value === 'string' ? value : String(value);

  return new BigNumber(valueString);
};

export function isValidForResultCount(tabName: string) : boolean{
  return !['skillshop','others'].includes(tabName);
}


export function convertSkillToWei(skill: string) : string {
  return Web3.utils.toWei(skill);
}
export function convertAddressToChecksum(defaultAccount: string) : string {
  return Web3.utils.toChecksumAddress(defaultAccount);
}

export const currentChainSupportsMarket = (): boolean => {
  const currentChain = localStorage.getItem('currentChain') || 'BNB';
  const marketSupportedChains = config.supportedChains;
  if (!currentChain || !marketSupportedChains) {
    return false;
  }
  return marketSupportedChains.includes(currentChain);
};

interface Config {
  environments: Record<string, Chain>;
}
interface Chain {
  chains: Record<string, Record<string, any>>;
}

export function ucFirst(text: string): string {
  return text.charAt(0).toUpperCase() + text.slice(1);
}
