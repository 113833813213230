



















































import Vue from "vue";
import SortFilter from "../SortFilter.vue";
import {mapGetters} from "vuex";
import { getConfigValue } from "@/contracts";

interface Tab {
  name: string;
  title?: string;
  url: string;
  icon: string;
}

export default Vue.extend({
  name: "SellInventory",
  components: {
    SortFilter
  },
  data() {
    return {
      tabs: [] as Tab[],
      currentTab: "",
      filterIsToggled: false,
      isLoading: false
    };
  },
  computed:{
    networkId(): string {
      return getConfigValue('VUE_APP_NETWORK_ID');
    },
    ...mapGetters(['getNftConfigs'])
  },
  mounted(){
    for(const key in this.getNftConfigs){
      this.tabs.push({
        name: 'Inventory' + this.getNftConfigs[key].name,
        title: this.getNftConfigs[key].name,
        url: `${key}`,
        icon: require(`@/assets/inventory-icons/${key}-icon.png`)
      });
    }
    this.tabs.push({
      name: "inventory-skill-shop",
      url: "skillshop",
      title: "Skill Shop",
      icon: require(`@/assets/inventory-icons/other-nft-icon.png`),
    });
  },
  methods: {
    selectTab(tab: any) {
      this.currentTab = tab;
    }
  }
});
