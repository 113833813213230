import { getElementNumberToName } from "@/default/element.default";
import { ICharacter } from "@/interface/character.interface";
import { Nft } from "@/interface/nft.interface";

export function timestampToStamina(timestamp: number) : number {
  if(timestamp > Math.floor(Date.now()/1000)) return 0;
  return +Math.min((Math.floor(Date.now()/1000) - timestamp) / 300, 200).toFixed(0);
}

export function characterFromContract(id: string | number, data: string[], version: string): ICharacter {
  const xp = data[0];
  const level = parseInt(data[1], 10);
  const trait = data[2];
  const traitName = getElementNumberToName(+data[2]);
  const staminaTimestamp = data[3];
  const head = data[4];
  const arms = data[5];
  const torso = data[6];
  const legs = data[7];
  const boots = data[8];
  const race = data[9];
  return { id: +id, xp, level, trait, traitName, staminaTimestamp, head, arms, torso, legs, boots, race, version: +version };
}

export interface ICharacterApi {
  charId: string;
  charLevel: number;
  charElement: string;
  price: number;
  timestamp: number;
  sellerAddress: string;
  network: string;
}

export function characterFromAPI(characterApi: ICharacterApi): Nft {
  const id = +characterApi.charId;
  const element = characterApi.charElement;
  const level = +characterApi.charLevel;
  const price = characterApi.price;
  const sellerAddress = characterApi.sellerAddress;

  return {
    id,
    element,
    level,
    price,
    sellerAddress,
  } as Nft;
}
