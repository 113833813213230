
























import Vue from 'vue';
import NftList from '@/components/NftList.vue';
import {mapGetters,mapState} from "vuex";

export default Vue.extend({
  components: {NftList},
  computed:{
    ...mapGetters(['getNftConfigs']),
    ...mapState(['defaultAccount'])
  },
});
