











































import Vue from 'vue';
import { MAX_LOW_STAR_BURN_POINTS, MAX_FOUR_STAR_BURN_POINTS, MAX_FIVE_STAR_BURN_POINTS } from '../default/dust.default';
import {setRarityName, setRarityColor, elementToStat} from "@/utils/item.utils";
import CBIcon from './CBIcon.vue';
import { getWeaponNameFromSeed } from '../weapon-names';
import { getCleanName } from "@/utils/rename-censor";
import { mapGetters, mapActions } from 'vuex';
import CurrencyConverter from './CurrencyConverter.vue';
import ElementTrait from './ElementTrait.vue';
export default Vue.extend({
  components:{
    CBIcon,
    CurrencyConverter,
    ElementTrait
  },
  props:{
    nft:{
      type: Object,
      required: true
    },
    nftType:{
      type: String,
      required: true
    },
    isSold:{
      type: Boolean,
      default: false
    }
  },
  computed:{
    ...mapGetters(['getCharacterName'])
  },
  data(){
    return{
      MAX_LOW_STAR_BURN_POINTS,
      MAX_FOUR_STAR_BURN_POINTS,
      MAX_FIVE_STAR_BURN_POINTS
    };
  },
  methods:{
    elementToStat,
    setRarityName,
    setRarityColor,
    viewNft(){
      if(this.isSold) return '';
      let routeData = this.$router.resolve({path: `/buy/${this.nftType}?id=${this.nft.id}`});
      window.open(routeData.href, '_blank');
    },
    getNftName(){
      switch(this.nftType){
      case 'cb-weapon':
        return getWeaponNameFromSeed(this.nft.id, this.nft.stars);
      case 'cb-character':
        return this.getCleanCharacterName(this.nft.id);
      case 'cb-shield':
        return this.nft.shieldFlag === 1 ? 'Templars Will' : 'Legendary Defender';
      }
    },
    getCleanCharacterName(id: number) {
      return getCleanName(this.getCharacterName(id));
    },
  }
});
