













import Vue from 'vue';
import DashboardNft from '@/components/DashboardNft.vue';
import Spinner from './dumb/cryptoblades-spinner.vue';
import {mapActions, mapGetters, mapMutations} from 'vuex';
import {store} from '@/store';
export default Vue.extend({
  props: {
    nftList: {
      type: Array,
      required: true,
    },
    nftType:{
      type: String,
      required: true
    },
    isSold:{
      type: Boolean,
      default: false
    }
  },
  data(){
    return{
      nftListFromChain: []
    };
  },
  computed:{
    ...mapGetters(['getLoadingState', 'getNftConfigs']),
    nftListIds(): number[]{
      return this.nftList.map((nft: any) => nft.props.id);
    }
  },
  async mounted(){
    store.commit('setLoadingState', {type: this.nftType + (this.isSold ? '-sold' : ''), isLoading: true});
    this.nftListFromChain = await this.fetchNftInfo({
      nftIds: this.nftListIds,
      nftType: this.nftType
    });
    store.commit('setLoadingState', {type: this.nftType + (this.isSold ? '-sold' : ''), isLoading: false});
  },
  methods:{
    ...mapActions(['fetchNftInfo']),
  },
  components:{
    DashboardNft,
    Spinner
  }
});
