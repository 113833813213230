
















import Vue from 'vue';
import  {mapState } from "vuex";
import { timeFormat, dateFormat } from '@/utils/common';
import CurrencyConverter from '../CurrencyConverter.vue';

export default Vue.extend({
  name: 'SellTransactionHistory',
  components:{CurrencyConverter},
  props:{
    transaction:{
      type: Object,
      required: false
    }
  },
  data() {
    return {
      price: "",
      shieldFlag: '',
    };
  },
  computed:{
    ...mapState(
      [
        'defaultAccount',
        'specialWeaponArts'
      ]
    ),
  },
  methods:{
    timeFormat,
    dateFormat,
  }
});
