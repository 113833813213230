





















































import { apiUrl } from '@/utils/common';
import Vue from 'vue';
import MarketStats from '@/components/MarketStats.vue';
import { DASHBOARD_RECENT_LISTING_PAGE_LIMIT } from '@/default/common.default';
import DashboardNftList from '@/components/DashboardNftList.vue';
import { Nft } from '@/interface/nft.interface';
import { mapGetters } from 'vuex';
import { MarketStat } from '../interface/dashboard.interface';
import Spinner from './dumb/cryptoblades-spinner.vue';

export default Vue.extend({
  components:{
    MarketStats,
    DashboardNftList,
    Spinner
  },
  data(){
    return{
      day: {} as MarketStat,
      week: {} as MarketStat,
      month: {} as MarketStat,
      nftsSold: 0,
      recentListing: {} as Record<string, Nft[]>,
      recentSold: {} as Record<string, Nft[]>,
      isLoading: true,
    };
  },
  computed:{
    ...mapGetters(['getNftConfigs']),
  },
  methods:{
    viewAllItems(){
      let routeData = this.$router.resolve({name: 'Buy'});
      window.open(routeData.href, '_blank');
    },
    async fetchNftsRecentListed(){
      const network = localStorage.getItem('currentChain') || 'BNB';
      const response = await fetch(apiUrl(`nfts/recent/${network}/${DASHBOARD_RECENT_LISTING_PAGE_LIMIT}`));
      const data = await response.json();
      for(const key in this.getNftConfigs){
        this.recentListing[key] = data.recent[key];
      }
    },
    async fetchStats(){
      const network = localStorage.getItem('currentChain') || 'BNB';
      const response = await fetch(apiUrl(`sales/stats/${network}`));
      const data = await response.json();
      for(const key in this.getNftConfigs){
        this.recentSold[key] = data.recent[key];
      }
      this.day = data.stats.day;
      this.week = data.stats.week;
      this.month = data.stats.month;
      this.nftsSold = data.total;
    }
  },
  async mounted(){
    await this.fetchNftsRecentListed();
    await this.fetchStats();
    this.isLoading = false;
  },
});
