












import Vue from 'vue';
import moment from 'moment';
import {mapActions, mapGetters, mapState, mapMutations} from "vuex";
import { getCharacterArt } from '../../utils/character-arts-placeholder';
import { apiUrl, convertAddressToChecksum, fromWeiEther } from '@/utils/common';
import SellTransactionHistoryItem from './SellTransactionHistoryItem.vue';
import { store } from '@/store';
import { mergeQueryParams } from '@/utils/query-params';
// import { fromAPI } from '@/interface/nft.interface';
import _ from 'lodash';
import { marketSortToQueryDict, objToQueryParams } from '@/utils/route.utils';
import { nftImageByType } from '@/utils/arts-generator.util';
export default Vue.extend({
  components: { SellTransactionHistoryItem },
  name: 'SellTransactionHistory',
  data() {
    return {
      transactions:[],
      numPages: null,
      isFirstLoad: true,
    };
  },
  computed:{
    ...mapState(
      [
        'defaultAccount',
      ]
    ),
    ...mapGetters(['characterContractAddress','getTxnHistory','getTransactionHistoryPagination','getGlobalFilter']),
    filteredTxnHistory(): any[]{
      return Object.values(this.getTxnHistory).filter((txn: any) => Object.keys(txn).length !== 0);
    }
  },
  mounted(){
    this.clearTxnHistoryItems();
    const listElm = document.querySelector('#infinite-list');
    listElm?.addEventListener('scroll', e => {
      if((Math.round(listElm.scrollTop + listElm.clientHeight) >= listElm.scrollHeight) && (this.numPages !== this.getTransactionHistoryPagination.currentPage) && (!this.isFirstLoad)) {
        store.commit('setTransactionHistoryListCurrentPage', this.getTransactionHistoryPagination.currentPage + 1);
        this.fetchTxnHistoryFromApi();
      }
    });
    this.fetchTxnHistoryFromApi();
    this.isFirstLoad = false;
  },
  methods:{
    ...mapActions(
      [
        'txnHistoryListUpdate','updateTxnHistoryCharacters','updateTxnHistoryWeapon','updateTxnHistoryShields', 'lookupWeaponPrice','fetchMarketNftPrice' 
      ]
    ),
    ...mapMutations(['clearTxnHistoryItems']),
    convertWeiToSkill(wei: string) {
      return fromWeiEther(wei);
    },
    getCharacterArt,
    timeFormat(value: number){
      return moment(value).format('h:mm:ss a');
    },
    dateFormat(value:number){
      return moment(value).format('dddd, MMMM Do YYYY');
    },
    async fetchTxnHistoryFromApi(){
      const network = localStorage.getItem('currentChain') || 'BNB';
      store.commit('setGlobalFilter', {
        type: 'cb',
        subtype: '',
        seller: convertAddressToChecksum(this.defaultAccount),
        network: network,
      });
      try {
        const paginationFilter = `pageNum=${this.getTransactionHistoryPagination.currentPage}`;
        const sorts = objToQueryParams(this.getGlobalFilter);
        const queryParams = mergeQueryParams(paginationFilter, sorts);
        const response = await fetch(apiUrl(`sales${queryParams}`));
        const data = await response.json();
        let tempData: any = [];
        store.commit('setTransactionHistoryPagination', {
          pageSize: data.page.pageSize,
          totalItems: data.page.total
        });
        this.numPages = data.page.numPages;
        data.results.map((transaction : any, index: number) => {
          const transactionModified = {
            type: transaction.type,
            timestamp: transaction.timestamp,
            image: nftImageByType(
              transaction.type,
              transaction.props
            ),
            price: transaction.price,
            id: transaction.props.id
          };
          tempData.push(transactionModified);
        });
        this.txnHistoryListUpdate(_.concat(this.getTxnHistory, tempData));
      // eslint-disable-next-line no-empty
      } catch (error) {}
    }
  }
});
