


















































import Vue from 'vue';
import { MarketStat } from '../interface/dashboard.interface';
export default Vue.extend({
  props:{
    stat:{
      required: false,
      type: Object as () => MarketStat,
    },
    nftsSold:{
      required: false,
      type: Number,
      default: 0
    },
  },
  methods:{
    formatDecimal(amount: number){
      if(!amount) return 0;
      return amount.toFixed(2);
    },
  }
});
