import img1 from './assets/weapons/weapon2.png';
import img2 from './assets/weapons/weapon2.png';
import img3 from './assets/weapons/weapon3.png';
import img4 from './assets/weapons/weapon4.png';
import img5 from './assets/weapons/weapon5.png';
import img6 from './assets/weapons/weapon6.png';
import img7 from './assets/weapons/weapon7.png';

import specialImg1 from './assets/weapons/special-weapons/heco-sword.png';
import specialImg2 from './assets/weapons/special-weapons/blazing-mane.png';
import specialImg3 from './assets/weapons/special-weapons/pluto-alliance.png';


import { IWeapon } from './interface/weapon.interface';

const allImages = [img1, img2, img3, img4, img5, img6, img7];
const specialImages = [specialImg1, specialImg2, specialImg3];

export function getWeaponArt(weapon: IWeapon) : string {
  if (!weapon) {
    return "";
  }

  if(weapon.weaponType > 0) {
    return specialImages[weapon.weaponType - 1];
  }

  return allImages[weapon.id % allImages.length];
}
