import type {getNFTCall, callData} from '@/interface/multicall.interface';

export function getNFTCall(abi: any, address: any, name: string, params: any[]): any {
  const calls: callData[] = params.map((param: string[]) => ({
    address,
    name,
    params: param,
  }));
  return {
    abi,
    calls,
  };
}
