


















































































import Vue from 'vue';
import CurrencyConverter from '@/components/CurrencyConverter.vue';
import {fromWeiEther, toBN} from '@/utils/common';
import {mapActions, mapState} from 'vuex';
import api from '@/api';
import {MerchandiseOrder, CartEntry, FileType, OrderItem, Recipient} from '@/interface/merchandise.interface';
import BigNumber from 'bignumber.js';
import Web3 from 'web3';

interface StoreMappedActions {
  currentSkillPrice(): Promise<string>;

  createOrder(payload: { orderNumber: number; payingAmount: string }): Promise<void>;

  canUserAfford(payload: { payingAmount: BigNumber }): Promise<boolean>;
}

export interface ShippingRate {
  currency: string;
  id: string;
  maxDeliveryDays: number;
  minDeliveryDays: number;
  name: string;
  rate: string;
}

interface Data {
  showModal: boolean;
  cartEntries: CartEntry[];
  recipient: Recipient;
  skillPrice: number;
  totalPrice: number;
  totalPriceInSkill: number;
  isOrderLoading: boolean;
  areShippingRatesLoading: boolean;
  shippingMethod: string;
  shippingRates: ShippingRate[];
  selectedShippingRate?: ShippingRate;
  canAffordMerch: boolean;
}

export default Vue.extend({
  data() {
    return {
      showModal: false,
      cartEntries: [],
      recipient: {} as Recipient,
      skillPrice: 0,
      totalPrice: 0,
      totalPriceInSkill: 0,
      isOrderLoading: false,
      areShippingRatesLoading: false,
      shippingMethod: '',
      shippingRates: [],
      selectedShippingRate: undefined,
      canAffordMerch: false,
    } as Data;
  },

  props: {
    showFiatPrices: {
      type: Boolean
    },
  },

  components: {CurrencyConverter},
  computed: {
    ...mapState(['defaultAccount']),
  },
  methods: {
    ...mapActions(['currentSkillPrice', 'createOrder', 'canUserAfford']) as StoreMappedActions,
    fromWeiEther,
    toBN,
    isFileTypePreview(file: File) {
      return file.type === FileType.PREVIEW;
    },
    usdToSkill(cartEntry: CartEntry) {
      if (!cartEntry?.variant) return;
      return +cartEntry.variant.retail_price * this.skillPrice;
    },
    async confirmOrder() {
      if (!this.selectedShippingRate) return;
      const orderItems = this.cartEntries.map(cartEntry => {
        return {
          external_variant_id: cartEntry.variant.external_id,
          quantity: cartEntry.quantity
        } as OrderItem;
      });
      const merchandiseOrder: MerchandiseOrder = {
        recipient: this.recipient,
        items: orderItems,
        shipping: this.selectedShippingRate.id,
        wallet: this.defaultAccount,
        currentChain: localStorage.getItem('currentChain') || 'BNB',
      };

      try {
        this.$root.$emit('merchandise-order-loading', true);
        this.isOrderLoading = true;
        const response = await api.createMerchandiseOrder(merchandiseOrder);
        if (response.totalPriceInSkill) {
          await this.createOrder({
            orderNumber: response.result.id,
            payingAmount: Web3.utils.toWei(response.totalPriceInSkill.toString(), 'ether'),
          });
          this.$root.$emit('order-complete-modal', response.result.id, response.result.shipping_service_name);
        }
      } catch (e) {
        this.$root.$emit('order-error-modal', e);
        console.error(e);
      } finally {
        this.$root.$emit('merchandise-order-loading', false);
        this.isOrderLoading = false;
        this.showModal = false;
      }
    },
    getTotalCartPrice() {
      return this.cartEntries.map(cartEntry => +cartEntry.variant.retail_price * cartEntry.quantity)
        .reduce((a, b) => a + b, 0);
    },
    calculateShippingPriceInSkill(price: number) {
      return price * this.skillPrice;
    },
    async calculateTotalPrice() {
      this.totalPrice = this.getTotalCartPrice() + this.getShippingPrice();
      this.totalPriceInSkill = this.totalPrice * this.skillPrice;
      this.canAffordMerch = await this.fetchCanAffordMerch();
    },
    getShippingPrice() {
      return this.selectedShippingRate ? +this.selectedShippingRate.rate : 0;
    },

    async fetchCanAffordMerch() {
      return await this.canUserAfford({payingAmount: toBN(this.totalPriceInSkill)});
    },

    async getShippingRates() {
      const orderItems = this.cartEntries.map(cartEntry => {
        return {
          external_variant_id: cartEntry.variant.external_id,
          quantity: cartEntry.quantity
        } as OrderItem;
      });
      const merchandiseOrder: MerchandiseOrder = {
        recipient: this.recipient,
        items: orderItems
      };
      const response = await api.getShippingRates(merchandiseOrder);
      if (response.code !== 200) {
        return;
      }
      this.shippingRates = response.result;
    }
  },

  async mounted() {
    this.$root.$on('order-summary-modal', async (recipient: Recipient) => {
      if (recipient) {
        this.selectedShippingRate = undefined;
        this.cartEntries = this.$store.getters.getCartEntries;
        this.recipient = recipient;
        this.showModal = true;
        this.skillPrice = +await this.currentSkillPrice();
        await this.calculateTotalPrice();
        try {
          this.areShippingRatesLoading = true;
          await this.getShippingRates();
        } finally {
          this.areShippingRatesLoading = false;
        }
        await this.calculateTotalPrice();
      } else {
        this.showModal = false;
      }
    });
  }
});
