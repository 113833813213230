






















































































import Vue from "vue";
import { mapActions, mapMutations } from "vuex";
import { fromWeiEther, toBN } from "@/utils/common";
import api from "@/api";
import {
  CartEntry,
  Product,
  DefaultPrices,
  Variant,
  ProcessedProductsData
} from "@/interface/merchandise.interface";
import { PropType } from "vue/types/options";
import CBIcon from "@/components/CBIcon.vue";
import CurrencyConverter from "@/components/CurrencyConverter.vue";

declare module "vue/types/vue" {
  interface Vue {
    products: Product[];
    productsPerPage: Product[];
    selectedProduct?: Product;
    isVariantModalLoading: boolean;
    currentPage: number;
    perPage: number;
    offset: number;
    limit: number;
    defaultPrices: DefaultPrices[];
    skillPrice: number;
    currentSkillPrice: () => Promise<string>;
    loadProductsAndDefaultPrice: (currentPage: number) => Promise<void>;
    fetchProducts: () => Promise<void>;
    isMobile: () => boolean;
    fetchVariants: (productId: number) => Promise<Variant[] | undefined>;
    usdToSkill: () => number | undefined;
    toggleShowFiatPrices: () => void;
    fetchProductDefaultPrice: (
      productId: number
    ) => Promise<string | undefined>;
  }
}

interface Data {
  products: Product[];
  productsPerPage: Product[];
  selectedProduct?: Product;
  isVariantModalLoading: boolean;
  currentPage: number;
  perPage: number;
  offset: number;
  limit: number;
  defaultPrices: DefaultPrices[];
  skillPrice: number;
}

interface StoreMappedActions {
  getItemPrice(payload: { id: number }): Promise<number>;

  currentSkillPrice(): Promise<string>;

  fetchProcessedProducts(payload: unknown): Promise<ProcessedProductsData[]>;
}

interface StoreMappedMutations {
  setMerchandisePage(page: number): void;
}

export default Vue.extend({
  components: { CBIcon, CurrencyConverter },

  data(): Data {
    return {
      products: [],
      productsPerPage: [],
      selectedProduct: undefined,
      isVariantModalLoading: false,
      currentPage: 1,
      perPage: 30,
      offset: 0,
      limit: 100,
      defaultPrices: [],
      skillPrice: 0
    } as Data;
  },

  props: {
    isOrderLoading: {
      type: Boolean
    },
    cartEntries: {
      type: Array as PropType<CartEntry[]>,
      required: true
    },
    showFiatPrices: {
      type: Boolean
    }
  },

  computed: {
    rows(): number {
      return this.$data.productsPerPage.length;
    }
  },

  methods: {
    ...(mapActions([
      "getItemPrice",
      "currentSkillPrice",
      "fetchProcessedProducts"
    ]) as StoreMappedActions),
    ...(mapMutations(["setMerchandisePage"]) as StoreMappedMutations),
    fromWeiEther,
    toBN,

    toggleShowFiatPrices() {
      this.$root.$emit("toggle-fiat-prices");
    },

    usdToSkill(price: CartEntry): number | undefined {
      if (!price) return;
      return +price * this.skillPrice;
    },

    async fetchProducts(): Promise<void> {
      let result = [];
      do {
        const response = await api.getMerchandiseProducts(
          this.limit,
          this.offset
        );
        if (response.code !== 200) {
          return;
        }
        result = response.result;
        this.products.push(...result);
        this.offset += 100;
      } while (result.length === 100);
    },

    async fetchVariants(productId: number): Promise<Variant[] | undefined> {
      const response = await api.getMerchandiseProductVariants(productId);
      if (response.code !== 200) {
        return;
      }
      return response.result.sync_variants;
    },

    async loadProductsAndDefaultPrice(currentPage: number): Promise<void> {
      const filters = JSON.parse(localStorage.getItem("merchFilter") || "");

      const payload = {
        products: this.products,
        currentPage: currentPage,
        perPage: this.perPage,
        filters: filters
      };

      this.productsPerPage = await this.fetchProcessedProducts({ payload });

      this.setProductDefaultPrice();
    },

    async setProductDefaultPrice() {
      this.defaultPrices = [];

      this.productsPerPage.forEach(async product => {
        const productPrice = await this.fetchProductDefaultPrice(product.id);

        this.defaultPrices.push({
          product_id: product.id,
          price: productPrice
        });
      });
    },

    async fetchProductDefaultPrice(
      productId: number
    ): Promise<string | undefined> {
      const response = await api.getMerchandiseProductVariants(productId);
      if (response.code !== 200) {
        return;
      }
      return response.result.sync_variants[0].retail_price;
    },

    async filterValueHandler() {
      this.currentPage = 1;

      this.loadProductsAndDefaultPrice(this.currentPage);
    },

    async openChooseVariantModal(product: Product): Promise<void> {
      try {
        this.isVariantModalLoading = true;
        this.selectedProduct = product;
        const variants = await this.fetchVariants(product.id);
        const skillPrice = +(await this.currentSkillPrice());
        this.$root.$emit(
          "merchandise-variant-modal",
          this.selectedProduct,
          variants,
          skillPrice
        );
      } finally {
        this.isVariantModalLoading = false;
      }
    },
    isMobile(): boolean {
      return screen.width <= 576;
    }
  },

  async mounted() {
    if (this.isMobile()) {
      this.perPage = 10;
    }
    this.skillPrice = +(await this.currentSkillPrice());
    await this.fetchProducts(),
    await this.loadProductsAndDefaultPrice(this.currentPage);

    this.$root.$on("filter-value-merchandise", this.filterValueHandler);
  },

  destroyed() {
    this.$root.$off("filter-value-merchandise");
  }
});
