

























import { Nft } from '@/interface/nft.interface';
import {addCommas, totalBattlePower, elementToStat} from "@/utils/item.utils";
import Vue, { PropType } from 'vue';
import ElementTrait from './ElementTrait.vue';
export default Vue.extend({
  components: { ElementTrait },
  props:{
    item:{
      required: true,
      type: Object as PropType<Nft>,
    }
  },
  methods: {
    addCommas,
    totalBattlePower,
    elementToStat
  }
});
