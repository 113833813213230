import { IJunk } from "@/interface/junk.interface";
import { Nft } from "@/interface/nft.interface";

export function junkFromContract(id: string | number, 
  stars: string): IJunk {
  return {
    id: +id,
    stars: +stars,
    type: 'junk'
  };
}

export interface IJunkApi {
  junkId: string;
  junkType: number;
  junkStars: number;
  price: number;
  timestamp: number;
  sellerAddress: string;
  network: string;
}

export function junkFromAPI(junkApi: IJunkApi): Nft {
  const id = +junkApi.junkId;
  const stars = junkApi.junkStars;
  const price = junkApi.price.toString();
  const sellerAddress = junkApi.sellerAddress;
  const junkType = junkApi.junkType;

  return {
    id,
    stars,
    price,
    sellerAddress,
    junkType
  } as Nft;
}
