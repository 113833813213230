








import Vue from 'vue';
import SellInventory from './SellInventory.vue';
import SellMyListings from './SellMyListings.vue';
import SellTransactionHistory from './SellTransactionHistory.vue';
import {tabs} from "@/views/Sell.vue";

export default Vue.extend({
  components: {SellInventory, SellMyListings, SellTransactionHistory},
  props: ['currentTab'],
  data() {
    return {
      tabs,
    };
  },
});
