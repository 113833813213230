

















import MarketFilter from '@/components/MarketSidebarFilter.vue';
import Vue from 'vue';
import BuyMain from '../components/BuyMain.vue';
import router from '@/router';

export default Vue.extend({
  data() {
    return {
      isLoading: false,
      filterIsToggled: false
    };
  },
  components: {
    BuyMain,
    MarketFilter,
  },
  mounted(){
    this.$root.$on('toggle', (bol:boolean) => {
      this.filterIsToggled = bol;
    });
  },
});
