
import { CharacterTrait, ICharacter } from '@/interface/character.interface';
import WaterKnight from './../assets/characters/KnightWater.png';
import LightningKnight from './../assets/characters/KnightLightning.png';
import FireKnight from './../assets/characters/KnightFire.png';
import EarthKnight from './../assets/characters/KnightEarth.png';
import Archer from './../assets/characters/Archer.png';
import Mage from './../assets/characters/Mage.png';
import Paladin from './../assets/characters/Paladin.png';
import Spearman from './../assets/characters/Spearman.png';

const characterImages = {earth: EarthKnight, fire: FireKnight, water: WaterKnight, lightning: LightningKnight};
const characterV2Images = {earth: Spearman, fire: Archer, water: Mage, lightning: Paladin};

export function getCharacterArt(character: ICharacter) : string {
  if (!character) {
    return "";
  }

  if(character.version === 0) {
    switch (+character.trait) {
    case CharacterTrait.Earth: return characterImages.earth;
    case CharacterTrait.Fire: return characterImages.fire;
    case CharacterTrait.Water: return characterImages.water;
    case CharacterTrait.Lightning: return characterImages.lightning;
    default: return characterImages.earth;
    }
  }
  else {
    switch (+character.trait) {
    case CharacterTrait.Earth: return characterV2Images.earth;
    case CharacterTrait.Fire: return characterV2Images.fire;
    case CharacterTrait.Water: return characterV2Images.water;
    case CharacterTrait.Lightning: return characterV2Images.lightning;
    default: return characterV2Images.earth;
    }
  }
}
