
















































































import Vue from "vue";
import MerchandiseList from "@/components/MerchandiseList.vue";
import MerchandiseCart from "@/components/MerchandiseCart.vue";
import VariantChoiceModal from "@/components/modal/VariantChoiceModal.vue";
import { BModal } from "bootstrap-vue";
import OrderSummaryModal from "@/components/modal/OrderSummaryModal.vue";
import ShippingInfo from "@/components/ShippingInfo.vue";
import { mapGetters, mapMutations } from "vuex";
import { Accessors } from "vue/types/options";
import { CartEntry } from "@/interface/merchandise.interface";

interface StoreMappedMutations {
  clearCartEntries(): void;
  setMerchandisePage(page: number): void;
}

interface StoreMappedGetters {
  getCartEntries: CartEntry[];
  getMerchandisePage: number;
}

interface Data {
  cartEntries: CartEntry[];
  orderNumber: number;
  shipping: string;
  isOrderLoading: boolean;
  showFiatPrices: boolean;
  currentPage: number;
  pageTitle: string[];
  totalPriceInSkill: number;
}

export default Vue.extend({
  components: {
    MerchandiseList,
    MerchandiseCart,
    OrderSummaryModal,
    ShippingInfo,
    VariantChoiceModal
  },

  data() {
    return {
      cartEntries: [],
      orderNumber: 0,
      shipping: "",
      isOrderLoading: false,
      showFiatPrices: true,
      currentPage: 2,
      pageTitle: ["YOUR CART", "CHECKOUT"],
      totalPriceInSkill: 0
    } as Data;
  },

  methods: {
    ...(mapMutations([
      "clearCartEntries",
      "setMerchandisePage"
    ]) as StoreMappedMutations),
    isCartEntryDuplicated(cartEntry: CartEntry) {
      return this.cartEntries.find(
        entry => entry.variant.id === cartEntry.variant.id
      );
    }
  },

  computed: {
    ...(mapGetters(["getCartEntries", "getMerchandisePage"]) as Accessors<
      StoreMappedGetters
    >)
  },

  mounted() {
    this.cartEntries = this.getCartEntries;
    this.$root.$on(
      "order-complete-modal",
      (orderNumber: number, shipping: string) => {
        const modal = this.$refs["order-complete-modal"] as BModal;
        if (modal) {
          if (orderNumber) {
            this.orderNumber = orderNumber;
            this.shipping = shipping;
            this.clearCartEntries();
            this.cartEntries = this.getCartEntries;
            modal.show();
          } else {
            modal.hide();
          }
        }
      }
    );
    this.$root.$on("order-error-modal", (error: any) => {
      const modal = this.$refs["order-error-modal"] as BModal;
      if (modal) {
        if (error) {
          modal.show();
        } else {
          modal.hide();
        }
      }
    });
    this.$root.$on("merchandise-order-loading", (isOrderLoading: boolean) => {
      this.isOrderLoading = isOrderLoading;
    });
    this.$root.$on("toggle-fiat-prices", () => {
      this.showFiatPrices = !this.showFiatPrices;
      if (this.showFiatPrices) localStorage.setItem("showFiatPrices", "true");
      else localStorage.setItem("showFiatPrices", "false");
    });
    this.$root.$on("merchandise-address", (totalPriceInSkill: number) => {
      if (totalPriceInSkill) {
        this.totalPriceInSkill = totalPriceInSkill;
      }
    });
  },

  created() {
    this.setMerchandisePage(0);
    localStorage.setItem("merchFilter",JSON.stringify({category:[],subcategory:[]}));
  }
});
