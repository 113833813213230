





























import Vue from 'vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { store } from '@/store';
import { removeEmptyQueryParams } from '@/utils/query-params';
import {isValidForResultCount} from '@/utils/common';
import sortAsc from '@/assets/sort-asc.svg';
import sortDesc from '@/assets/sort-desc.svg';

export default Vue.extend({
  props: {
    config: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      sortDesc,
      sortAsc,
      sort: true,
      minPrice: '',
      maxPrice: '',
      currentTab: '',
      sortValues: [] as {value: string; name: string}[],
      sortBy: '',
      timeout: 0 as any,
    };
  },
  methods: {
    sortUpdate() {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.sort = !this.sort;
        store.commit('setGlobalSort', {
          sortDir: this.sort ? +this.config.ui.sortValues.asc : +this.config.ui.sortValues.desc,
        });
        this.onChangeSortType();
      }, 500);
    },
    isValidForResultCount,
    ...mapActions(['fetchNfts']),
    ...mapMutations(['setGlobalFilter','setWeaponList']),
    onChangeSortType(){
      store.commit('setGlobalSort', {
        sortBy: this.sortBy,
      });
      this.fetchNfts({
        type: this.config.type,
      });
    },
    showFilters() {
      this.$root.$emit("filter-value", true);
    },
    onChangeMinMaxPrice() {
      if (this.timeout) clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        const safeMinMaxFilter = this.getSafeMinMaxValue(
          this.minPrice,
          this.maxPrice
        );

        store.commit('setGlobalFilter', safeMinMaxFilter);
        this.$router.replace({name: this.$route.name || '', params: this.$route.params, query: {...removeEmptyQueryParams({ ...this.$route.query, ...safeMinMaxFilter, page: '1'})} }).catch(err => err);

        this.fetchNfts({
          type: this.config.type,
        });
      }, 500);
    },
    getSafeMinMaxValue(minPrice: string, maxPrice: string) {
      const min = Math.min(
        minPrice ? +minPrice : 0,
        maxPrice ? +maxPrice : Number.MAX_SAFE_INTEGER
      );
      const max = Math.max(
        minPrice ? +minPrice : Number.MIN_SAFE_INTEGER,
        maxPrice ? +maxPrice : 0
      );

      return {
        minPrice: minPrice ? min.toString() : "",
        maxPrice: maxPrice ? max.toString() : ""
      };
    },
    tabChangeHandler(tab: string) {
      this.currentTab = tab;
      this.resetInitData();
    },
    resetInitData() {
      this.minPrice = "";
      this.maxPrice = "";
    },
    initParamsData() {
      const query = this.$route.query;

      if (query["minPrice"]) this.minPrice = query["minPrice"] as string;

      if (query["maxPrice"]) this.maxPrice = query["maxPrice"] as string;

      const safeMinMaxFilter = this.getSafeMinMaxValue(this.minPrice, this.maxPrice);
      store.commit('setGlobalFilter', safeMinMaxFilter);
    }
  },
  created() {
    this.initParamsData();
  },
  mounted() {
    this.$root.$on("tab-changed", this.tabChangeHandler);
  },
  destroyed() {
    this.$root.$off("tab-changed", this.tabChangeHandler);
  },
  computed:{
    ...mapGetters(['getFetchWeaponlistLoadingState', 'getFetchCharacterlistLoadingState', 'getFetchShieldAndArmorListLoadingState','getTotalItems','getGlobalFilter','weapons','shields','characters','shieldContractAddress','characterContractAddress','weaponContractAddress']),
    sortImage() : string{
      return this.sort ? this.sortAsc : this.sortDesc;
    },
    isFetching(): boolean {
      return (
        this.getFetchWeaponlistLoadingState ||
        this.getFetchCharacterlistLoadingState ||
        this.getFetchShieldAndArmorListLoadingState
      );
    }
  },
});
