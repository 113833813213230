const EarthKnight = require('@/assets/characters/KnightEarth.png');
const FireKnight = require('@/assets/characters/KnightFire.png');
const LightningKnight = require('@/assets/characters/KnightLightning.png');
const WaterKnight = require('@/assets/characters/KnightWater.png');
const Spearman = require("@/assets/characters/Spearman.png");
const Archer = require("@/assets/characters/Archer.png");
const Mage = require("@/assets/characters/Mage.png");
const Paladin = require("@/assets/characters/Paladin.png");

const characterImages = {earth: EarthKnight, fire: FireKnight, water: WaterKnight, lightning: LightningKnight};
const characterV2Images = {earth: Spearman, fire: Archer, water: Mage, lightning: Paladin};

const CharacterTrait = {
  Fire: 0,
  Earth: 1,
  Lightning: 2,
  Water: 3,
};

module.exports = (character) => {
  if(!character) return;
  if(character.version === 0) {
    switch (+character.trait) {
    case CharacterTrait.Earth: return characterImages.earth;
    case CharacterTrait.Fire: return characterImages.fire;
    case CharacterTrait.Water: return characterImages.water;
    case CharacterTrait.Lightning: return characterImages.lightning;
    default: return characterImages.earth;
    }
  }
  else {
    switch (+character.trait) {
    case CharacterTrait.Earth: return characterV2Images.earth;
    case CharacterTrait.Fire: return characterV2Images.fire;
    case CharacterTrait.Water: return characterV2Images.water;
    case CharacterTrait.Lightning: return characterV2Images.lightning;
    default: return characterV2Images.earth;
    }
  }
};
