import Vue from 'vue';
import App from './App.vue';
import router from './router';
import { BootstrapVue } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import { store } from './store/index';
import _ from 'lodash';
import genericConfig from '../data/generic-config.json';


Vue.config.productionTip = false;
Vue.use(BootstrapVue);
for(const key of genericConfig.packages) {
  const config = require(`../data/nfts/${key}.json`);
  Vue.component(config.type, require(`@/components/${config.template}`).default);
}
Vue.use(_);
Vue.mixin({
  methods:{
    isMobile() : boolean {
      if( screen.width <= 600) {
        return true;
      }
      else {
        return false;
      }
    }
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
