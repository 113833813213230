import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Buy from '../views/Buy.vue';
import Sell from '../views/Sell.vue';
import SellInventory from '@/components/sell/SellInventory.vue';
import SellMyListings from '@/components/sell/SellMyListings.vue';
import SellTransactionHistory from '@/components/sell/SellTransactionHistory.vue';
import Dashboard from '@/components/Dashboard.vue';
import genericConfig from '../../data/generic-config.json';
import SkillList from '../components/SkilllList.vue';
import Merchandise from '../components/Merchandise.vue';
import HecoClaim from '@/components/partner-giveaways/HecoClaim.vue';
Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/buy',
    name: 'Buy',
    component: Buy,
    props: true,
    redirect: to => {
      // the function receives the target route as the argument
      // we return a redirect path/location here.
      return { path: `/buy/${genericConfig.packages[0]}`, meta: { tabName: genericConfig.packages[0] } };
    },
    children: [
      {
        path: 'skillshop',
        name: 'buy-skill-shop',
        components: {
          'skill-list': SkillList,
        },
        meta: { tabName: 'skillshop' }
      },
      {
        path: 'merchandise',
        name: 'Merchandise',
        components: {
          'merchandise-list': Merchandise,
        },
      },
    ],
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    props: true
  },
  {
    path: '/sell',
    name: 'Sell',
    component: Sell,
    props: true,
    redirect: `/sell/inventory`,
    children: [
      {
        path: 'inventory',
        component: SellInventory,
        name: 'Inventory',
        props: true,
        redirect: to => {
          // the function receives the target route as the argument
          // we return a redirect path/location here.
          return { path: `/sell/inventory/${genericConfig.packages[0]}`, meta: { tabName: genericConfig.packages[0] } };
        },
        children: [
          {
            path: 'skillshop',
            name: 'inventory-skill-shop',
            component: SkillList,
            meta: { tabName: 'skillshop' }
          },
        ],
      },
      {
        path: 'my-listings',
        component: SellMyListings,
        name: 'MyListings',
      },
      {
        path: 'transaction-history',
        component: SellTransactionHistory,
        name: 'Transaction History',
      },
    ]
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/',
    redirect: {
      name: 'dashboard',
    }
  },
  {
    path: '/heco-claim',
    component: HecoClaim,
    name: 'Heco Event Claim'
  }
];

const router = new VueRouter({
  mode: 'history',
  linkActiveClass: "",
  linkExactActiveClass: "upperLabel",
  base: process.env.BASE_URL,
  routes
});

export default router;
