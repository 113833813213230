




























































import Vue from 'vue';
import './../mixins/skill-shop-mixins';
import { skillShopMixins } from './../mixins/skill-shop-mixins';
import { fromWeiEther } from '@/utils/common';
import { truncateDecimals } from '@/utils/currency-converter';
import { Nft } from '@/interface/nft.interface';
import { mapActions } from 'vuex';
import CurrencyConverter from '@/components/CurrencyConverter.vue';
interface Land {
  tier: string;
  chunkId: string;
  reseller?: string;
}
const vm = Vue.extend({
  name: 'SkillItem',
  components: {
    CurrencyConverter
  },
  props: ['skill'],
  data(){
    return{
      totalShieldSupply: 0,
      totalT1LandSupply: 0,
      totalT2LandSupply: 0,
      totalT3LandSupply: 0,
      totalT2LandsToClaim: 0,
      totalT3LandsToClaim: 0,
      fetchSupplyInterval: 0,
      quantityOwned: 0,
      canPurchaseLand: false as boolean,
      checkIfCanPurchaseLandInterval: null as ReturnType<typeof setInterval> | null,
      purchase: undefined as Land | undefined,
      t1LandAvailable: false as boolean,
      t2LandAvailable: false as boolean,
      t3LandAvailable: false as boolean,
      t1LandPrice: '' as string,
      t2LandPrice: '' as string,
      t3LandPrice: '' as string,
      ownedLands: [] as Land[],
      checkOwnedLandsInterval: null as ReturnType<typeof setInterval> | null,
      hasClaimableLand: false as boolean,
      price: 0 as number,
    };
  },
  async mounted(){
    await this.checkIfCanPurchaseLand();
    this.checkIfCanPurchaseLandInterval = setInterval(await this.checkIfCanPurchaseLand,3000);
    
    const {t1Land, t2Land, t3Land} = await this.getAvailableLand();
    this.t1LandAvailable = t1Land !== '0';
    this.t2LandAvailable = t2Land !== '0';
    this.t3LandAvailable = t3Land !== '0';

    if(this.skill.type === 'shield') {
      this.price = await this.fetchSkillItemPrice(9);
      this.totalShieldSupply = 25000 - (await this.fetchTotalShieldSupply());
      this.fetchSupplyInterval = window.setInterval(async () => {
        this.totalShieldSupply = 25000 - (await this.fetchTotalShieldSupply());
      }, 3000);
    } else if(this.skill.type === 'CharacterRenameTag' || this.skill.type === 'CharacterRenameTagDeal') {
      if(this.skill.type === 'CharacterRenameTag'){
        this.price = await this.fetchSkillItemPrice(2);
      }else{
        this.price = await this.fetchSkillItemPrice(2) * 3;
      }
      this.quantityOwned = await this.fetchTotalRenameTags();
      this.fetchSupplyInterval = window.setInterval(async () => {
        this.quantityOwned = await this.fetchTotalRenameTags();
      }, 3000);
    } else if(this.skill.type === 'WeaponRenameTag' || this.skill.type === 'WeaponRenameTagDeal') {
      if(this.skill.type === 'WeaponRenameTag'){
        this.price = await this.fetchSkillItemPrice(1);
      }else{
        this.price = await this.fetchSkillItemPrice(1) * 3;
      }
      this.quantityOwned = await this.fetchTotalWeaponRenameTags();
      this.fetchSupplyInterval = window.setInterval(async () => {
        this.quantityOwned = await this.fetchTotalWeaponRenameTags();
      }, 3000);
    } else if(this.skill.type === 'CharacterFireTraitChange') {
      this.price = await this.fetchSkillItemPrice(3);
      this.quantityOwned = await this.fetchTotalCharacterFireTraitChanges();
      this.fetchSupplyInterval = window.setInterval(async () => {
        this.quantityOwned = await this.fetchTotalCharacterFireTraitChanges();
      }, 3000);
    } else if(this.skill.type === 'CharacterEarthTraitChange') {
      this.price = await this.fetchSkillItemPrice(4);
      this.quantityOwned = await this.fetchTotalCharacterEarthTraitChanges();
      this.fetchSupplyInterval = window.setInterval(async () => {
        this.quantityOwned = await this.fetchTotalCharacterEarthTraitChanges();
      }, 3000);
    } else if(this.skill.type === 'CharacterWaterTraitChange') {
      this.price = await this.fetchSkillItemPrice(5);
      this.quantityOwned = await this.fetchTotalCharacterWaterTraitChanges();
      this.fetchSupplyInterval = window.setInterval(async () => {
        this.quantityOwned = await this.fetchTotalCharacterWaterTraitChanges();
      }, 3000);
    } else if(this.skill.type === 'CharacterLightningTraitChange') {
      this.price = await this.fetchSkillItemPrice(6);
      this.quantityOwned = await this.fetchTotalCharacterLightningTraitChanges();
      this.fetchSupplyInterval = window.setInterval(async () => {
        this.quantityOwned = await this.fetchTotalCharacterLightningTraitChanges();
      }, 3000);
    } else if(this.skill.type === 'WeaponCosmetic') {
      this.price = await this.fetchSkillItemPriceSeries({itemId: 7, seriesIndex: this.skill.seriesIndex});
      this.quantityOwned = await this.fetchOwnedWeaponCosmetics({ cosmetic: +this.skill.id });
      this.fetchSupplyInterval = window.setInterval(async () => {
        this.quantityOwned = await this.fetchOwnedWeaponCosmetics({ cosmetic: +this.skill.id });
      }, 3000);
    } else if(this.skill.type === 'CharacterCosmetic') {
      this.price = await this.fetchSkillItemPriceSeries({itemId: 8, seriesIndex: this.skill.seriesIndex});
      this.quantityOwned = await this.fetchOwnedCharacterCosmetics({cosmetic: +this.skill.id});
      this.fetchSupplyInterval = window.setInterval(async () => {
        this.quantityOwned = await this.fetchOwnedCharacterCosmetics({cosmetic: +this.skill.id});
      }, 3000);
    }
    if(this.skill.type === 't1land' || this.skill.type === 't2land' || this.skill.type === 't3land') {
      this.price = this.skill.nftPrice;
      const {t1Land, t2Land, t3Land} = await this.getAvailableLand();
      this.totalT1LandSupply = t1Land;
      this.totalT2LandSupply = t2Land;
      this.totalT3LandSupply = t3Land;
      this.fetchSupplyInterval = window.setInterval(async () => {
        const {t1Land, t2Land, t3Land} = await this.getAvailableLand();
        this.totalT1LandSupply = t1Land;
        this.totalT2LandSupply = t2Land;
        this.totalT3LandSupply = t3Land;
      }, 3000);

      await this.fetchOwnedLands();
      this.price = this.skill.nftPrice;
      this.checkOwnedLandsInterval = setInterval(async () => {
        await this.fetchOwnedLands();
      }, 3000);
    }
    if(this.skill.type === 't2land' || this.skill.type === 't3land') {
      const playerReservedLand = await this.getPlayerReservedLand();
      if(playerReservedLand) {
        const {t2Reservations, t3Reservations} = playerReservedLand;
        this.totalT2LandsToClaim = t2Reservations.length;
        this.totalT3LandsToClaim = t3Reservations.length;
      }
      this.fetchSupplyInterval = window.setInterval(async () => {
        const playerReservedLand = await this.getPlayerReservedLand();
        if(playerReservedLand) {
          const {t2Reservations, t3Reservations} = playerReservedLand;
          this.totalT2LandsToClaim = t2Reservations.length;
          this.totalT3LandsToClaim = t3Reservations.length;
        }
      }, 3000);
    }
  },
  computed:{
    getOwnedLandCount(): number{
      if(this.skill.type === 't1land') {
        return this.ownedLands.filter(land => land.tier === '1').length;
      } else if(this.skill.type === 't2land') {
        return this.ownedLands.filter(land => land.tier === '2').length;
      } else if(this.skill.type === 't3land') {
        return this.ownedLands.filter(land => land.tier === '3').length;
      }
      return 0;
    },
    getTotalSupply(): number{
      if(this.skill.type === 't1land'){
        return this.totalT1LandSupply;
      } else if(this.skill.type === 't2land'){
        return this.totalT2LandSupply;
      } else if(this.skill.type === 't3land'){
        return this.totalT3LandSupply;
      }else if(this.skill.type === 'shield'){
        return this.totalShieldSupply;
      }
      return 0;
    },
    getClaimableLandCount(): number {
      if(this.skill.type === 't2land'){
        return this.totalT2LandsToClaim;
      }else if(this.skill.type === 't3land'){
        return this.totalT3LandsToClaim;
      }
      return 0;
    },
  },
  methods:{
    getPrice(){
      if(this.skill.type === 't1land' || this.skill.type === 't2land' || this.skill.type === 't3land'){
        return this.skill.nftPrice.toString();
      }else{
        return this.truncateDecimals(this.fromWeiEther(this.price.toString()));
      }
    },
    ...mapActions(['fetchSkillItemPriceSeries','fetchSkillItemPrice','getPlayerReservedLand','getOwnedLands','getCBKLandPrice','getAvailableLand','getPurchase','fetchOwnedCharacterCosmetics','fetchOwnedWeaponCosmetics','fetchTotalCharacterLightningTraitChanges','fetchTotalCharacterWaterTraitChanges','fetchTotalCharacterEarthTraitChanges','fetchTotalCharacterFireTraitChanges','fetchTotalWeaponRenameTags','fetchTotalRenameTags','fetchTotalShieldSupply','purchaseCharacterCosmetic','purchaseWeaponCosmetic','purchaseCharacterLightningTraitChange','purchaseCharacterWaterTraitChange','purchaseShield', 'purchaseRenameTag', 'purchaseRenameTagDeal', 'purchaseWeaponRenameTag', 'purchaseWeaponRenameTagDeal', 'purchaseCharacterEarthTraitChange', 'purchaseCharacterFireTraitChange']),
    ...skillShopMixins.methods,
    fromWeiEther,
    truncateDecimals,
    buyButtonDisabled(type: string) {
      if((type === 't1land' || type === 't2land' || type === 't3land' ) && !this.canPurchaseLand){
        return true;
      }
      if(type === 't1land' && !this.t1LandAvailable) {
        return true;
      }
      if(type === 't2land' && !this.t2LandAvailable) {
        return true;
      }
      return type === 't3land' && !this.t3LandAvailable;
    },
    checkIfLandOrShield():boolean{
      return this.skill.type === 't1land' || this.skill.type === 't2land' || this.skill.type === 't3land' || this.skill.type === 'shield';
    },
    async fetchOwnedLands() {
      const results = await this.getOwnedLands();
      if(results){
        this.ownedLands = results.map((result: Land[]) => ({tier: result[0], chunkId: result[1], reseller: result[4]}));
      }
    },
    async checkIfCanPurchaseLand() {
      const purchase = await this.getPurchase();
      if(purchase) {
        this.purchase = purchase;
        this.canPurchaseLand = purchase.tier === '0';
      }
    },
    openModal(bol:boolean){
      this.$root.$emit('modal',bol);
    },
    getSkillImage(imageFileName: string) {
      return require(`../assets/skill/${imageFileName}`);
    },
    getLandImage(imageFileName: string) {
      return require(`../assets/land/${imageFileName}`);
    },
    getWeaponPlaceholderImage() {
      return require('../assets/placeholder/sword-placeholder-0.png');
    },
    getCharacterPlaceholderImage() {
      return require('../assets/characters/KnightEarth.png');
    },
    getShieldPlaceholderImage() {
      return require("../assets/shields/shield2.png");
    },
    claimLand(item: Nft){
      if(item.type === 't2land'){
        this.$root.$emit('claim-t2');
      }
      if(item.type === 't3land'){
        this.$root.$emit('claim-t3');
      }
    },
    async buyItem(item: Nft) {
      if(item.type === 'shield'){
        await this.purchaseShield({price: this.getPrice() || 0});
      }

      if(item.type === 'CharacterRenameTag'){
        await this.purchaseRenameTag({price: this.getPrice() || 0});
      }
      if(item.type === 'CharacterRenameTagDeal'){
        await this.purchaseRenameTagDeal({price: this.getPrice() || 0});
      }

      if(item.type === 'WeaponRenameTag'){
        await this.purchaseWeaponRenameTag({price: this.getPrice() || 0});
      }
      if(item.type === 'WeaponRenameTagDeal'){
        await this.purchaseWeaponRenameTagDeal({price: this.getPrice() || 0});
      }

      if(item.type === 'CharacterFireTraitChange'){
        await this.purchaseCharacterFireTraitChange({price: this.getPrice() || 0});
      }

      if(item.type === 'CharacterEarthTraitChange'){
        await this.purchaseCharacterEarthTraitChange({price: this.getPrice() || 0});
      }

      if(item.type === 'CharacterWaterTraitChange'){
        await this.purchaseCharacterWaterTraitChange({price: this.getPrice() || 0});
      }

      if(item.type === 'CharacterLightningTraitChange'){
        await this.purchaseCharacterLightningTraitChange({price: this.getPrice() || 0});
      }


      if(item.type === 'WeaponCosmetic'){
        await this.purchaseWeaponCosmetic({cosmetic: +item.id, price: this.getPrice() || 0});
      }

      if(item.type === 'CharacterCosmetic'){
        await this.purchaseCharacterCosmetic({cosmetic: +item.id, price: this.getPrice() || 0});
      }

      if(item.type === 't1land'){
        this.$root.$emit('buy-t1');
      }

      if(item.type === 't2land'){
        this.$root.$emit('buy-t2');
      }
      if(item.type === 't3land'){
        this.$root.$emit('buy-t3');
      }
    },
  },
  beforeDestroy() {
    if(this.checkIfCanPurchaseLandInterval){
      clearInterval(this.checkIfCanPurchaseLandInterval);
    }
    if(this.checkOwnedLandsInterval){
      clearInterval(this.checkOwnedLandsInterval);
    }
  }
});

export default vm;

