















































import Vue, {PropType} from 'vue';
import {mapGetters} from 'vuex';
import {setRarityName, setRarityColor, addCommas, totalBattlePower, elementToStat} from "@/utils/item.utils";
import ElementTrait from '@/components/ElementTrait.vue';
import SellItem from './SellItem.vue';
import { Nft } from '@/interface/nft.interface';

export default Vue.extend({
  props: {
    isSellModal: {
      type: Boolean
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    shield: {
      type: Object as PropType<Nft>,
      required: false
    },
  },
  computed: {
    ...mapGetters(
      [
        'getWeaponDurability',
        'shieldContractAddress'
      ]
    )
  },
  components: {
    ElementTrait,
    SellItem
  },
  methods: {
    elementToStat,
    setRarityName,
    setRarityColor,
    addCommas,
    totalBattlePower,
  }
});

