







































import Vue, { PropType } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { toNumber } from 'lodash';
import {DEFAULT_TARGET_BUYER} from "@/default/common.default";
import Web3 from 'web3';
import { IWeapon } from '@/interface/weapon.interface';
import { IShield } from '@/interface/shield.interface';
import { ICharacter } from '@/interface/character.interface';
import CurrencyConverter from '@/components/CurrencyConverter.vue';
import { fromWeiEther } from '@/utils/common';
import fixedPrice from '@/assets/nav-icons/fixed-price.svg';
import { IJunk } from '@/interface/junk.interface';
export default Vue.extend({
  components: {CurrencyConverter},
  props:{
    item:{
      type: Object as PropType<IShield | IWeapon | ICharacter | IJunk>,
      required: true
    },
    contractAddress:{
      type: String,
      required: true,
    },
    isEdit:{
      type: Boolean,
      default: false
    },
    type:{
      type: String,
      required: true
    }
  },
  data(){
    return{
      listingSellPrice: '',
      targetBuyerAddress: '',
      price: '',
      fixedPrice
    };
  },
  async created(){
    switch(this.type){
    case 'weapon':
      this.price = await this.lookupWeaponPrice(this.item.id);
      break;
    case 'character':
      this.price = await this.lookupCharacterPrice(this.item.id);
      break;
    case 'shield':
      this.price = await this.lookupShieldPrice(this.item.id);
      break;
    case 'junk':
      this.price = await this.lookupJunkPrice(this.item.id);
      break;
    default:
      break;
    }
  },
  computed:{
    ...mapGetters(['getCurrentWeapon', 'getWeaponDurability', 'currentSkillBalance']),
  },
  methods:{
    ...mapActions(['addMarketListing','lookupWeaponPrice','lookupCharacterPrice', 'lookupShieldPrice','lookupJunkPrice','updateNftListingPrice']),
    taxCalculator() {
      return ((Math.min(toNumber(this.listingSellPrice), 100000) * 1.1).toFixed(3)).toString();
    },
    convertSkillToWei(skill: string) {
      return Web3.utils.toWei(skill);
    },
    convertWeiToSkill(wei: string) {
      return fromWeiEther(wei);
    },
    getModalType() {
      switch(this.type){
      case 'weapon':
        return 'weapon-view-modal';
      case 'character':
        return 'character-view-modal';
      case 'shield':
        return 'shield-view-modal';
      case 'junk':
        return 'junk-view-modal';
      default:
        return '';
      }
    },
    async addListing() {
      if (Number(this.currentSkillBalance) < 0.0032) {
        return this.openModal(this.getModalType(), this.item, 'not-enough-skill-listing', false);
      }
      await this.addMarketListing({
        nftContractAddr: this.contractAddress, 
        tokenId: this.item.id, 
        price: this.convertSkillToWei(this.listingSellPrice),
        targetBuyer: this.targetBuyerAddress.toString() || DEFAULT_TARGET_BUYER })
        .then((res) => {
          this.$root.$emit(`refresh-${this.$route.meta?.tabName}-inventory`);
          this.$root.$emit(`${this.type}-view-modal`, this.item, 'listed', false, false);
        });
    },
    async updatePrice(){
      await this.updateNftListingPrice({ selectedNftId: this.item.id, listingSellPrice: this.listingSellPrice, contractAddress: this.contractAddress, type: this.type }).then((res) => {
        this.$root.$emit(`refresh-${this.$route.meta?.tabName}-inventory`);
        this.$root.$emit(`${this.type}-view-modal`, this.item, 'price-updated', false, false);
      });
    },
    openModal(modal: string, item: IWeapon | IShield | ICharacter | IJunk, type: string, isSellModal: boolean, isEditing?: boolean){
      this.$root.$emit(modal, item, type, isSellModal, isEditing);
    },
  }
});
