









import Vue from 'vue';
import {store} from "@/store";
import { mapActions,mapGetters,mapState } from 'vuex';
import { PAGE_SIZE_LIMIT } from '@/default/common.default';
import Web3 from 'web3';
import { Dictionary } from 'vue-router/types/router';

export interface ISearchType {
  name: string;
  value: string;
}

export default Vue.extend({
  data(){
    return{
      search: '',
      searchType: {} as ISearchType,
      timeout: 0 as any,
      isLoading: false,
    };
  },
  computed:{
    ...mapGetters(['getNftConfigs','weaponContractAddress','characterContractAddress','shieldContractAddress', 'contracts']),
    ...mapState(['defaultAccount', 'isFetchWeaponListLoading', 'isCharacterListLoading', 'isFetchShieldAndArmorListLoading']),
    nftConfigsAsOptions(){
      let results: Array<{name: string; value: string}> = [];
      Object.values(this.getNftConfigs).map((config: any) => {
        results.push({
          name: config.name,
          value: config.type,
        });
        results.push({
          name: config.name + '-seller',
          value: config.type + '-seller',
        });
      });
      return results;
    },
  },
  mounted(){
    const search = this.$route.query.id;
    const searchAddress = this.$route.query.address;
    const tabName = this.$route.meta.tabName;
    this.searchType = {
      name: this.getNftConfigs[tabName].name + (searchAddress ? '-seller' : ''),
      value: tabName + (searchAddress ? '-seller' : '')
    };
    if(search || searchAddress){
      this.search = search || searchAddress;
    }
  },
  methods:{
    ...mapActions(['fetchNftInfo','fetchContractFromConfig','fetchNfts','lookupSellerStatus', 'filterOutTargetBuyers','fetchMarketNftPrice']),
    async lookupNftPrice(type: string, nftInfo: any, id: string | number) {
      const contractAddress = await this.fetchContractFromConfig({
        nftInfo: nftInfo,
        nftType: type,
      });
      if(!contractAddress) return;

      return await this.fetchMarketNftPrice({
        nftContractAddr: contractAddress.options.address,
        tokenId: id,
      });
    },
    parseTitle(value: string){
      return value.replace('-seller','').toLowerCase();
    },
    titleCase(value: string){
      return value[0].toUpperCase() + value.slice(1).toLowerCase();
    },
    changeTab(){
      let snapshotQuery = this.$route.query as Dictionary<string>;
      this.$router.replace({name: `Buy${this.getNftConfigs[this.parseTitle(this.searchType.value)].name}` || '', query: {...snapshotQuery} }).catch((err: any) => err);
    },
    async searchListingsByNftId(type: ISearchType) {
      if(type.value.includes('-seller')){
        this.fetchNfts({
          type: this.parseTitle(this.searchType.value),
          seller: this.search,
        });
      }else{
        const nftType = this.parseTitle(type.value);
        store.commit('setLoadingState', {type: nftType, isLoading: true});
        const nftInfo: any = this.getNftConfigs[nftType];
        const contractAddress = await this.fetchContractFromConfig({
          nftInfo: nftInfo,
          nftType: nftType,
        });
        
        const sellerStatus = await this.lookupSellerStatus(this.search);

        let filteredResults = await this.filterOutTargetBuyers({list: [this.search], contractAddress: contractAddress.options.address});

        const price = await this.lookupNftPrice(nftType, nftInfo, this.search);
        if(price !== '0' && !sellerStatus) {
          store.commit('updateNftList', {
            type: nftType,
            nfts: await store.dispatch('fetchNftInfo', {
              nftIds: filteredResults,
              nftType: nftType,
            })
          });
        }
        store.commit(`setNftListPagination`,{
          type: nftType,
          pagination:{
            pageSize: PAGE_SIZE_LIMIT,
            totalItems: filteredResults.length
          }
        });
        store.commit('setTotalItems', filteredResults.length);
        store.commit('setLoadingState', {type: nftType, isLoading: false});
      }
    },
  },
  watch:{
    searchType(){
      if (this.timeout) 
        clearTimeout(this.timeout); 
      this.timeout = setTimeout(() => {
        if(this.search.length > 0){
          this.searchListingsByNftId(this.searchType);
        }
      }, 500);
    },
    search(newVal:string){
      if (this.timeout) 
        clearTimeout(this.timeout); 
      this.timeout = setTimeout(() => {
        if(newVal.length > 0){
          const address = this.$route.query.address;
          const id = this.$route.query.id;
          if(Web3.utils.isAddress(newVal)){
            if(!address || address || newVal){
              this.$router.replace({query: {address: newVal}});
            }
          }else{
            if(!id || id !== newVal){
              this.$router.replace({query: {id: newVal}});
            }
          }
          this.searchListingsByNftId(this.searchType);
        }else{
          this.$router.replace({query: {}});
          store.commit('setGlobalFilter', {seller: ''});
          this.fetchNfts({
            type: this.$route?.meta?.tabName,
            seller: this.search,
          });
        }
      }, 500);
    },
  },
});
