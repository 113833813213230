import { getElementNameByValue } from "@/default/element.default";
import { getRarityNameByValue } from "@/default/rarity.default";
import { IFilter, IMarketFilter } from "@/interface/filters.interface";
import { Dictionary } from "vue-router/types/router";
import { Capitalize } from "./string.util";
import _ from 'lodash';
import { NULL_FILTER_VALUE } from "@/default/common.default";
import { IGlobalSort } from "@/interface/sort.interface";

export function isPathMatch(a:string, b:string) : boolean {
  return a === b;
}

export function queryParamsToMarketFilter(dict:Dictionary<string | (string | null)[] | null | undefined>, replaceEmptyWithDefault: IMarketFilter = { elementFilter: [], rarityFilter: [], maxPrice: Number.MAX_SAFE_INTEGER, minPrice: 0 }): IMarketFilter {
  const marketFilter: IMarketFilter = {
    elementFilter: replaceEmptyWithDefault.elementFilter,
    rarityFilter: replaceEmptyWithDefault.rarityFilter,
    maxPrice: replaceEmptyWithDefault.maxPrice,
    minPrice: replaceEmptyWithDefault.minPrice
  };

  if(dict["element"]) {
    marketFilter.elementFilter = [({name: getElementNameByValue(dict["element"] as string), value: dict["element"] as string || ""})];
  }
    
  if(dict["minStar"]) {
    marketFilter.rarityFilter = [({name: getRarityNameByValue(+dict["minStar"]), value: +dict["minStar"] || 0})];
  }

  if(dict["maxPrice"]) {
    marketFilter.maxPrice = +dict["maxPrice"];
  }

  if(dict["minStar"]) {
    marketFilter.minPrice = +dict["minStar"];
  }

  return marketFilter;
}

export function objToQueryParams(obj: any) : string {
  let query = "";
    
  let isFirstParam = true;
  for (const [key, value] of Object.entries(obj)) {
    if((value || value === 0) && key) {
      if(isFirstParam) {
        query += `${key}=${value}`;
        isFirstParam = false;
      }
      else {
        query += `&${key}=${value}`;
      }
    }
  }

  return query;
}

export function marketSortToQueryDict(sort: IGlobalSort) : Dictionary<number | undefined> {
  const queryDict:Dictionary<number | undefined> = {};
  const genericSort: string[] = ['price','id'];

  if(sort.sortBy) {
    if(genericSort.includes(sort.sortBy)) {
      queryDict[sort.sortBy] = sort.sortDir;
    }else{
      queryDict[sort.sortBy] = sort.sortDir;
    }
  }

  return queryDict;
}


export function marketFilterToQueryDict(filter: IMarketFilter) : Dictionary<string | (string | null)[]> {
  const queryDict:Dictionary<string | (string | null)[]> = {};
  const sortedRarityFilter: Array<number> = [];

  queryDict["stars"] = "";
  queryDict["stars"] = "";
  queryDict["element"] = "";
  
  _.orderBy(filter?.rarityFilter, ['value'], ['asc']).map((rarity: IFilter<number>) => {
    sortedRarityFilter.push(rarity.value);
  });
  
  const rarity = sortedRarityFilter.toString().split(",").map(Number);
  queryDict["stars"] = Math.min(...rarity) !== 0 ? (+Math.min(...rarity) - 1).toString() : (0).toString();
  queryDict["stars"] = Math.max(...rarity) !== 0 ? (+Math.max(...rarity) - 1).toString() : NULL_FILTER_VALUE.toString();

  filter?.elementFilter?.some((res: { value: string }) => {
    queryDict["element"] = Capitalize(res.value);
  });

  return queryDict;
}
