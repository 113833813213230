import {getElementNumberToName} from "@/default/element.default";
import {IWeapon} from "@/interface/weapon.interface";
import {
  getStat1Trait,
  getStat2Trait,
  getStat3Trait,
  getStatPatternFromProperties,
  getWeaponTraitFromProperties,
  statNumberToName
} from "./contract";
import {Nft} from "@/interface/nft.interface";

export function weaponFromContract(id: string | number, data: string[]): IWeapon {
  const properties = data[0];
  const stat1 = data[1];
  const stat2 = data[2];
  const stat3 = data[3];
  const level = +data[4];
  const cosmetics = +data[5];
  const blade = (cosmetics & 0xff).toString();
  const crossguard = ((cosmetics >> 8) & 0xff).toString();
  const grip = ((cosmetics >> 16) & 0xff).toString();
  const pommel = ((cosmetics >> 24) & 0xff).toString();
  const burnPoints = +data[6];
  const bonusPower = +data[7];
  const weaponType = +data[8];

  const stat1Value = +stat1;
  const stat2Value = +stat2;
  const stat3Value = +stat3;

  const statPattern = getStatPatternFromProperties(+properties);
  const stat1Type = getStat1Trait(statPattern);
  const stat2Type = getStat2Trait(statPattern);
  const stat3Type = getStat3Trait(statPattern);

  const traitNum = getWeaponTraitFromProperties(+properties);

  const lowStarBurnPoints = burnPoints & 0xff;
  const fourStarBurnPoints = (burnPoints >> 8) & 0xff;
  const fiveStarBurnPoints = (burnPoints >> 16) & 0xff;

  const stars = (+properties) & 0x7;
  return {
    id: +id, properties,
    element: getElementNumberToName(traitNum),
    stat1: statNumberToName(stat1Type), stat1Value, stat1Type,
    stat2: statNumberToName(stat2Type), stat2Value, stat2Type,
    stat3: statNumberToName(stat3Type), stat3Value, stat3Type,
    level,
    blade, crossguard, grip, pommel,
    stars,
    lowStarBurnPoints,
    fourStarBurnPoints,
    fiveStarBurnPoints,
    bonusPower,
    weaponType
  };
}

export interface IWeaponApi {
    weaponId: string;
    weaponType: number;
    weaponStars: number;
    weaponElement: string;
    stat1Element: string;
    stat1Value: number;
    stat2Element: string;
    stat2Value: number;
    stat3Element: string;
    stat3Value: number;
    price: number;
    timestamp: number;
    sellerAddress: string;
    network: string;
}

export function weaponFromAPI(weaponApi: IWeaponApi): Nft {
  const id = +weaponApi.weaponId;
  const stars = weaponApi.weaponStars;
  const element = weaponApi.weaponElement;
  const stat1 = weaponApi.stat1Element;
  const stat1Value = weaponApi.stat1Value;
  const stat2 = weaponApi.stat2Element;
  const stat2Value = weaponApi.stat2Value;
  const stat3 = weaponApi.stat3Element;
  const stat3Value = weaponApi.stat3Value;
  const price = weaponApi.price;
  const sellerAddress = weaponApi.sellerAddress;
  const weaponType = weaponApi.weaponType;

  return {
    id,
    stars,
    element,
    stat1,
    stat2,
    stat3,
    stat1Value,
    stat2Value,
    stat3Value,
    price,
    sellerAddress,
    weaponType
  } as Nft;
}
