

























































import Vue from 'vue';
import { mapActions } from 'vuex';
import { store } from '@/store';
import detectEthereumProvider from '@metamask/detect-provider';
import { mapGetters } from 'vuex';
import CurrencyConverter from '../components/CurrencyConverter.vue';
import { fromWeiEther } from '@/utils/common';
import CBIcon from './CBIcon.vue';


// move this out
interface ConnectInfo {
  chainId: string;
}

export default Vue.extend({
  name : 'WalletBalance',
  props: {
    msg: String,
  },
  data() {
    return {
      metamaskConnected : false,
      currentChain: ''
    };
  },
  async mounted () {
    this.getAsset();
    if (!this.isConnected()) {
      await this.onSetupMetamask();
    }

    await store.dispatch('getMetamaskProvider');
    await store.dispatch('getMetamaskAccount');
    await this.connectMetamask();
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters([
      'getMetamaskConnected',
      'defaultAccount',
      'currentWalletAddress',
      'currentBNBBalance',
      'currentSkillBalance',
      'currentSkillRewards',
      'getIGOFunds'
    ]),
  },
  methods: {
    onBuySkill() {
      window.open('https://app.apeswap.finance/swap?outputCurrency=0x154a9f9cbd3449ad22fdae23044319d6ef2a1fab', '_blank');
    },
    ...mapActions(['initialize'] ),
    connectMetamask: async () => {
      try {
        const provider = await detectEthereumProvider() as any;
        if (provider) {
          const accounts = await provider.request({ method: 'eth_requestAccounts' }); // use for request metamask account
          if (accounts.length > 0) {
            // get account in array
            // account connect success fully will get array more then 1
            // store account to state
            store.commit('setDefaultAccount', accounts[0]);
            store.commit('setMetamaskConnected', true);
            store.commit('setCurrentWalletAddress', provider.selectedAddress);
            await store.dispatch('fetchUserGameDetails');
          }
        }
      } catch (err) {
        console.error('Connect metamask fail:', err);
      }
    },
    isConnected : async () => {
      const provider = await detectEthereumProvider() as any;
      if (provider.selectedAddress) {
        store.commit('setMetamaskConnected', true);
        store.commit('setCurrentWalletAddress', provider.selectedAddress);
        store.commit('setDefaultAccount', provider.selectedAddress);
      }
    },
    onSetupMetamask: async () => {
      const provider = await detectEthereumProvider() as any;

      provider.on('connect', (connectInfo: ConnectInfo) => {
        store.commit('setChainId', connectInfo.chainId);
      });
      // watch when user change account
      provider.on('accountsChanged', (accounts: string[]) => {
        if (accounts.length > 0) {
          store.commit('setDefaultAccount', accounts[0]);
        } else {
          return;
        }
      });
      await store.dispatch('getMetamaskProvider');
      await store.dispatch('getMetamaskAccount');
      store.commit('setCurrentWalletAddress', provider.selectedAddress);
    },
    getAsset() {
      this.currentChain = localStorage.getItem('currentChain') || 'BNB';
    },
    convertWeiToSkill(wei: string) {
      return fromWeiEther(wei);
    },
  },
  components: { CurrencyConverter, CBIcon }
});
