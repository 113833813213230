

































import Vue from "vue";
import SortFilter from "../components/SortFilter.vue";
import {mapGetters} from "vuex";
import { getConfigValue } from '../contracts';

export default Vue.extend({
  components: {
    SortFilter,
  },
  computed: {
    ...mapGetters(['getNftConfigs']),
    networkId(): string {
      return getConfigValue('VUE_APP_NETWORK_ID');
    }
  },
  methods:{
    getComponentName(){
      if(this.$route?.meta?.tabName === 'skillshop'){
        return 'skill-list';
      }else if(this.$route.name === 'Merchandise'){
        return 'merchandise-list';
      }
      return 'nft-list';
    }
  },
  data() {
    return {
      filterIsToggled: false,
    };
  },
});
