


















































import Vue from 'vue';
import {BModal} from 'bootstrap-vue';
import { getJunkArt } from '../junk-arts-placeholder';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { fromWeiEther } from '@/utils/common';
import CurrencyConverter from './CurrencyConverter.vue';
import {setRarityName, setRarityColor, addCommas} from "@/utils/item.utils";
import { DEFAULT_TARGET_BUYER } from '@/default/common.default';
import { Nft } from '@/interface/nft.interface';

export default Vue.extend({
  name: 'JunkItem',
  props: {
    nft:{
      type: Object,
      required: true
    },
    contractAddress:{
      type: String,
      required: true,
    },
    nftType:{
      type: String,
      required: true,
    }
  },
  data() {
    return  {
      isSelling: true,
      DEFAULT_TARGET_BUYER,
    };
  },
  computed: {
    isValidForPurchase(): boolean{
      return this.nft.targetBuyer === DEFAULT_TARGET_BUYER || this.nft.targetBuyer?.toUpperCase() === this.defaultAccount.toUpperCase();
    },
    ...mapState(['defaultAccount']),
    ...mapGetters(
      [
        'currentSkillBalance',
      ]
    )
  },
  methods:{
    ...mapActions(
      [
        'lookupJunkPrice',
        'purchaseJunk',
        'purchaseJunkListing',
        'fetchMarketNftPrice',
        'fetchMarketNftTargetBuyer',
        'fetchMarketNftSellerStatus',
        'cancelMarketListing',
      ]
    ),
    getPurchaseTooltipMsg(): string{
      if(this.nft.sellerStatus){
        return 'NFT is blacklisted';
      }
      if(!this.isValidForPurchase){
        return 'You are not the target buyer.';
      }
      return '';
    },
    ...mapMutations(['setCurrentJunk']),
    setRarityName,
    setRarityColor,
    getJunkArt,
    addCommas,
    openModal(modal: string, junk: Nft, type: string, isSellModal: boolean, isEditing?: boolean){
      this.$root.$emit(modal, junk, type, isSellModal, isEditing);
    },
    hideSellItemModal() {
      (this.$refs['sellItem'] as BModal).hide();
    },
    async lookupJunkPrice(id: number) {
      if (!this.contractAddress) return;

      return await this.fetchMarketNftPrice({
        nftContractAddr: this.contractAddress,
        tokenId: id,
      });
    },
    async lookupTargetBuyer(id: number) {
      if (!this.contractAddress) return;

      return await this.fetchMarketNftTargetBuyer({
        nftContractAddr: this.contractAddress,
        tokenId: id,
      });
    },
    async lookupSellerStatus(id: number) {
      if (!this.contractAddress) return;

      return await this.fetchMarketNftSellerStatus({
        nftContractAddr: this.contractAddress,
        tokenId: id,
      });
    },
    async purchaseJunk(){
      const price = await this.lookupJunkPrice(this.nft.id);
      const targetBuyer = await this.lookupTargetBuyer(this.nft.id);
      const sellerStatus = await this.lookupSellerStatus(this.nft.id);

      /* Call component modal */
      if(!price){
        return this.openModal('junk-view-modal', this.nft, 'sold', false);
      } // item is already sold
      if(Number(price) > Number(this.currentSkillBalance)) {
        return this.openModal('junk-view-modal', this.nft, 'not-enough-skill-purchase', false);
      }
      if (targetBuyer !== DEFAULT_TARGET_BUYER) {
        if (targetBuyer.toUpperCase() !== this.defaultAccount.toUpperCase()) {
          return this.openModal('junk-view-modal', this.nft, 'target-buyer', false);
        }
      }
      if (sellerStatus){
        return this.openModal('junk-view-modal', this.nft, 'banned', false);
      }

      /* this is only called when all filters passed */
      try{
        await this.purchaseJunkListing({
          tokenId: this.nft.id,
          maxPrice: price
        }).then(() => {
          this.openModal('junk-view-modal', this.nft, 'purchased', false);
        });
      /* call error modal stating "An error occurred, please try again later." (must console log the error) */
      // eslint-disable-next-line no-empty
      } catch (error) {}
    },
    convertWeiToSkill(wei: string) {
      return fromWeiEther(wei);
    },
    cancelListing() {
      this.$root.$emit('confirm-modal', "Are you sure you want to cancel junk listing?",
        async () => {
          let result = await this.cancelMarketListing({nftContractAddr: this.contractAddress, tokenId: this.nft.id});
          if(result){
            this.$root.$emit(`refresh-${this.nftType}-inventory`);
            this.$root.$emit(`junk-view-modal`, this.nft, 'cancelled', false, false);
          }
        });
    }
  },
  components: { CurrencyConverter },
});

