















import Vue from 'vue';
import {IWeapon} from "@/interface/weapon.interface";
import CloseModalButton from "@/components/modal/CloseModalButton.vue";
import WeaponDisplay from "@/components/modal/WeaponDisplay.vue";
import {BModal} from "bootstrap-vue";
import { mapActions } from 'vuex';
import { fromWeiEther } from "@/utils/common";

export default Vue.extend({
  data() {
    return {
      weapon: {} as IWeapon,
      type: '' as 'listed' | 'price-updated' | 'purchased' | 'cancelled',
      isSellModal: false,
      isEditing: false,
      listingFee: '0',
    };
  },
  components: {
    CloseModalButton,
    WeaponDisplay,
  },
  methods: {
    ...mapActions(
      [
        'getListingFee'
      ]
    ),
  },
  mounted() {
    this.$root.$on('weapon-view-modal', async (weapon: IWeapon, type: 'listed' | 'price-updated' | 'purchased' | 'cancelled', isSellModal: boolean, isEditing: boolean) => {
      const modal = this.$refs['weapon-view-modal'] as BModal;
      if (modal) {
        this.isEditing = isEditing;
        this.weapon = weapon;
        this.type = type;
        this.isSellModal = isSellModal;
        if (isSellModal) {
          this.listingFee = parseFloat(fromWeiEther(await this.getListingFee())).toFixed(5);
        }
        if (weapon) {
          modal.show();
        } else {
          modal.hide();
        }
      }
    });
    this.$root.$on('close-modal', () => {
      const modal = this.$refs['weapon-view-modal'] as BModal;
      modal.hide();
    });
  }
});

