import { IMarketFilter } from '@/interface/filters.interface';
export function filterToApiFields(filters: IMarketFilter, config: any) : Array<{name: string; value: string | number}> {
  const apiFields: Array<{name: string; value: string | number}> = [];
  const uiFilters = config.ui.filters;
  if(filters.elementFilter && filters.elementFilter.length > 0) {
    filters.elementFilter.forEach((element: any) => {
      apiFields.push({
        name: uiFilters.element.apiField,
        value: element
      });
    });
  }
  if(filters.rarityFilter && filters.rarityFilter.length > 0) {
    const rarityArr : Array<number> = [];
    filters.rarityFilter.forEach((rarity: any) => {
      rarityArr.push(rarity);
    });
    apiFields.push({name: uiFilters.stars.apiField, value: Math.min(...rarityArr)});
    apiFields.push({name: uiFilters.stars.apiField, value: Math.max(...rarityArr)});
  }
  if(filters.minLevel) {
    apiFields.push({name: "minLevel", value: filters.minLevel});
  }
  if(filters.maxLevel) {
    apiFields.push({name: "maxLevel", value: filters.maxLevel});
  }
  return apiFields;
}
