


























































































import Vue from 'vue';
import {mapGetters, mapActions, mapState} from 'vuex';
import {fromWeiEther} from "@/utils/common";
import CurrencyConverter from "@/components/CurrencyConverter.vue";
import {setRarityName, setRarityColor, addCommas, totalBattlePower, elementToStat} from "@/utils/item.utils";
import CBIcon from './CBIcon.vue';
import ElementTrait from './ElementTrait.vue';
import BattlePower from './BattlePower.vue';
import { DEFAULT_TARGET_BUYER } from '@/default/common.default';
import { IShield } from '@/interface/shield.interface';

export default Vue.extend({
  props: {
    nft:{
      type: Object,
      required: true
    },
    contractAddress:{
      type: String,
      required: true,
    },
    nftType:{
      type: String,
      required: true,
    }
  },
  data(){
    return{
      sellerAddress: '',
    };
  },
  methods: {
    elementToStat,
    getPurchaseTooltipMsg(): string{
      if(this.nft.sellerStatus){
        return 'NFT is blacklisted';
      }
      if(!this.isValidForPurchase){
        return 'You are not the target buyer.';
      }
      return '';
    },
    ...mapActions(
      [
        'cancelMarketListing', 
        'purchaseShieldListing',
        'fetchShieldsNftPrice',
        'fetchMarketNftPrice',
        'fetchMarketNftTargetBuyer',
        'fetchMarketNftSellerStatus',
      ]
    ),
    setRarityName,
    setRarityColor,
    addCommas,
    totalBattlePower,
    async lookupShieldPrice(id: string) {
      if (!this.contractAddress) return;

      return await this.fetchMarketNftPrice({
        nftContractAddr: this.contractAddress,
        tokenId: id,
      });
    },
    async lookupTargetBuyer(id: string) {
      if (!this.contractAddress) return;

      return await this.fetchMarketNftTargetBuyer({
        nftContractAddr: this.contractAddress,
        tokenId: id,
      });
    },
    async lookupSellerStatus(id: string) {
      if (!this.contractAddress) return;

      return await this.fetchMarketNftSellerStatus({
        nftContractAddr: this.contractAddress,
        tokenId: id,
      });
    },
    async purchaseShield(){
      const price = await this.lookupShieldPrice(this.nft.id);
      const targetBuyer = await this.lookupTargetBuyer(this.nft.id);
      const sellerStatus = await this.lookupSellerStatus(this.nft.id);

      /* Call component modal */
      if(!price){
        this.openModal('shield-view-modal', this.nft, 'sold', false);
        return;
      }
      if(Number(price) > Number(this.currentSkillBalance)) {
        return this.openModal('shield-view-modal', this.nft, 'not-enough-skill-purchase', false);
      }
      if (targetBuyer !== DEFAULT_TARGET_BUYER) {
        if (targetBuyer.toUpperCase() !== this.defaultAccount.toUpperCase()) {
          this.openModal('shield-view-modal', this.nft, 'target-buyer', false);
          return;
        }
      }
      if (sellerStatus){
        this.openModal('shield-view-modal', this.nft, 'banned', false);
        return;
      }

      /* this is only called when all filters passed */
      try{
        await this.purchaseShieldListing({
          tokenId: this.nft.id,
          maxPrice: price
        }).then(() => {
          this.openModal('shield-view-modal', this.nft, 'purchased', false);
        });
        /* call error modal stating "An error occurred, please try again later." (must console log the error) */
        // eslint-disable-next-line no-empty
      } catch (error) {}
        
    },
    openModal(modal: string, shield: IShield, type: string, isSellModal: boolean, isEditing?: boolean){
      this.$root.$emit(modal, shield, type, isSellModal, isEditing);
    },
    cancelListing() {
      this.$root.$emit('confirm-modal', "Are you sure you want to cancel shield listing?",
        async () => {
          let result = await this.cancelMarketListing({nftContractAddr: this.contractAddress, tokenId: this.nft.id});
          if(result){
            this.$root.$emit(`refresh-${this.nftType}-inventory`);
            this.$root.$emit(`shield-view-modal`, this.nft, 'cancelled', false, false);
          }
        });
    },
    convertWeiToSkill(wei: string) {
      return fromWeiEther(wei);
    },
  },
  computed: {
    isValidForPurchase(): boolean{
      return this.nft.targetBuyer === DEFAULT_TARGET_BUYER || this.nft.targetBuyer.toUpperCase() === this.defaultAccount.toUpperCase();
      // return false;
    },
    ...mapState(['defaultAccount']),
    ...mapGetters(
      [
        'getShieldListFilterState', 'getShieldDurability', 'currentSkillBalance'
      ]
    )
  },
  components: {CurrencyConverter, CBIcon, ElementTrait, BattlePower}
});
