import { fromPairs } from "lodash";

export interface Nft {
  id: number | string;
  //weapon,shield stats
  stars?: number;
  element?: string;
  stat1Type?: string;
  stat2Type?: string;
  stat3Type?: string;
  stat1Value?: number;
  stat2Value?: number;
  stat3Value?: number;
  //not included for shields
  blade?: number | string;
  crossguard?: number | string;
  grip?: number | string;
  pommel?: number | string;
  lowStarBurnPoints?: number;
  fourStarBurnPoints?: number;
  fiveStarBurnPoints?: number;
  bonusPower?: number;
  weaponType?: number;
  //character stats
  level?: number;
  head?: number;
  arms?: number;
  torso?: number;
  legs?: number;
  boots?: number;
  race?: number;
  //skillshop
  type?: string;
  nftPrice?: number;
  //common stats
  price?: number | string;
  timestamp?: number;
  seller?: string;
  network?: string;
  sellStatus?: number;
  seriesIndex? : number;
}

export enum NftSellStatusEnum {
  NotListed = 0,
  ListedActive = 1,
  ListedSold = 2,
}
