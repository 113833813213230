export interface CartEntry {
  product: Product;
  variant: Variant;
  quantity: number;
}

export interface ProductDetails {
  sync_product: Product;
  sync_variants: Variant[];
}

export interface DefaultPrices {
  product_id: number;
  price: string | undefined;
}

export interface Variant {
  id: number;
  external_id: string;
  name: string;
  synced: boolean;
  variant_id: number;
  warehouse_product_variant_id?: number;
  retail_price: string;
  sku: string;
  currency: string;
  product: {
    variant_id: number;
    product_id: number;
    image: string;
    name: string;
  };
  files: File[];
}

export interface File {
  id: number;
  type: FileType;
  hash: string;
  filename: string;
  mime_type: string;
  size: number;
  width: number;
  height: number;
  dpi: number;
  status: string;
  created: number;
  thumbnail_url: string;
  preview_url: string;
  visible: boolean;
}

export enum FileType {
  DEFAULT = 'default',
  PREVIEW = 'preview',
  BACK = 'back',
  EMBROIDERY_CHEST_LEFT = 'embroidery_chest_left',
  EMBROIDERY_CHEST_RIGHT = 'embroidery_chest_right',
  EMBROIDERY_CHEST_CENTER = 'embroidery_chest_center',
}

export interface Product {
  id: number;
  external_id: string;
  name: string;
  variants: number;
  synced: number;
  thumbnail_url: string;
  is_ignored: boolean;
}

export interface MerchandiseOrder {
  recipient: Recipient;
  items: Item[];
  shipping?: string;
  wallet?: string;
  currentChain?: string;
}

export interface Item {
  external_variant_id: string;
  product_id: string;
  quantity: number;
}

export interface Recipient {
  name: string;
  email: string;
  phone: string;
  country_code: string;
  state_code?: string;
  address1: string;
  address2?: string;
  city: string;
  zip: string;
  company?: string;
  tax_number?: string;
}

export interface Country {
  name: string;
  code: string;
  states?: State[];
}

export interface State {
  name: string;
  code: string;
}

export interface OrderItem {
  external_variant_id: string;
  product_id: string;
  quantity: number;
}

export interface MerchandiseListing {
  item_description: string;
  category: string;
  subcategory: string;
  tags: string;
}

export interface CategorySubCategory<MerchSubcategory> {
  name: string;
  value: string;
  child: MerchSubcategory[];
}

export interface MerchSubcategory {
  name: string;
  value: string;
}

export interface ProcessedProductsData {
  id: number;
  external_id: string;
  name: string;
  variants: number;
  synced: number;
  thumbnail_url: string;
  is_ignored: boolean;
  category: string;
  subcategory: string;
  tags: string;
}
