




















import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { isValidForResultCount } from "@/utils/common";
export default Vue.extend({
  name: "SellSortFilter",
  data() {
    return {
      sortValues: [] as { value: string; name: string }[],
      sortBy: ""
    };
  },
  created() {
    this.sortValues = [
      {
        value: "",
        name: "None"
      },
      {
        value: "price",
        name: "Price"
      }
    ];
  },
  computed: {
    ...mapGetters([
      "getTotalItems",
      "weapons",
      "shields",
      "characters",
      "junks",
      "weaponContractAddress",
      "shieldContractAddress",
      "characterContractAddress"
    ])
  },
  methods: {
    isValidForResultCount,
    ...mapActions(["sortByType"]),
    ...mapMutations(["setGlobalFilter"]),
    showFilters() {
      this.$root.$emit("filter-value", true);
    },
    onChangeSortType() {
      this.setGlobalFilter({ sortBy: this.sortBy });
    }
  },
  watch: {
    "$route.name"(data: string) {
      switch (data) {
      case "Weapons":
        this.sortValues = [
          {
            value: "",
            name: "None"
          },
          {
            value: "price",
            name: "Price"
          }
        ];
        break;
      case "Shields & Armors":
        this.sortValues = [
          {
            value: "",
            name: "None"
          },
          {
            value: "price",
            name: "Price"
          }
        ];
        break;

      case "Characters":
        this.sortValues = [
          {
            value: "",
            name: "None"
          },
          {
            value: "price",
            name: "Price"
          }
        ];
        break;
      case "Junk":
        this.sortValues = [
          {
            value: "",
            name: "None"
          },
          {
            value: "price",
            name: "Price"
          }
        ];
        break;
      case "Merch":
        this.sortValues = [
          {
            value: "",
            name: "None"
          },
          {
            value: "price",
            name: "Price"
          }
        ];
        break;
      case "Other NFT":
        this.sortValues = [
          {
            value: "",
            name: "None"
          },
          {
            value: "price",
            name: "Price"
          }
        ];
        break;
      }
    }
  }
});
