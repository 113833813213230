























import Vue from 'vue';
import { store } from '@/store';
import  { mapActions , mapGetters, mapState } from 'vuex';
import { IMarketFilter } from '@/interface/filters.interface';
import Pagination from './dumb/cryptoblades-pagination.vue';
import Spinner from './dumb/cryptoblades-spinner.vue';
import { Dictionary } from 'vue-router/types/router';

export default Vue.extend({
  props:{
    config: {
      type: Object,
      required: true
    },
    isOwnedInventory:{
      type: Boolean,
      required: false,
      default: false
    },
    sellerAddress: {
      type: String,
      required: false,
      default: ''
    },
  },
  components: {
    Pagination,
    Spinner,
  },
  //passing the filters on props for now...
  data() {
    return {
      filterIsToggled: {},
      totalWeapons: 0,
      isFirstLoad: true
    };
  },
  name: 'NftList',
  store : store,
  methods: {
    ...mapActions(
      [
        'fetchNfts',
        'getAccountNftsFromContractByType'
      ]
    ),
    getContract() : string{
      const env = window.location.href.startsWith('https://testmarketplace') ? 'test' : 'production';
      const chain = localStorage.getItem('currentChain') || 'BNB';
      return this.config.token.contractAddress[env][chain];
    },
    async onCurrentPageChange(page: number) {
      if(page) {
        localStorage.setItem("page", JSON.stringify(page));
        let snapshotQuery = this.$route.query as Dictionary<string>;

        this.$router.replace({name: this.$route.name || '', query: {...snapshotQuery, page: page.toString()} }).catch(err => err);        
        
        store.commit('setNftListCurrentPage', {type: this.config.type, page: page});
        await this.loadNfts();
      }
    },
    async loadNfts() {
      const routeName = this.$route.name;
      if(this.isOwnedInventory){
        await this.getAccountNftsFromContractByType({
          type: this.config.type,
        });
      }else{
        if(!this.$route.query.id && !this.$route.query.address){
          await this.fetchNfts({
            type: this.config.type,
            seller: routeName === 'MyListings' ? this.sellerAddress : '',
          });
        }
      }
    },
    async filterValueHandler(data: IMarketFilter) {
      const tabName = this.config.type;
      if(localStorage.getItem(tabName)){
        if(JSON.stringify(data) === localStorage.getItem(tabName) && !this.isFirstLoad) return;
      }
      this.filterIsToggled = data;
      
      localStorage.setItem(tabName, JSON.stringify(this.filterIsToggled));

      store.commit({
        type: 'setNftListFilter',
        filter: this.filterIsToggled
      });
      if(!this.isFirstLoad){
        this.onCurrentPageChange(1);
      }else{
        await this.loadNfts();
        this.isFirstLoad = false;
      }
    },
    isNftsEmpty(): boolean {
      if(this.nfts[this.config.type] && this.nfts[this.config.type].length > 0) 
        return false;
      return true;
    }
  },
  computed:
    {
    	...mapGetters(
    		[
    			'nfts',
    			'getNftListPagination',
    			'getLoadingState',
    			'contracts',
    			'weaponContractAddress',
    		]
    	),
    	...mapState(
    		[
    			'defaultAccount',
    			'weaponListPagination',
    			'globalBuyMarketFilter',
    			'weaponListFilter'
    		]
    	)
    },
  created() {
    const snapshotQuery = this.$route.query;
    const tabName = this.config.type;
    const pageQuery = snapshotQuery['page'];

    if(pageQuery)
      store.commit('setNftListCurrentPage', {type: this.config.type, page: +pageQuery});
    let filterValue: IMarketFilter = {
      elementFilter: [],
      rarityFilter: [],
      minPrice: 0,
      maxPrice: Number.MAX_SAFE_INTEGER,
    };
    if(localStorage.getItem(tabName))
      filterValue = JSON.parse(localStorage.getItem(tabName) || '');
    this.filterValueHandler(filterValue);
    
    this.$root.$on('filter-value', this.filterValueHandler);
    this.$root.$on(`refresh-${this.config.type}-inventory`, this.loadNfts);
  },
  destroyed() {
    this.$root.$off('filter-value', this.filterValueHandler);
    this.$root.$off(`refresh-${this.config.type}-inventory`, this.loadNfts);
    store.commit('setNftListCurrentPage', {type: this.config.type, page: 1});
  }
});

