






























































































import Vue from 'vue';
import {BModal} from 'bootstrap-vue';
import { getWeaponNameFromSeed } from '../weapon-names';
import { getWeaponArt } from '../weapon-arts-placeholder';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { fromWeiEther } from '@/utils/common';
import { MAX_LOW_STAR_BURN_POINTS, MAX_FOUR_STAR_BURN_POINTS, MAX_FIVE_STAR_BURN_POINTS } from '../default/dust.default';
import CurrencyConverter from './CurrencyConverter.vue';
import ElementTrait from './ElementTrait.vue';
import {setRarityName, setRarityColor, totalBattlePower, addCommas, elementToStat} from "@/utils/item.utils";
import BattlePower from './BattlePower.vue';
import { DEFAULT_TARGET_BUYER } from '@/default/common.default';
import { Nft } from '@/interface/nft.interface';
import skillIcon from '@/assets/apple-touch-icon.png';
export default Vue.extend({
  name: 'WeaponItem',
  props: {
    nft:{
      type: Object,
      required: true
    },
    contractAddress:{
      type: String,
      required: true,
    },
    nftType:{
      type: String,
      required: true,
    }
  },
  data() {
    return  {
      isSelling: true,
      MAX_LOW_STAR_BURN_POINTS: MAX_LOW_STAR_BURN_POINTS,
      MAX_FOUR_STAR_BURN_POINTS: MAX_FOUR_STAR_BURN_POINTS,
      MAX_FIVE_STAR_BURN_POINTS: MAX_FIVE_STAR_BURN_POINTS,
      DEFAULT_TARGET_BUYER,
      skillIcon
    };
  },
  computed: {
    isValidForPurchase(): boolean{
      return this.nft.targetBuyer === DEFAULT_TARGET_BUYER || this.nft.targetBuyer.toUpperCase() === this.defaultAccount.toUpperCase();
      // return false;
    },
    ...mapState(['defaultAccount', 'specialWeaponArts']),
    ...mapGetters(
      [
        'getWeaponDurability',
        'currentSkillBalance'
      ]
    ),
  },
  methods:{
    elementToStat,
    getPurchaseTooltipMsg(): string{
      if(this.nft.sellerStatus){
        return 'NFT is blacklisted';
      }
      if(!this.isValidForPurchase){
        return 'You are not the target buyer.';
      }
      return '';
    },
    ...mapActions(
      [
        'lookupWeaponPrice',
        'purchaseWeapon',
        'purchaseWeaponListing',
        'fetchWeaponsNftPrice',
        'fetchMarketNftPrice',
        'fetchMarketNftTargetBuyer',
        'fetchMarketNftSellerStatus',
        'cancelMarketListing',
        'fetchList',
        'lookupSellerStatus',
      ]
    ),
    ...mapMutations(['setCurrentWeapon']),
    setRarityName,
    setRarityColor,
    getWeaponArt,
    getWeaponNameFromSeed,
    totalBattlePower,
    addCommas,
    elementIcons(element : string) {
      return element.toLowerCase;
    },
    randomStamina(){
      return Math.floor(Math.random() * 100) + 30;
    },
    openModal(modal: string, weapon: Nft, type: string, isSellModal: boolean, isEditing?: boolean){
      this.$root.$emit(modal, weapon, type, isSellModal, isEditing);
    },
    hideSellItemModal() {
      (this.$refs['sellItem'] as BModal).hide();
    },
    async lookupWeaponPrice(id: number) {
      if (!this.contractAddress) return;

      return await this.fetchMarketNftPrice({
        nftContractAddr: this.contractAddress,
        tokenId: id,
      });
    },
    async lookupTargetBuyer(id: number) {
      if (!this.contractAddress) return;

      return await this.fetchMarketNftTargetBuyer({
        nftContractAddr: this.contractAddress,
        tokenId: id,
      });
    },
    async purchaseWeapon(){
      const price = await this.lookupWeaponPrice(this.nft.id);
      const targetBuyer = await this.lookupTargetBuyer(this.nft.id);
      const sellerStatus = await this.lookupSellerStatus(this.nft.id);

      /* Call component modal */
      if(!price){
        return this.openModal('weapon-view-modal', this.nft, 'sold', false);
      } // item is already sold
      if(Number(price) > Number(this.currentSkillBalance)) {
        return this.openModal('weapon-view-modal', this.nft, 'not-enough-skill-purchase', false);
      }
      if (targetBuyer !== DEFAULT_TARGET_BUYER) {
        if (targetBuyer.toUpperCase() !== this.defaultAccount.toUpperCase()) {
          return this.openModal('weapon-view-modal', this.nft, 'target-buyer', false);
        }
      }
      if (sellerStatus){
        return this.openModal('weapon-view-modal', this.nft, 'banned', false);
      }

      /* this is only called when all filters passed */
      try{
        await this.purchaseWeaponListing({
          tokenId: this.nft.id,
          maxPrice: price
        }).then(() => {
          this.openModal('weapon-view-modal', this.nft, 'purchased', false);
        });
      /* call error modal stating "An error occurred, please try again later." (must console log the error) */
      // eslint-disable-next-line no-empty
      } catch (error) {}
    },
    convertWeiToSkill(wei: string) {
      return fromWeiEther(wei);
    },
    cancelListing() {
      this.$root.$emit('confirm-modal', "Are you sure you want to cancel weapon listing?",
        async () => {
          let result = await this.cancelMarketListing({nftContractAddr: this.contractAddress, tokenId: this.nft.id});
          if(result){
            this.$root.$emit(`refresh-${this.nftType}-inventory`);
            this.$root.$emit(`weapon-view-modal`, this.nft, 'cancelled', false, false);
          }
        });
    }
  },
  components: { CurrencyConverter, ElementTrait, BattlePower }
});

