























import { toNumber } from 'lodash';
import Vue, { PropType } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { IWeapon } from '@/interface/weapon.interface';
import { getCharacterNameFromSeed } from '@/utils/character-name';
import { getCleanName } from '@/utils/rename-censor';
import { getCharacterArt } from '@/utils/character-arts-placeholder';
import SellItem from '@/components/modal/SellItem.vue';

export default Vue.extend({
  components:{ SellItem },
  props: {
    isSellModal: {
      type: Boolean
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    character: {
      type: Object as PropType<IWeapon>,
      required: false
    }
  },
  computed:{
    ...mapGetters(['characterContractAddress', 'getCharacterName']),
  },
  methods: {
    ...mapActions(['addMarketListing']),
    getCharacterNameFromSeed,
    getCharacterArt,

    closeModal() {
      this.$emit('close');
    },
    getCleanCharacterName(id:number) {
      return getCleanName(this.getCharacterName(id));
    },
    taxCalculator() {
      return ((Math.min(toNumber(this.listingSellPrice), 100000) * 1.1).toFixed(3)).toString();
    },
  },
  data() {
    return {
      listingSellPrice: '',
      targetBuyerAddress: '',
    };
  }
});
