import {
  CategorySubCategory,
  MerchandiseListing,
  MerchSubcategory
} from "@/interface/merchandise.interface";

export const MerchFilters: Array<CategorySubCategory<MerchSubcategory>> = [
  {
    name: "Apparel",
    value: "Apparel",
    child: [
      {
        name: "Tops",
        value: "Tops"
      },
      {
        name: "Sweatshirts",
        value: "Sweatshirts"
      }
    ]
  },
  {
    name: "Accessories",
    value: "Accessories",
    child: [
      {
        name: "Hats",
        value: "Hats"
      },
      {
        name: "Bags",
        value: "Bags"
      },
      {
        name: "Socks",
        value: "Socks"
      },
      {
        name: "Stickers",
        value: "Stickers"
      },
      {
        name: "Phone Cases",
        value: "Phone Cases"
      },
      {
        name: "Mouse Pads",
        value: "Mouse Pads"
      },
      {
        name: "Notebooks",
        value: "Notebooks"
      }
    ]
  },
  {
    name: "Home & Living",
    value: "Home & Living",
    child: [
      {
        name: "Drinkware",
        value: "Drinkware"
      },
      {
        name: "Blankets",
        value: "Blankets"
      },
      {
        name: "Posters",
        value: "Posters"
      },
      {
        name: "Canvas Prints",
        value: "Canvas Prints"
      }
    ]
  }
];

export function merchList(): Array<MerchandiseListing> {
  return [
    {
      item_description: "SKILL Embroidered Patch",
      category: "Accessories",
      subcategory: "Stickers",
      tags: "patch, embroidered"
    },
    {
      item_description:
        "Cryptoblade Stainless Steel Water Bottle Seiyria Illustration",
      category: "Home & Living",
      subcategory: "Drinkware",
      tags: "Seiyria, water bottle, drinkware"
    },
    {
      item_description:
        "Cryptoblade Stainless Steel Water Bottle Levitate Illustration",
      category: "Home & Living",
      subcategory: "Drinkware",
      tags: "levitate, buff daddy, water bottle, drinkware"
    },
    {
      item_description:
        "Cryptoblade Stainless Steel Water Bottle Kroge Illustration",
      category: "Home & Living",
      subcategory: "Drinkware",
      tags: "kroge, water bottle, drinkware"
    },
    {
      item_description: "Cryptoblades Kroge Illustration Matte Magic Mug",
      category: "Home & Living",
      subcategory: "Drinkware",
      tags: "kroge, mug, drinkware, magic"
    },
    {
      item_description: "Cryptoblades Kroge Illustration Glossy Magic Mug",
      category: "Home & Living",
      subcategory: "Drinkware",
      tags: "kroge, mug, drinkware, magic"
    },
    {
      item_description: "Cryptoblades Levitate Illustration Matte Magic Mug",
      category: "Home & Living",
      subcategory: "Drinkware",
      tags: "levitate, buff daddy, water bottle, drinkware"
    },
    {
      item_description: "Cryptoblades Levitate Illustration Glossy Magic Mug",
      category: "Home & Living",
      subcategory: "Drinkware",
      tags: "levitate, buff daddy, water bottle, drinkware"
    },
    {
      item_description: "Cryptoblades Seiyria Illustration Glossy Magic Mug",
      category: "Home & Living",
      subcategory: "Drinkware",
      tags: "Seiyria, mug, magic, drinkware"
    },
    {
      item_description: "Cryptoblades Seiyria Illustration Matte Magic Mug",
      category: "Home & Living",
      subcategory: "Drinkware",
      tags: "Seiyria, mug, magic, drinkware"
    },
    {
      item_description: "Cryptoblades Kingdoms Glossy Magic Mug Icon",
      category: "Home & Living",
      subcategory: "Drinkware",
      tags:
        "CryptoBlades: Kingdoms, CryptoBlades Kingdoms, CBK, mug, magic, drinkware"
    },
    {
      item_description: "Cryptoblades Kingdoms Glossy Magic Mug Logo Font",
      category: "Home & Living",
      subcategory: "Drinkware",
      tags:
        "CryptoBlades: Kingdoms, CryptoBlades Kingdoms, CBK, mug, magic, drinkware"
    },
    {
      item_description: "Cryptoblades Kingdoms Glossy Magic Mug Logo",
      category: "Home & Living",
      subcategory: "Drinkware",
      tags:
        "CryptoBlades: Kingdoms, CryptoBlades Kingdoms, CBK, mug, magic, drinkware"
    },
    {
      item_description: "Cryptoblades Kingdoms Matte Black Magic Mug Logo Font",
      category: "Home & Living",
      subcategory: "Drinkware",
      tags:
        "CryptoBlades: Kingdoms, CryptoBlades Kingdoms, CBK, mug, magic, drinkware"
    },
    {
      item_description: "Cryptoblades Kingdoms Matte Black Magic Mug Icon",
      category: "Home & Living",
      subcategory: "Drinkware",
      tags:
        "CryptoBlades: Kingdoms, CryptoBlades Kingdoms, CBK, mug, magic, drinkware"
    },
    {
      item_description: "Cryptoblades Kingdoms Matte Black Magic Mug Logo",
      category: "Home & Living",
      subcategory: "Drinkware",
      tags:
        "CryptoBlades: Kingdoms, CryptoBlades Kingdoms, CBK, mug, magic, drinkware"
    },
    {
      item_description:
        "Cryptoblades Seiyria Illustration Back Print T-Shirt U-Neck Light",
      category: "Apparel",
      subcategory: "Tops",
      tags: "Shirt, Tshirt, T-shirt, Seiyria, top"
    },
    {
      item_description:
        "Cryptoblades Seiyria Illustration Back Print T-Shirt U-Neck Dark",
      category: "Apparel",
      subcategory: "Tops",
      tags: "Shirt, Tshirt, T-shirt, Seiyria, top"
    },
    {
      item_description:
        "Cryptoblades Seiyria Illustration Front Print T-Shirt U-Neck Light",
      category: "Apparel",
      subcategory: "Tops",
      tags: "Shirt, Tshirt, T-shirt, Seiyria, top"
    },
    {
      item_description:
        "Cryptoblades Seiyria Illustration Front Print T-Shirt U-Neck Dark",
      category: "Apparel",
      subcategory: "Tops",
      tags: "Shirt, Tshirt, T-shirt, Seiyria, top"
    },
    {
      item_description:
        'Cryptoblades Levitate Illustration "C" Cap Back Print T-Shirt U-Neck',
      category: "Apparel",
      subcategory: "Tops",
      tags: "Shirt, Tshirt, T-shirt, Levitate, buff daddy, top"
    },
    {
      item_description:
        'Cryptoblades Levitate Illustration "Logo Cap" Back Print T-Shirt U-Neck',
      category: "Apparel",
      subcategory: "Tops",
      tags: "Shirt, Tshirt, T-shirt, Levitate, buff daddy, top"
    },
    {
      item_description:
        'Cryptoblades Levitate Illustration "C" Cap Front Print T-Shirt U-Neck',
      category: "Apparel",
      subcategory: "Tops",
      tags: "Shirt, Tshirt, T-shirt, Levitate, buff daddy, top"
    },
    {
      item_description:
        "Cryptoblades Levitate Cryptoblades Illustration Front Print T-Shirt U-Neck",
      category: "Apparel",
      subcategory: "Tops",
      tags: "Shirt, Tshirt, T-shirt, Kroge, top"
    },
    {
      item_description:
        "Cryptoblades Kroge Illustration Back Print T-Shirt U-Neck",
      category: "Apparel",
      subcategory: "Tops",
      tags: "Shirt, Tshirt, T-shirt, Levitate, buff daddy, top"
    },
    {
      item_description:
        "Cryptoblades Kroge Illustration Front Print T-Shirt U-Neck",
      category: "Apparel",
      subcategory: "Tops",
      tags: "Shirt, Tshirt, T-shirt, Levitate, buff daddy, top"
    },
    {
      item_description: "Cryptoblades Kingdoms Blanket Logo Blue Background",
      category: "Home & Living",
      subcategory: "Blankets",
      tags: "CryptoBlades: Kingdoms, CryptoBlades Kingdoms, CBK,  blanket, logo"
    },
    {
      item_description: "Cryptoblades Kingdoms Blanket Logo Black Background",
      category: "Home & Living",
      subcategory: "Blankets",
      tags: "CryptoBlades: Kingdoms, CryptoBlades Kingdoms, CBK,  blanket, logo"
    },
    {
      item_description: "Cryptoblades Kingdoms Spiral Notebook Logo Font Print",
      category: "Accessories",
      subcategory: "Notebooks",
      tags:
        "CryptoBlades: Kingdoms, CryptoBlades Kingdoms, CBK,  notebook, logo, writting"
    },
    {
      item_description: "Cryptoblades Kingdoms Spiral Notebook Icon Print",
      category: "Accessories",
      subcategory: "Notebooks",
      tags:
        "CryptoBlades: Kingdoms, CryptoBlades Kingdoms, CBK,  notebook, writting"
    },
    {
      item_description: "Cryptoblades Kingdoms Spiral notebook Logo Print",
      category: "Accessories",
      subcategory: "Notebooks",
      tags:
        "CryptoBlades: Kingdoms, CryptoBlades Kingdoms, CBK,  notebook, writting"
    },
    {
      item_description: "Cryptoblades Kingdoms Eco Tote Bag Logo Font Print",
      category: "Home & Living",
      subcategory: "Bags",
      tags:
        "CryptoBlades: Kingdoms, CryptoBlades Kingdoms, CBK, tote, bag, totebag"
    },
    {
      item_description:
        "Cryptoblades Kingdoms Eco Tote Bag Logo Unicolor Print",
      category: "Home & Living",
      subcategory: "Bags",
      tags:
        "CryptoBlades: Kingdoms, CryptoBlades Kingdoms, CBK, tote, bag, totebag"
    },
    {
      item_description:
        "Cryptoblades Kingdoms Eco Tote Bag Logo in Color Print",
      category: "Home & Living",
      subcategory: "Bags",
      tags:
        "CryptoBlades: Kingdoms, CryptoBlades Kingdoms, CBK, tote, bag, totebag"
    },
    {
      item_description: "Cryptoblades Kingdoms Mouse Pad Logo Black Background",
      category: "Accessories",
      subcategory: "Mouse Pads",
      tags:
        "CryptoBlades: Kingdoms, CryptoBlades Kingdoms, CBK, mouse, pad, mousepad"
    },
    {
      item_description: "Cryptoblades Kingdoms Mouse Pad Logo Blue Background",
      category: "Accessories",
      subcategory: "Mouse Pads",
      tags:
        "CryptoBlades: Kingdoms, CryptoBlades Kingdoms, CBK, mouse, pad, mousepad"
    },
    {
      item_description: "Cryptoblades Kingdoms Mouse Pad Font Black Background",
      category: "Accessories",
      subcategory: "Mouse Pads",
      tags:
        "CryptoBlades: Kingdoms, CryptoBlades Kingdoms, CBK, mouse, pad, mousepad"
    },
    {
      item_description: "Cryptoblades Kingdoms Mouse Pad Font Blue Background",
      category: "Accessories",
      subcategory: "Mouse Pads",
      tags:
        "CryptoBlades: Kingdoms, CryptoBlades Kingdoms, CBK, mouse, pad, mousepad"
    },
    {
      item_description:
        "Cryptoblades Kingdoms Framed Matte Poster Logo Blue Background",
      category: "Accessories",
      subcategory: "Mouse Pads",
      tags:
        "CryptoBlades: Kingdoms, CryptoBlades Kingdoms, CBK, mouse, pad, mousepad"
    },
    {
      item_description:
        "Cryptoblades Kingdoms Framed Matte Poster Logo Black Background",
      category: "Home & Living",
      subcategory: "Posters",
      tags: "CryptoBlades: Kingdoms, CryptoBlades Kingdoms, CBK, poster"
    },
    {
      item_description: "Cryptoblades Kingdoms Canvas Logo Blue Background",
      category: "Home & Living",
      subcategory: "Canvas Prints",
      tags: "CryptoBlades: Kingdoms, CryptoBlades Kingdoms, CBK, canvas"
    },
    {
      item_description: "Cryptoblades Kingdoms Canvas Logo Black Background",
      category: "Home & Living",
      subcategory: "Canvas Prints",
      tags: "CryptoBlades: Kingdoms, CryptoBlades Kingdoms, CBK, canvas"
    },
    {
      item_description: "Cryptoblades Kingdoms Samsung Case Logo Unicolor",
      category: "Accessories",
      subcategory: "Phone Cases",
      tags:
        "CryptoBlades: Kingdoms, CryptoBlades Kingdoms, CBK, phone, phone case, case, samsung"
    },
    {
      item_description: "Cryptoblades Kingdoms Samsung Case Logo in Color",
      category: "Accessories",
      subcategory: "Phone Cases",
      tags:
        "CryptoBlades: Kingdoms, CryptoBlades Kingdoms, CBK, phone, phone case, case, samsung"
    },
    {
      item_description:
        "Cryptoblades Kingdoms Biodegradable iPhone case Logo Unicolor",
      category: "Accessories",
      subcategory: "Phone Cases",
      tags:
        "CryptoBlades: Kingdoms, CryptoBlades Kingdoms, CBK, phone, phone case, case, apple, iphone"
    },
    {
      item_description:
        "Cryptoblades Kingdoms Biodegradable iPhone case Logo in Color",
      category: "Accessories",
      subcategory: "Phone Cases",
      tags:
        "CryptoBlades: Kingdoms, CryptoBlades Kingdoms, CBK, phone, phone case, case, apple, iphone"
    },
    {
      item_description:
        "Cryptoblade Kingdoms Stainless Steel Water Bottle Logo Font Print",
      category: "Home & Living",
      subcategory: "Drinkware",
      tags:
        "CryptoBlades: Kingdoms, CryptoBlades Kingdoms, CBK, water bottle, drinkware, bottle"
    },
    {
      item_description:
        "Cryptoblade Kingdoms Stainless Steel Water Bottle Logo Print",
      category: "Home & Living",
      subcategory: "Drinkware",
      tags:
        "CryptoBlades: Kingdoms, CryptoBlades Kingdoms, CBK, water bottle, drinkware, bottle"
    },
    {
      item_description:
        "Cryptoblade Kingdoms Stainless Steel Water Bottle Icon Print",
      category: "Home & Living",
      subcategory: "Drinkware",
      tags:
        "CryptoBlades: Kingdoms, CryptoBlades Kingdoms, CBK, water bottle, drinkware, bottle"
    },
    {
      item_description: "Cryptoblades Kingdom Stickers Icon White",
      category: "Accessories",
      subcategory: "Stickers",
      tags:
        "CryptoBlades: Kingdoms, CryptoBlades Kingdoms, CBK, sticker, stickers"
    },
    {
      item_description: "Cryptoblades Kingdom Stickers Icon Black",
      category: "Accessories",
      subcategory: "Stickers",
      tags:
        "CryptoBlades: Kingdoms, CryptoBlades Kingdoms, CBK, sticker, stickers"
    },
    {
      item_description: "Cryptoblades Kingdom Stickers Icon Gold",
      category: "Accessories",
      subcategory: "Stickers",
      tags:
        "CryptoBlades: Kingdoms, CryptoBlades Kingdoms, CBK, sticker, stickers"
    },
    {
      item_description: "Cryptoblades Kingdom Stickers Logo Gold",
      category: "Accessories",
      subcategory: "Stickers",
      tags:
        "CryptoBlades: Kingdoms, CryptoBlades Kingdoms, CBK, sticker, stickers"
    },
    {
      item_description: "Cryptoblades Kingdom Stickers Logo White",
      category: "Accessories",
      subcategory: "Stickers",
      tags:
        "CryptoBlades: Kingdoms, CryptoBlades Kingdoms, CBK, sticker, stickers"
    },
    {
      item_description: "Cryptoblades Kingdom Stickers Logo Black",
      category: "Accessories",
      subcategory: "Stickers",
      tags:
        "CryptoBlades: Kingdoms, CryptoBlades Kingdoms, CBK, sticker, stickers"
    },
    {
      item_description:
        "Cryptoblades Kingdoms Unisex Tank Top Logo Unicolor Print",
      category: "Apparel",
      subcategory: "Tops",
      tags:
        "CryptoBlades: Kingdoms, CryptoBlades Kingdoms, CBK, top, tank top, tank, muscle"
    },
    {
      item_description: "Cryptoblades Kingdoms Unisex Tank Top Logo Print",
      category: "Apparel",
      subcategory: "Tops",
      tags:
        "CryptoBlades: Kingdoms, CryptoBlades Kingdoms, CBK, top, tank top, tank, muscle"
    },
    {
      item_description: "Cryptoblades Spiral notebook Font Print copy",
      category: "Accessories",
      subcategory: "Notebooks",
      tags: "CryptoBlades, CB, notebook, writting "
    },
    {
      item_description: "Cryptoblades Spiral notebook Font Print",
      category: "Accessories",
      subcategory: "Notebooks",
      tags: "CryptoBlades, CB, notebook, writting "
    },
    {
      item_description: "Cryptoblades Spiral notebook Logo Print",
      category: "Accessories",
      subcategory: "Notebooks",
      tags: "CryptoBlades, CB, notebook, writting "
    },
    {
      item_description: "Cryptoblade Stainless Steel Water Bottle Sword Print",
      category: "Home & Living",
      subcategory: "Drinkware",
      tags: "CryptoBlades, CB, water bottle, bottle, drinkware"
    },
    {
      item_description: "Cryptoblade Stainless Steel Water Bottle Font Print",
      category: "Home & Living",
      subcategory: "Drinkware",
      tags: "CryptoBlades, CB, water bottle, bottle, drinkware"
    },
    {
      item_description: "Cryptoblade Stainless Steel Water Bottle Logo Print",
      category: "Home & Living",
      subcategory: "Drinkware",
      tags: "CryptoBlades, CB, water bottle, bottle, drinkware"
    },
    {
      item_description:
        "Cryptoblades Kingdoms Muscle Shirt Logo Print Unicolor White",
      category: "Apparel",
      subcategory: "Tops",
      tags:
        "CryptoBlades Kingdoms, CryptoBlades: Kingdoms, CBK, muscle, tank, tank top, top"
    },
    {
      item_description:
        "Cryptoblades Kingdoms Muscle Shirt Logo Print Unicolor Dark",
      category: "Apparel",
      subcategory: "Tops",
      tags:
        "CryptoBlades Kingdoms, CryptoBlades: Kingdoms, CBK, muscle, tank, tank top, top"
    },
    {
      item_description: "Cryptoblades Kingdoms Muscle Shirt Logo-Color Print",
      category: "Apparel",
      subcategory: "Tops",
      tags:
        "CryptoBlades Kingdoms, CryptoBlades: Kingdoms, CBK, muscle, tank, tank top, top"
    },
    {
      item_description: "Cryptoblades Muscle Shirt Logo Print Unicolor White",
      category: "Apparel",
      subcategory: "Tops",
      tags: "CryptoBlades, CB, muscle, tank, tank top, top"
    },
    {
      item_description: "Cryptoblades Muscle Shirt Logo Print Unicolor Dark",
      category: "Apparel",
      subcategory: "Tops",
      tags: "CryptoBlades, CB, muscle, tank, tank top, top"
    },
    {
      item_description: "Cryptoblades Muscle Shirt Logo-Color Print",
      category: "Apparel",
      subcategory: "Tops",
      tags: "CryptoBlades, CB, muscle, tank, tank top, top"
    },
    {
      item_description: "Cryptoblades Kingdoms Ankle socks Font Print",
      category: "Accessories",
      subcategory: "Socks",
      tags: "CryptoBlades Kingdoms, CryptoBlades: Kingdoms, CBK, socks"
    },
    {
      item_description: "Cryptoblades Kingdoms Crew Socks Embroidery Icon Dark",
      category: "Accessories",
      subcategory: "Socks",
      tags: "CryptoBlades Kingdoms, CryptoBlades: Kingdoms, CBK, socks"
    },
    {
      item_description:
        "Cryptoblades Kingdoms Crew Socks Embroidery Icon White",
      category: "Accessories",
      subcategory: "Socks",
      tags: "CryptoBlades Kingdoms, CryptoBlades: Kingdoms, CBK, socks"
    },
    {
      item_description: 'Cryptoblades Crew Socks Embroidery "Sword" White',
      category: "Accessories",
      subcategory: "Socks",
      tags: "CryptoBlades, CB, socks"
    },
    {
      item_description: 'Cryptoblades Crew Socks Embroidery "Sword" Dark',
      category: "Accessories",
      subcategory: "Socks",
      tags: "CryptoBlades, CB, socks"
    },
    {
      item_description:
        "Cryptoblades Kingdoms Unisex Zip Hoodie Embroidery Logo Font Print",
      category: "Apparel",
      subcategory: "Sweatshirts",
      tags:
        "CryptoBlades Kingdoms, CryptoBlades: Kingdoms, CBK, sweatshirt, hoodie"
    },
    {
      item_description:
        "Cryptoblades Kingdoms Unisex Zip Hoodie Embroidery Logo Print Unicolor",
      category: "Apparel",
      subcategory: "Sweatshirts",
      tags:
        "CryptoBlades Kingdoms, CryptoBlades: Kingdoms, CBK, sweatshirt, hoodie"
    },
    {
      item_description:
        "Cryptoblades Kingdoms Unisex Zip Hoodie Embroidery Logo Print in Color",
      category: "Apparel",
      subcategory: "Sweatshirts",
      tags:
        "CryptoBlades Kingdoms, CryptoBlades: Kingdoms, CBK, sweatshirt, hoodie"
    },
    {
      item_description:
        "Cryptoblades Kingdoms Unisex Zip Hoodie Embroidery Icon",
      category: "Apparel",
      subcategory: "Sweatshirts",
      tags:
        "CryptoBlades Kingdoms, CryptoBlades: Kingdoms, CBK, sweatshirt, hoodie"
    },
    {
      item_description:
        "Cryptoblades Kingdoms Unisex Hoodie Double Logo Print Light",
      category: "Apparel",
      subcategory: "Sweatshirts",
      tags:
        "CryptoBlades Kingdoms, CryptoBlades: Kingdoms, CBK, sweatshirt, hoodie"
    },
    {
      item_description:
        "Cryptoblades Kingdoms Unisex Hoodie Double Logo Print Dark",
      category: "Apparel",
      subcategory: "Sweatshirts",
      tags:
        "CryptoBlades Kingdoms, CryptoBlades: Kingdoms, CBK, sweatshirt, hoodie"
    },
    {
      item_description: "Cryptoblades Kingdoms Unisex Hoodie Logo Print",
      category: "Apparel",
      subcategory: "Sweatshirts",
      tags:
        "CryptoBlades Kingdoms, CryptoBlades: Kingdoms, CBK, sweatshirt, hoodie"
    },
    {
      item_description:
        "Cryptoblades Kingdoms Unisex Hoodie Embroidery Logo Light",
      category: "Apparel",
      subcategory: "Sweatshirts",
      tags:
        "CryptoBlades Kingdoms, CryptoBlades: Kingdoms, CBK, sweatshirt, hoodie"
    },
    {
      item_description:
        "Cryptoblades Kingdoms Unisex Hoodie Embroidery Logo Dark",
      category: "Apparel",
      subcategory: "Sweatshirts",
      tags:
        "CryptoBlades Kingdoms, CryptoBlades: Kingdoms, CBK, sweatshirt, hoodie"
    },
    {
      item_description:
        "Cryptoblades Kingdoms Unisex Hoodie Embroidery Icon Light",
      category: "Apparel",
      subcategory: "Sweatshirts",
      tags:
        "CryptoBlades Kingdoms, CryptoBlades: Kingdoms, CBK, sweatshirt, hoodie"
    },
    {
      item_description:
        "Cryptoblades Kingdoms Unisex Hoodie Embroidery Icon Dark",
      category: "Apparel",
      subcategory: "Sweatshirts",
      tags:
        "CryptoBlades Kingdoms, CryptoBlades: Kingdoms, CBK, sweatshirt, hoodie"
    },
    {
      item_description:
        "Cryptoblades Kingdoms Old School Bucket Hat Embroidery Icon White",
      category: "Accessories",
      subcategory: "Hats",
      tags:
        "CryptoBlades Kingdoms, CryptoBlades: Kingdoms, CBK, hat, bucket hat, bucket"
    },
    {
      item_description:
        "Cryptoblades Kingdoms Old School Bucket Hat Embroidery Icon Dark",
      category: "Accessories",
      subcategory: "Hats",
      tags:
        "CryptoBlades Kingdoms, CryptoBlades: Kingdoms, CBK, hat, bucket hat, bucket"
    },
    {
      item_description:
        "Cryptoblades Kingdoms Old School Bucket Hat Embroidery Logo White",
      category: "Accessories",
      subcategory: "Hats",
      tags:
        "CryptoBlades Kingdoms, CryptoBlades: Kingdoms, CBK, hat, bucket hat, bucket"
    },
    {
      item_description:
        "Cryptoblades Kingdoms Old School Bucket Hat Embroidery Logo Dark",
      category: "Accessories",
      subcategory: "Hats",
      tags:
        "CryptoBlades Kingdoms, CryptoBlades: Kingdoms, CBK, hat, bucket hat, bucket"
    },
    {
      item_description:
        "Cryptoblades Kingdoms Trucker Cap Embroidery Icon White",
      category: "Accessories",
      subcategory: "Hats",
      tags:
        "CryptoBlades Kingdoms, CryptoBlades: Kingdoms, CBK, hat, trucker hat, trucker"
    },
    {
      item_description:
        "Cryptoblades Kingdoms Trucker Cap Embroidery Icon Dark",
      category: "Accessories",
      subcategory: "Hats",
      tags:
        "CryptoBlades Kingdoms, CryptoBlades: Kingdoms, CBK, hat, trucker hat, trucker"
    },
    {
      item_description:
        "Cryptoblades Kingdoms Trucker Cap Embroidery Logo White",
      category: "Accessories",
      subcategory: "Hats",
      tags:
        "CryptoBlades Kingdoms, CryptoBlades: Kingdoms, CBK, hat, trucker hat, trucker"
    },
    {
      item_description:
        "Cryptoblades Kingdoms Trucker Cap Embroidery Logo Dark",
      category: "Accessories",
      subcategory: "Hats",
      tags:
        "CryptoBlades Kingdoms, CryptoBlades: Kingdoms, CBK, hat, trucker hat, trucker"
    },
    {
      item_description:
        "Cryptoblades Kingdoms Classic Dad Hat Embroidery Logo White",
      category: "Accessories",
      subcategory: "Hats",
      tags:
        "CryptoBlades Kingdoms, CryptoBlades: Kingdoms, CBK, hat, dad hat, dad"
    },
    {
      item_description:
        "Cryptoblades Kingdoms Classic Dad Hat Embroidery Logo Dark",
      category: "Accessories",
      subcategory: "Hats",
      tags:
        "CryptoBlades Kingdoms, CryptoBlades: Kingdoms, CBK, hat, dad hat, dad"
    },
    {
      item_description:
        "Cryptoblades Kingdoms Classic Dad Hat Embroidery Icon White",
      category: "Accessories",
      subcategory: "Hats",
      tags:
        "CryptoBlades Kingdoms, CryptoBlades: Kingdoms, CBK, hat, dad hat, dad"
    },
    {
      item_description:
        "Cryptoblades Kingdoms Classic Dad Hat Embroidery Icon Dark",
      category: "Accessories",
      subcategory: "Hats",
      tags:
        "CryptoBlades Kingdoms, CryptoBlades: Kingdoms, CBK, hat, dad hat, dad"
    },
    {
      item_description: "Cryptoblades Kingdoms Beanie Logo Embroidery",
      category: "Accessories",
      subcategory: "Hats",
      tags:
        "CryptoBlades Kingdoms, CryptoBlades: Kingdoms, CBK, hat, winter, beanie, cap"
    },
    {
      item_description: 'Cryptoblades Kingdoms Beanie "Icon" Embroidery',
      category: "Accessories",
      subcategory: "Hats",
      tags:
        "CryptoBlades Kingdoms, CryptoBlades: Kingdoms, CBK, hat, winter, beanie, cap"
    },
    {
      item_description: 'Cryptoblades Kingdoms Denim T-Shirt "Icon" Embroidery',
      category: "Apparel",
      subcategory: "Tops",
      tags:
        "CryptoBlades Kingdoms, CryptoBlades: Kingdoms, CBK, shirt, Tshirt, T-shirt, denim"
    },
    {
      item_description:
        "Cryptoblades Kingdoms V-Neck T-Shirt Logo & Icon Black",
      category: "Apparel",
      subcategory: "Tops",
      tags:
        "CryptoBlades Kingdoms, CryptoBlades: Kingdoms, CBK, shirt, Tshirt, T-shirt, V, v-neck, neck"
    },
    {
      item_description: "Cryptoblades Kingdoms V-Neck T-Shirt Logo in Color",
      category: "Apparel",
      subcategory: "Tops",
      tags:
        "CryptoBlades Kingdoms, CryptoBlades: Kingdoms, CBK, shirt, Tshirt, T-shirt, V, v-neck, neck"
    },
    {
      item_description: "Cryptoblades Kingdoms V-Neck T-Shirt Logo Icon Black",
      category: "Apparel",
      subcategory: "Tops",
      tags:
        "CryptoBlades Kingdoms, CryptoBlades: Kingdoms, CBK, shirt, Tshirt, T-shirt, V, v-neck, neck"
    },
    {
      item_description: "Cryptoblades Kingdoms Logo in Color T-Shirt Dark",
      category: "Apparel",
      subcategory: "Tops",
      tags:
        "CryptoBlades Kingdoms, CryptoBlades: Kingdoms, CBK, shirt, Tshirt, T-shirt"
    },
    {
      item_description: "Cryptoblades Kingdoms Logo T-Shirt Dark",
      category: "Apparel",
      subcategory: "Tops",
      tags:
        "CryptoBlades Kingdoms, CryptoBlades: Kingdoms, CBK, shirt, Tshirt, T-shirt"
    },
    {
      item_description: "Cryptoblades Kingdoms Full Logo T-Shirt Dark",
      category: "Apparel",
      subcategory: "Tops",
      tags:
        "CryptoBlades Kingdoms, CryptoBlades: Kingdoms, CBK, shirt, Tshirt, T-shirt"
    },
    {
      item_description: "Cryptoblades Kingdoms Full Logo T-Shirt Light",
      category: "Apparel",
      subcategory: "Tops",
      tags:
        "CryptoBlades Kingdoms, CryptoBlades: Kingdoms, CBK, shirt, Tshirt, T-shirt"
    },
    {
      item_description: "Cryptoblades Kingdoms Logo T-Shirt Light",
      category: "Apparel",
      subcategory: "Tops",
      tags:
        "CryptoBlades Kingdoms, CryptoBlades: Kingdoms, CBK, shirt, Tshirt, T-shirt"
    },
    {
      item_description: "Cryptoblades Blanket Logo Black Background",
      category: "Home & Living",
      subcategory: "Blankets",
      tags: "CryptoBlades, CB, blanket"
    },
    {
      item_description: "Cryptoblades Blanket Logo Blue Background",
      category: "Home & Living",
      subcategory: "Blankets",
      tags: "CryptoBlades, CB, blanket"
    },
    {
      item_description: "Cryptoblades Blanket CBK Map Black Background",
      category: "Home & Living",
      subcategory: "Blankets",
      tags: "CryptoBlades Kingdoms, CryptoBlades: Kingdoms, CBk, blanket, map"
    },
    {
      item_description: "Cryptoblades Blanket CBK Map Blue Background",
      category: "Home & Living",
      subcategory: "Blankets",
      tags: "CryptoBlades Kingdoms, CryptoBlades: Kingdoms, CBk, blanket, map"
    },
    {
      item_description: "Cryptoblades Eco Tote Bag CBK Map Print Color",
      category: "Accessories",
      subcategory: "Bags",
      tags:
        "CryptoBlades Kingdoms, CryptoBlades: Kingdoms, CBk, tote, tote bag, bag, map"
    },
    {
      item_description: "Cryptoblades Eco Tote Bag CBK Map Print BW",
      category: "Accessories",
      subcategory: "Bags",
      tags:
        "CryptoBlades Kingdoms, CryptoBlades: Kingdoms, CBk, tote, tote bag, bag, map"
    },
    {
      item_description: "Cryptoblades Eco Tote Bag Font Print",
      category: "Accessories",
      subcategory: "Bags",
      tags: "CryptoBlades, CB, tote, tote bag, bag"
    },
    {
      item_description: "Cryptoblades Eco Tote Bag Sword Print",
      category: "Accessories",
      subcategory: "Bags",
      tags: "CryptoBlades, CB, tote, tote bag, bag"
    },
    {
      item_description: "Cryptoblades Eco Tote Bag Logo Print Unicolor",
      category: "Accessories",
      subcategory: "Bags",
      tags: "CryptoBlades, CB, tote, tote bag, bag"
    },
    {
      item_description: "Cryptoblades Eco Tote Bag Logo Print Color",
      category: "Accessories",
      subcategory: "Bags",
      tags: "CryptoBlades, CB, tote, tote bag, bag"
    },
    {
      item_description: "Cryptoblades Mouse Pad CBK Map Sand Background",
      category: "Accessories",
      subcategory: "Mouse Pads",
      tags:
        "CryptoBlades Kingdoms, CryptoBlades: Kingdoms, CBk, mouse, mouse pad, pad, map"
    },
    {
      item_description: "Cryptoblades Mouse Pad CBK Map Blue Background",
      category: "Accessories",
      subcategory: "Mouse Pads",
      tags:
        "CryptoBlades Kingdoms, CryptoBlades: Kingdoms, CBk, mouse, mouse pad, pad, map"
    },
    {
      item_description: "Cryptoblades Mouse Pad Font Blue Background",
      category: "Accessories",
      subcategory: "Mouse Pads",
      tags: "CryptoBlades, CB, mouse, mouse pad, pad"
    },
    {
      item_description: "Cryptoblades Mouse Pad Font Black Background",
      category: "Accessories",
      subcategory: "Mouse Pads",
      tags: "CryptoBlades, CB, mouse, mouse pad, pad"
    },
    {
      item_description: "Cryptoblades Mouse Pad Logo Blue Background",
      category: "Accessories",
      subcategory: "Mouse Pads",
      tags: "CryptoBlades, CB, mouse, mouse pad, pad"
    },
    {
      item_description: "Cryptoblades Mouse Pad Logo Black Background",
      category: "Accessories",
      subcategory: "Mouse Pads",
      tags: "CryptoBlades, CB, mouse, mouse pad, pad"
    },
    {
      item_description: "Cryptoblades Glossy Magic Mug CBK Map Color",
      category: "Home & Living",
      subcategory: "Drinkware",
      tags:
        "CryptoBlades Kingdoms, CryptoBlades: Kingdoms, CBk, mug, magic, map"
    },
    {
      item_description: "Cryptoblades Glossy Magic Mug CBK Map BW",
      category: "Home & Living",
      subcategory: "Drinkware",
      tags:
        "CryptoBlades Kingdoms, CryptoBlades: Kingdoms, CBk, mug, magic, map"
    },
    {
      item_description: "Cryptoblades Glossy Magic Mug Font",
      category: "Home & Living",
      subcategory: "Drinkware",
      tags: "CryptoBlades, CB, mug, magic"
    },
    {
      item_description: "Cryptoblades Glossy Magic Mug Sword",
      category: "Home & Living",
      subcategory: "Drinkware",
      tags: "CryptoBlades, CB, mug, magic"
    },
    {
      item_description: "Cryptoblades Glossy Magic Mug Logo",
      category: "Home & Living",
      subcategory: "Drinkware",
      tags: "CryptoBlades, CB, mug, magic"
    },
    {
      item_description: "Cryptoblades Matte Black Magic Mug CBK Map Color",
      category: "Home & Living",
      subcategory: "Drinkware",
      tags: "CryptoBlades, CB, mug, magic"
    },
    {
      item_description: "Cryptoblades Matte Black Magic Mug CBK Map BW",
      category: "Home & Living",
      subcategory: "Drinkware",
      tags:
        "CryptoBlades Kingdoms, CryptoBlades: Kingdoms, CBk, mug, magic, map"
    },
    {
      item_description: "Cryptoblades Matte Black Magic Mug CBK Map BW",
      category: "Home & Living",
      subcategory: "Drinkware",
      tags:
        "CryptoBlades Kingdoms, CryptoBlades: Kingdoms, CBk, mug, magic, map"
    },
    {
      item_description: "Cryptoblades Matte Black Magic Mug Font",
      category: "Home & Living",
      subcategory: "Drinkware",
      tags: "CryptoBlades, CB, mug, magic"
    },
    {
      item_description: "Cryptoblades Matte Black Magic Mug Sword",
      category: "Home & Living",
      subcategory: "Drinkware",
      tags: "CryptoBlades, CB, mug, magic"
    },
    {
      item_description: "Cryptoblades Matte Black Magic Mug Logo",
      category: "Home & Living",
      subcategory: "Drinkware",
      tags: "CryptoBlades, CB, mug, magic"
    },
    {
      item_description: "Cryptoblades Canvas CBK Map Black Background",
      category: "Home & Living",
      subcategory: "Canvas Prints",
      tags: "CryptoBlades Kingdoms, CryptoBlades: Kingdoms, CBk, canvas, map"
    },
    {
      item_description: "Cryptoblades Canvas CBK Map Blue Background",
      category: "Home & Living",
      subcategory: "Canvas Prints",
      tags: "CryptoBlades Kingdoms, CryptoBlades: Kingdoms, CBk, canvas, map"
    },
    {
      item_description: "Cryptoblades Canvas Logo Blue Background",
      category: "Home & Living",
      subcategory: "Canvas Prints",
      tags: "CryptoBlades, CB, canvas"
    },
    {
      item_description: "Cryptoblades Canvas Logo Black Background",
      category: "Home & Living",
      subcategory: "Canvas Prints",
      tags: "CryptoBlades, CB, canvas"
    },
    {
      item_description:
        "Cryptoblades Framed Matte Poster CBK Map Black Background",
      category: "Home & Living",
      subcategory: "Posters",
      tags: "CryptoBlades Kingdoms, CryptoBlades: Kingdoms, CBk, poster, map"
    },
    {
      item_description:
        "Cryptoblades Framed Matte Poster CBK Map Blue Background",
      category: "Home & Living",
      subcategory: "Posters",
      tags: "CryptoBlades Kingdoms, CryptoBlades: Kingdoms, CBk, poster, map"
    },
    {
      item_description: "Cryptoblades Framed Matte Poster Logo Blue Background",
      category: "Home & Living",
      subcategory: "Posters",
      tags: "CryptoBlades, CB, poster"
    },
    {
      item_description:
        "Cryptoblades Framed Matte Poster Logo Black Background",
      category: "Home & Living",
      subcategory: "Posters",
      tags: "CryptoBlades, CB, poster"
    },
    {
      item_description: "Cryptoblades Samsung Case Logo Unicolor",
      category: "Accessories",
      subcategory: "Phone Cases",
      tags: "CryptoBlades, CB, phone, case, samsung"
    },
    {
      item_description: "Cryptoblades Samsung Case Logo in Color",
      category: "Accessories",
      subcategory: "Phone Cases",
      tags: "CryptoBlades, CB, phone, case, samsung"
    },
    {
      item_description: "Cryptoblades Biodegradable iPhone case Logo Unicolor",
      category: "Accessories",
      subcategory: "Phone Cases",
      tags: "CryptoBlades, CB, phone, case, apple, iphone"
    },
    {
      item_description: "Cryptoblades Biodegradable iPhone case Logo in Color",
      category: "Accessories",
      subcategory: "Phone Cases",
      tags: "CryptoBlades, CB, phone, case, apple, iphone"
    },
    {
      item_description: "Cryptoblades Stickers Logo Gold",
      category: "Accessories",
      subcategory: "Stickers",
      tags: "CryptoBlades, CB, stickers"
    },
    {
      item_description: "Cryptoblades Stickers Logo White",
      category: "Accessories",
      subcategory: "Stickers",
      tags: "CryptoBlades, CB, stickers"
    },
    {
      item_description: "Cryptoblades Stickers Logo Black",
      category: "Accessories",
      subcategory: "Stickers",
      tags: "CryptoBlades, CB, stickers"
    },
    {
      item_description: "Cryptoblades Stickers Logo Color",
      category: "Accessories",
      subcategory: "Stickers",
      tags: "CryptoBlades, CB, stickers"
    },
    {
      item_description: "Cryptoblades Stickers Logo BW",
      category: "Accessories",
      subcategory: "Stickers",
      tags: "CryptoBlades, CB, stickers"
    },
    {
      item_description: "Cryptoblades Stickers CBK Map BW",
      category: "Accessories",
      subcategory: "Stickers",
      tags: "CryptoBlades Kingdoms, CryptoBlades: Kingdoms, CBk, sticker, map"
    },
    {
      item_description: "Cryptoblades Stickers CBK Map Color",
      category: "Accessories",
      subcategory: "Stickers",
      tags: "CryptoBlades Kingdoms, CryptoBlades: Kingdoms, CBk, sticker, map"
    },
    {
      item_description: "Cryptoblades Stickers Sword Color",
      category: "Accessories",
      subcategory: "Stickers",
      tags: "CryptoBlades, CB, stickers"
    },
    {
      item_description: "Cryptoblades Stickers Sword Black",
      category: "Accessories",
      subcategory: "Stickers",
      tags: "CryptoBlades, CB, stickers"
    },
    {
      item_description: "Cryptoblades Unisex Tank Top Logo Unicolor Print",
      category: "Apparel",
      subcategory: "Tops",
      tags: "CryptoBlades, CB, top, tank top, tank, muscle"
    },
    {
      item_description: "Cryptoblades Unisex Tank Top Logo Print",
      category: "Apparel",
      subcategory: "Tops",
      tags: "CryptoBlades, CB, top, tank top, tank, muscle"
    },
    {
      item_description: "Cryptoblades Ankle Socks Font Print",
      category: "Accessories",
      subcategory: "Socks",
      tags: "CryptoBlades, CB, socks"
    },
    {
      item_description: "Cryptoblades Unisex Zip Hoodie Logo Print UniColor",
      category: "Apparel",
      subcategory: "Sweatshirts",
      tags: "CryptoBlades, CB, sweatshirt, sweat, hoodie"
    },
    {
      item_description: "Cryptoblades Unisex Zip Hoodie Logo Print",
      category: "Apparel",
      subcategory: "Sweatshirts",
      tags: "CryptoBlades, CB, sweatshirt, sweat, hoodie"
    },
    {
      item_description: 'Cryptoblades Unisex Zip Hoodie Embroidery "C"',
      category: "Apparel",
      subcategory: "Sweatshirts",
      tags: "CryptoBlades, CB, sweatshirt, sweat, hoodie"
    },
    {
      item_description: 'Cryptoblades Unisex Zip Hoodie Embroidery "Sword"',
      category: "Apparel",
      subcategory: "Sweatshirts",
      tags: "CryptoBlades, CB, sweatshirt, sweat, hoodie"
    },
    {
      item_description: "Cryptoblades Unisex Hoodie Logo Double Print Light",
      category: "Apparel",
      subcategory: "Sweatshirts",
      tags: "CryptoBlades, CB, sweatshirt, sweat, hoodie"
    },
    {
      item_description: "Cryptoblades Unisex Hoodie Logo Double Print Dark",
      category: "Apparel",
      subcategory: "Sweatshirts",
      tags: "CryptoBlades, CB, sweatshirt, sweat, hoodie"
    },
    {
      item_description: "Cryptoblades Unisex Hoodie Logo-Print",
      category: "Apparel",
      subcategory: "Sweatshirts",
      tags: "CryptoBlades, CB, sweatshirt, sweat, hoodie"
    },
    {
      item_description: 'Cryptoblades Unisex Hoodie Embroidery "Logo" Light',
      category: "Apparel",
      subcategory: "Sweatshirts",
      tags: "CryptoBlades, CB, sweatshirt, sweat, hoodie"
    },
    {
      item_description: 'Cryptoblades Unisex Hoodie Embroidery "Logo" Dark',
      category: "Apparel",
      subcategory: "Sweatshirts",
      tags: "CryptoBlades, CB, sweatshirt, sweat, hoodie"
    },
    {
      item_description: 'Cryptoblades Unisex Hoodie Embroidery "C" Light',
      category: "Apparel",
      subcategory: "Sweatshirts",
      tags: "CryptoBlades, CB, sweatshirt, sweat, hoodie"
    },
    {
      item_description: 'Cryptoblades Unisex Hoodie Embroidery "C" Dark',
      category: "Apparel",
      subcategory: "Sweatshirts",
      tags: "CryptoBlades, CB, sweatshirt, sweat, hoodie"
    },
    {
      item_description: 'Cryptoblades Unisex Hoodie Embroidery "Sword" Light',
      category: "Apparel",
      subcategory: "Sweatshirts",
      tags: "CryptoBlades, CB, sweatshirt, sweat, hoodie"
    },
    {
      item_description: 'Cryptoblades Unisex Hoodie Embroidery "Sword" Dark',
      category: "Apparel",
      subcategory: "Sweatshirts",
      tags: "CryptoBlades, CB, sweatshirt, sweat, hoodie"
    },
    {
      item_description:
        'Cryptoblades Old School Bucket Hat Embroidery "Logo" White',
      category: "Accessories",
      subcategory: "Hats",
      tags: "CryptoBlades, CB, hat, bucket"
    },
    {
      item_description:
        'Cryptoblades Old School Bucket Hat Embroidery "Logo" Dark',
      category: "Accessories",
      subcategory: "Hats",
      tags: "CryptoBlades, CB, hat, bucket"
    },
    {
      item_description:
        'Cryptoblades Old School Bucket Hat Embroidery "Sword" White',
      category: "Accessories",
      subcategory: "Hats",
      tags: "CryptoBlades, CB, hat, bucket"
    },
    {
      item_description:
        'Cryptoblades Old School Bucket Hat Embroidery "Sword" Dark',
      category: "Accessories",
      subcategory: "Hats",
      tags: "CryptoBlades, CB, hat, bucket"
    },
    {
      item_description:
        'Cryptoblades Old School Bucket Hat Embroidery "C" White',
      category: "Accessories",
      subcategory: "Hats",
      tags: "CryptoBlades, CB, hat, bucket"
    },
    {
      item_description:
        'Cryptoblades Old School Bucket Hat Embroidery "C" Dark',
      category: "Accessories",
      subcategory: "Hats",
      tags: "CryptoBlades, CB, hat, bucket"
    },
    {
      item_description: 'Cryptoblades Trucker Cap Embroidery "Sword" White',
      category: "Accessories",
      subcategory: "Hats",
      tags: "CryptoBlades, CB, hat, trucker"
    },
    {
      item_description: 'Cryptoblades Trucker Cap Embroidery "Sword" Dark',
      category: "Accessories",
      subcategory: "Hats",
      tags: "CryptoBlades, CB, hat, trucker"
    },
    {
      item_description: 'Cryptoblades Trucker Cap Embroidery "C" White',
      category: "Accessories",
      subcategory: "Hats",
      tags: "CryptoBlades, CB, hat, trucker"
    },
    {
      item_description: 'Cryptoblades Trucker Cap Embroidery "C" Dark',
      category: "Accessories",
      subcategory: "Hats",
      tags: "CryptoBlades, CB, hat, trucker"
    },
    {
      item_description: 'Cryptoblades Trucker Cap Embroidery "Logo" White',
      category: "Accessories",
      subcategory: "Hats",
      tags: "CryptoBlades, CB, hat, trucker"
    },
    {
      item_description: 'Cryptoblades Trucker Cap Embroidery "Logo" Dark',
      category: "Accessories",
      subcategory: "Hats",
      tags: "CryptoBlades, CB, hat, trucker"
    },
    {
      item_description: 'Cryptoblades Classic Dad Hat Embroidery "Sword" White',
      category: "Accessories",
      subcategory: "Hats",
      tags: "CryptoBlades, CB, hat, dad"
    },
    {
      item_description: 'Cryptoblades Classic Dad Hat Embroidery "Sword" Dark',
      category: "Accessories",
      subcategory: "Hats",
      tags: "CryptoBlades, CB, hat, dad"
    },
    {
      item_description: 'Cryptoblades Classic Dad Hat Embroidery "C" White',
      category: "Accessories",
      subcategory: "Hats",
      tags: "CryptoBlades, CB, hat, dad"
    },
    {
      item_description: 'Cryptoblades Classic Dad Hat Embroidery "C" Dark',
      category: "Accessories",
      subcategory: "Hats",
      tags: "CryptoBlades, CB, hat, dad"
    },
    {
      item_description: "Cryptoblades Beanie Logo Embroidery",
      category: "Accessories",
      subcategory: "Hats",
      tags: "CryptoBlades, CB, hat, beanie, cap, winter"
    },
    {
      item_description: 'Cryptoblades Beanie "Sword" Embroidery',
      category: "Accessories",
      subcategory: "Hats",
      tags: "CryptoBlades, CB, hat, beanie, cap, winter"
    },
    {
      item_description: 'Cryptoblades Beanie "C" Embroidery',
      category: "Accessories",
      subcategory: "Hats",
      tags: "CryptoBlades, CB, hat, beanie, cap, winter"
    },
    {
      item_description: 'Cryptoblades Denim T-Shirt "Sword" Embroidery',
      category: "Apparel",
      subcategory: "Tops",
      tags: "CryptoBlades, CB, shirt, Tshirt, T-shirt"
    },
    {
      item_description: 'Cryptoblades Denim T-Shirt "C" Embroidery',
      category: "Apparel",
      subcategory: "Tops",
      tags: "CryptoBlades, CB, shirt, Tshirt, T-shirt"
    },
    {
      item_description: "Cryptoblades V-Neck T-Shirt Double Logo White",
      category: "Apparel",
      subcategory: "Tops",
      tags: "CryptoBlades, CB, shirt, Tshirt, T-shirt, V, neck, V-neck"
    },
    {
      item_description: "Cryptoblades V-Neck T-Shirt Double Logo Black",
      category: "Apparel",
      subcategory: "Tops",
      tags: "CryptoBlades, CB, shirt, Tshirt, T-shirt, V, neck, V-neck"
    },
    {
      item_description: "Cryptoblades V-Neck T-Shirt Logo in Color",
      category: "Apparel",
      subcategory: "Tops",
      tags: "CryptoBlades, CB, shirt, Tshirt, T-shirt, V, neck, V-neck"
    },
    {
      item_description: 'Cryptoblades V-Neck T-Shirt Logo "C" White',
      category: "Apparel",
      subcategory: "Tops",
      tags: "CryptoBlades, CB, shirt, Tshirt, T-shirt, V, neck, V-neck"
    },
    {
      item_description: 'Cryptoblades V-Neck T-Shirt Logo "C" White',
      category: "Apparel",
      subcategory: "Tops",
      tags: "CryptoBlades, CB, shirt, Tshirt, T-shirt, V, neck, V-neck"
    },
    {
      item_description: 'Cryptoblades V-Neck T-Shirt Logo "C" Black',
      category: "Apparel",
      subcategory: "Tops",
      tags: "CryptoBlades, CB, shirt, Tshirt, T-shirt, V, neck, V-neck"
    },
    {
      item_description: "Cryptoblades V-Neck T-Shirt Logo Blade White",
      category: "Apparel",
      subcategory: "Tops",
      tags: "CryptoBlades, CB, shirt, Tshirt, T-shirt, V, neck, V-neck"
    },
    {
      item_description: "Cryptoblades V-Neck T-Shirt Logo Blade Black",
      category: "Apparel",
      subcategory: "Tops",
      tags: "CryptoBlades, CB, shirt, Tshirt, T-shirt, V, neck, V-neck"
    },
    {
      item_description: "Cryptoblades Double Logo Color T-Shirt Light",
      category: "Apparel",
      subcategory: "Tops",
      tags: "CryptoBlades, CB, shirt, Tshirt, T-shirt"
    },
    {
      item_description: "Cryptoblades Double Logo Color T-Shirt Dark",
      category: "Apparel",
      subcategory: "Tops",
      tags: "CryptoBlades, CB, shirt, Tshirt, T-shirt"
    },
    {
      item_description: "Cryptoblades Logo Color T-Shirt",
      category: "Apparel",
      subcategory: "Tops",
      tags: "CryptoBlades, CB, shirt, Tshirt, T-shirt"
    },
    {
      item_description: 'Cryptoblades Logo T-Shirt "C" Light',
      category: "Apparel",
      subcategory: "Tops",
      tags: "CryptoBlades, CB, shirt, Tshirt, T-shirt"
    },
    {
      item_description: 'Cryptoblades Logo T-Shirt "C" Dark',
      category: "Apparel",
      subcategory: "Tops",
      tags: "CryptoBlades, CB, shirt, Tshirt, T-shirt"
    },
    {
      item_description: "Cryptoblades Logo T-Shirt Light",
      category: "Apparel",
      subcategory: "Tops",
      tags: "CryptoBlades, CB, shirt, Tshirt, T-shirt"
    },
    {
      item_description: "Cryptoblades Logo T-Shirt",
      category: "Apparel",
      subcategory: "Tops",
      tags: "CryptoBlades, CB, shirt, Tshirt, T-shirt"
    },
    {
      item_description: "Short-Sleeve CryptoBlades Unisex T-Shirt",
      category: "Apparel",
      subcategory: "Tops",
      tags: "CryptoBlades, CB, shirt, Tshirt, T-shirt"
    }
  ];
}
