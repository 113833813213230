






























































































































































import Vue from "vue";
import { PropType } from "vue/types/options";
import { CartEntry, FileType } from "@/interface/merchandise.interface";
import { mapActions, mapMutations, mapState } from "vuex";
import { fromWeiEther, toBN } from "@/utils/common";
import CurrencyConverter from "@/components/CurrencyConverter.vue";

interface StoreMappedMutations {
  removeCartEntry(cartEntry: CartEntry): void;
  setMerchandisePage(page: number): void;
}

interface StoreMappedActions {
  currentSkillPrice(): Promise<string>;
}

interface Data {
  totalPrice: number;
  totalPriceInSkill: number;
  skillPrice: number;
}

export default Vue.extend({
  data() {
    return {
      totalPrice: 0,
      totalPriceInSkill: 0,
      skillPrice: 0
    } as Data;
  },

  props: {
    cartEntries: {
      type: Array as PropType<CartEntry[]>,
      required: true
    },
    showFiatPrices: {
      type: Boolean
    }
  },

  components: { CurrencyConverter },

  computed: {
    ...mapState(["skillBalance"])
  },

  methods: {
    ...(mapActions(["currentSkillPrice"]) as StoreMappedActions),
    ...(mapMutations([
      "removeCartEntry",
      "setMerchandisePage"
    ]) as StoreMappedMutations),
    fromWeiEther,
    toBN,
    async shippingInfoPage() {
      this.setMerchandisePage(2);
      this.$root.$emit("merchandise-address", this.totalPriceInSkill);
    },
    async openCartModal() {
      this.skillPrice = +(await this.currentSkillPrice());
      this.calculateTotalPrice();
    },
    isFileTypePreview(file: File) {
      return file.type === FileType.PREVIEW;
    },
    subtractQuantity(cartEntry: CartEntry) {
      cartEntry.quantity--;
      this.calculateTotalPrice();
    },
    addQuantity(cartEntry: CartEntry) {
      cartEntry.quantity++;
      this.calculateTotalPrice();
    },
    calculateTotalPrice() {
      this.totalPrice = this.cartEntries
        .map(cartEntry => +cartEntry.variant.retail_price * cartEntry.quantity)
        .reduce((a, b) => a + b, 0);
      this.totalPriceInSkill = this.totalPrice * this.skillPrice;
    },
    usdToSkill(cartEntry: CartEntry) {
      if (!cartEntry?.variant) return;
      return +cartEntry.variant.retail_price * this.skillPrice;
    },
    cartItemTotalUsdToSkill(cartEntry: CartEntry) {
      if (!cartEntry?.variant) return;
      return (
        +cartEntry.variant.retail_price * cartEntry.quantity * this.skillPrice
      );
    },
    deleteCartEntry(cartEntry: CartEntry) {
      this.removeCartEntry(cartEntry);
      this.calculateTotalPrice();
    },
    isMinusButtonDisabled(cartEntry: CartEntry) {
      return cartEntry.quantity <= 1;
    },
    isContinueToCheckoutButtonDisabled() {
      return this.cartEntries.length === 0;
    },
    toggleShowFiatPrices() {
      this.$root.$emit("toggle-fiat-prices");
    },
    canAffordMerch() {
      const cost = toBN(this.totalPriceInSkill);
      const balance = toBN(this.skillBalance);
      return balance.isGreaterThanOrEqualTo(cost);
    }
  },
  async created() {
    this.skillPrice = +(await this.currentSkillPrice());
    this.calculateTotalPrice();
  }
});
