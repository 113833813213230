
import { abi as erc20Abi } from '../build/contracts/IERC20.json';
import { abi as cryptoBladesAbi, networks as cryptoBladesNetworks } from '../build/contracts/CryptoBlades.json';
import { abi as marketAbi, networks as marketNetworks } from '../build/contracts/NFTMarket.json';
import { abi as partnerGiveawaysAbi, networks as partnerGiveawaysNetworks } from '../build/contracts/PartnerGiveaways.json';
import { abi as weaponsAbi } from '../build/contracts/Weapons.json';
import { abi as charactersAbi } from '../build/contracts/Characters.json';
import { abi as blacksmithAbi } from '../build/contracts/Blacksmith.json';
import { abi as shieldsAbi } from '../build/contracts/Shields.json';
import { abi as junkAbi } from '../build/contracts/Junk.json';
import { abi as raidAbi, networks as raidNetworks } from '../build/contracts/Raid1.json';
import { abi as keyboxAbi } from '../build/contracts/KeyLootbox.json';
import { abi as raidTrinketAbi } from '../build/contracts/RaidTrinket.json';
import { networks as skillTokenNetworks } from '../build/contracts/SkillToken.json';
import { abi as characterRenameTagConsumablesAbi } from '../build/contracts/CharacterRenameTagConsumables.json';
import { abi as weaponRenameTagConsumablesAbi } from '../build/contracts/WeaponRenameTagConsumables.json';
import { abi as characterEarthTraitChangeConsumablesAbi } from '../build/contracts/CharacterEarthTraitChangeConsumables.json';
import { abi as characterFireTraitChangeConsumablesAbi } from '../build/contracts/CharacterFireTraitChangeConsumables.json';
import { abi as characterWaterTraitChangeConsumablesAbi } from '../build/contracts/CharacterWaterTraitChangeConsumables.json';
import { abi as characterLightningTraitChangeConsumablesAbi } from '../build/contracts/CharacterLightningTraitChangeConsumables.json';
import { abi as weaponCosmeticsAbi } from '../build/contracts/WeaponCosmetics.json';
import { abi as characterCosmeticsAbi } from '../build/contracts/CharacterCosmetics.json';
import { abi as cbkLandAbi } from '../build/contracts/CBKLand.json';
import { abi as cbkLandSaleAbi } from '../build/contracts/CBKLandSale.json';
import { abi as specialWeaponsManagerAbi } from '../build/contracts/SpecialWeaponsManager.json';
import { abi as simpleQuestsAbi, networks as simpleQuestsNetworks } from '../build/contracts/SimpleQuests.json';
import { abi as merchandiseAbi, networks as merchandiseNetworks } from '../build/contracts/Merchandise.json';
import multicallAbi from '@/data/MultiCall.json';

import Web3 from 'web3';
import { Contracts } from './interface/contract.interface';
import config from '../app-config.json';

const networkId = process.env.VUE_APP_NETWORK_ID || '5777';

type Networks = Partial<Record<string, { address: string }>>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Abi = any[];

interface MarketContracts {
  NFTMarket?: Contracts['NFTMarket'];
}

interface QuestsContracts {
  SimpleQuests?: Contracts['SimpleQuests'];
}

interface RaidContracts {
  Raid1?: Contracts['Raid1'];
}

interface Config {
  environments: Record<string, Chain>;
}

interface Chain {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  chains: Record<string, Record<string, any>>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getConfigValue(key: string): any {
  if (process.env.VUE_APP_STAGE === 'alpha') {
    return process.env[key];
  }
  const env = window.location.href.startsWith('https://testmarketplace') ? 'test' : 'production';
  const chain = localStorage.getItem('currentChain') || 'BNB';
  return (config as Config).environments[env].chains[chain][key];
}


export async function setUpContracts(web3: Web3): Promise<Contracts> {

  const skillTokenAddress = getConfigValue('VUE_APP_SKILL_TOKEN_CONTRACT_ADDRESS') || (skillTokenNetworks as Networks)[networkId]?.address;
  const SkillToken = new web3.eth.Contract(erc20Abi as Abi, skillTokenAddress);
  const cryptoBladesContractAddr = getConfigValue('VUE_APP_CRYPTOBLADES_CONTRACT_ADDRESS') || (cryptoBladesNetworks as Networks)[networkId]?.address;
  const CryptoBlades = new web3.eth.Contract(cryptoBladesAbi as Abi, cryptoBladesContractAddr);
  const [charactersAddr, weaponsAddr, blacksmithAddr] = await Promise.all([
    CryptoBlades.methods.characters().call(),
    CryptoBlades.methods.weapons().call(),
    CryptoBlades.methods.blacksmith().call(),
  ]);
  const Characters = new web3.eth.Contract(charactersAbi as Abi, charactersAddr);
  const Weapons = new web3.eth.Contract(weaponsAbi as Abi, weaponsAddr);
  const Blacksmith = new web3.eth.Contract(blacksmithAbi as Abi, blacksmithAddr);

  //Special Weapons
  let SpecialWeaponsManager;
  let specialWeaponsManagerAddr;
  try {
    specialWeaponsManagerAddr = await CryptoBlades.methods.specialWeaponsManager().call();
  }
  catch(e) {
    console.error(e);
  }

  if(specialWeaponsManagerAddr) {
    SpecialWeaponsManager = new web3.eth.Contract(specialWeaponsManagerAbi as Abi, specialWeaponsManagerAddr);
  }


  //Character Rename Tag
  const characterRenameTagConsumablesIndex = await Blacksmith.methods.ITEM_CHARACTER_RENAME().call();
  const characterRenameTagConsumablesAddr = await Blacksmith.methods.getAddressOfItem(characterRenameTagConsumablesIndex).call();
  const CharacterRenameTagConsumables = new web3.eth.Contract(characterRenameTagConsumablesAbi as Abi, characterRenameTagConsumablesAddr);

  //Weapon Rename Tag
  const weaponRenameTagConsumablesIndex = await Blacksmith.methods.ITEM_WEAPON_RENAME().call();
  const weaponRenameTagConsumablesAddr = await Blacksmith.methods.getAddressOfItem(weaponRenameTagConsumablesIndex).call();
  const WeaponRenameTagConsumables = new web3.eth.Contract(weaponRenameTagConsumablesAbi as Abi, weaponRenameTagConsumablesAddr);

  //Character Earth Trait Change
  const CharacterEarthTraitChangeConsumablesIndex = await Blacksmith.methods.ITEM_CHARACTER_TRAITCHANGE_EARTH().call();
  const characterEarthTraitChangeConsumablesAddr = await Blacksmith.methods.getAddressOfItem(CharacterEarthTraitChangeConsumablesIndex).call();
  const CharacterEarthTraitChangeConsumables = new web3.eth.Contract(characterEarthTraitChangeConsumablesAbi as Abi, characterEarthTraitChangeConsumablesAddr);

  //Character Fire Trait Change
  const CharacterFireTraitChangeConsumablesIndex = await Blacksmith.methods.ITEM_CHARACTER_TRAITCHANGE_FIRE().call();
  const characterFireTraitChangeConsumablesAddr = await Blacksmith.methods.getAddressOfItem(CharacterFireTraitChangeConsumablesIndex).call();
  const CharacterFireTraitChangeConsumables = new web3.eth.Contract(characterFireTraitChangeConsumablesAbi as Abi, characterFireTraitChangeConsumablesAddr);

  //Character Water Trait Change
  const CharacterWaterTraitChangeConsumablesIndex = await Blacksmith.methods.ITEM_CHARACTER_TRAITCHANGE_WATER().call();
  const characterWaterTraitChangeConsumablesAddr = await Blacksmith.methods.getAddressOfItem(CharacterWaterTraitChangeConsumablesIndex).call();
  const CharacterWaterTraitChangeConsumables = new web3.eth.Contract(characterWaterTraitChangeConsumablesAbi as Abi, characterWaterTraitChangeConsumablesAddr);

  //Chacater Lightning Trait Change
  const CharacterLightningTraitChangeConsumablesIndex = await Blacksmith.methods.ITEM_CHARACTER_TRAITCHANGE_LIGHTNING().call();
  const characterLightningTraitChangeConsumablesAddr = await Blacksmith.methods.getAddressOfItem(CharacterLightningTraitChangeConsumablesIndex).call();
  const CharacterLightningTraitChangeConsumables = new web3.eth.Contract(characterLightningTraitChangeConsumablesAbi as Abi,
    characterLightningTraitChangeConsumablesAddr);
    
  //Weapon Cosmetics
  const cosmeticsWeaponIndex = await Blacksmith.methods.ITEM_COSMETIC_WEAPON().call();
  const cosmeticsWeaponAddr = await Blacksmith.methods.getAddressOfItem(cosmeticsWeaponIndex).call();
  const WeaponCosmetics = new web3.eth.Contract(weaponCosmeticsAbi as Abi, cosmeticsWeaponAddr);

  //Character Cosmetics
  const cosmeticsCharacterIndex = await Blacksmith.methods.ITEM_COSMETIC_CHARACTER().call();
  const cosmeticsCharacterAddr = await Blacksmith.methods.getAddressOfItem(cosmeticsCharacterIndex).call();
  const CharacterCosmetics = new web3.eth.Contract(characterCosmeticsAbi as Abi, cosmeticsCharacterAddr);

  const shieldsAddr = await Blacksmith.methods.shields().call();
  const Shields = new web3.eth.Contract(shieldsAbi as Abi, shieldsAddr);

  const marketContracts: MarketContracts = {};
  const marketContractAddr = getConfigValue('VUE_APP_MARKET_CONTRACT_ADDRESS') || (marketNetworks as Networks)[networkId]?.address;
  marketContracts.NFTMarket = new web3.eth.Contract(marketAbi as Abi, marketContractAddr);

  const cbkLandSaleAddr = await Blacksmith.methods.cbkLandSale().call();
  const CBKLandSale = new web3.eth.Contract(cbkLandSaleAbi as Abi, cbkLandSaleAddr);

  const cbkLandAddr = await CBKLandSale.methods.cbkLand().call();
  const CBKLand = new web3.eth.Contract(cbkLandAbi as Abi, cbkLandAddr);

  const questsContracts: QuestsContracts = {};
  const simpleQuestsContractAddr = getConfigValue('VUE_APP_SIMPLE_QUESTS_CONTRACT_ADDRESS') || (simpleQuestsNetworks as Networks)[networkId]?.address;

  const simpleQuests = new web3.eth.Contract(simpleQuestsAbi as Abi, simpleQuestsContractAddr);
  questsContracts.SimpleQuests = simpleQuests;

  const multicallAddr = getConfigValue('VUE_APP_MULTICALL_CONTRACT_ADDRESS');
  const MultiCall = new web3.eth.Contract(multicallAbi as Abi, multicallAddr);

  const partnerGiveawaysContractAddr = getConfigValue('VUE_APP_PARTNER_GIVEAWAYS_CONTRACT_ADDRESS') || (partnerGiveawaysNetworks as Networks)[networkId]?.address;
  const PartnerGiveaways = new web3.eth.Contract(partnerGiveawaysAbi as Abi, partnerGiveawaysContractAddr);

  const raidContracts: RaidContracts = {};
  let raidTrinketAddress = '';
  let keyboxAddress = '';
  let junkAddress = '';
  
  const raidContractAddr = getConfigValue('VUE_APP_RAID_CONTRACT_ADDRESS') || (raidNetworks as Networks)[networkId]?.address;

  const Raid1 = new web3.eth.Contract(raidAbi as Abi, raidContractAddr);
  raidContracts.Raid1 = Raid1;

  // for the time being, junk, keylootbox and trinket are dependent on raids for the address
  const RAID_LINK_TRINKET = await Raid1.methods.LINK_TRINKET().call();
  const RAID_LINK_KEYBOX = await Raid1.methods.LINK_KEYBOX().call();
  const RAID_LINK_JUNK = await Raid1.methods.LINK_JUNK().call();
  raidTrinketAddress = await Raid1.methods.getLinkAddress(RAID_LINK_TRINKET).call();
  keyboxAddress = await Raid1.methods.getLinkAddress(RAID_LINK_KEYBOX).call();
  junkAddress = await Raid1.methods.getLinkAddress(RAID_LINK_JUNK).call();

  const RaidTrinket = new web3.eth.Contract(raidTrinketAbi as Abi, raidTrinketAddress);
  const KeyLootbox = new web3.eth.Contract(keyboxAbi as Abi, keyboxAddress);
  const Junk = new web3.eth.Contract(junkAbi as Abi, junkAddress);

  const merchandiseAddr = getConfigValue('VUE_APP_MERCHANDISE_CONTRACT_ADDRESS') || (merchandiseNetworks as Networks)[networkId]!.address;
  const Merchandise = new web3.eth.Contract(merchandiseAbi as Abi, merchandiseAddr);

  return {
    ...marketContracts, CryptoBlades, Blacksmith, CBKLandSale, CharacterRenameTagConsumables, CharacterWaterTraitChangeConsumables,
    WeaponRenameTagConsumables, CharacterEarthTraitChangeConsumables,CharacterFireTraitChangeConsumables, CharacterLightningTraitChangeConsumables,
    Weapons, Characters, Shields, WeaponCosmetics, CharacterCosmetics,CBKLand,
    SkillToken, MultiCall, PartnerGiveaways, 
    RaidTrinket, KeyLootbox, Junk, Merchandise, SpecialWeaponsManager,
    ...questsContracts,
    ...raidContracts
  };
}

export const INTERFACE_ID_TRANSFER_COOLDOWNABLE = '0xe62e6974';
