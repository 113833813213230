




import Vue from 'vue';

export default Vue.extend({
  props: {
    modalName: {
      type: String,
      required: true
    }
  },
  methods: {
    closeModal() {
      this.$root.$emit(this.modalName);
    },
  }
});

