































import Vue from 'vue';
import Filters from './Filters.vue';
import detectEthereumProvider from "@metamask/detect-provider";
import {store} from "@/store";
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'MarketSidebarFilter',
  components:{'filters':Filters},
  computed:{ 
    ...mapGetters(['getNftConfigs']),
  },
  methods:{
    logOut: async () => {
      try {
        const provider = await detectEthereumProvider() as any;
        if (provider) {
          const accounts = await provider.request({
            method: 'eth_requestAccounts',
            params: [
              {
                eth_accounts: {}
              }
            ] });
          store.commit('setDefaultAccount', []);
          store.commit('setMetamaskConnected', false);
        }
      } catch (err) {
        console.error('Logout Failure:', err);
      }
    },
  }
});

