


























































































































import Vue from 'vue';
import SkillItem from './SkillItem.vue';
import { nftList, shieldNFT, specialOffersNftList } from '@/default/nft-shop-list.default';
import { Land, LandPrice, Reservation, SkillShopListing } from '@/interface/shop.interface';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { fromWeiEther } from '@/utils/common';
import { BModal } from 'bootstrap-vue';
import CloseModalButton from "@/components/modal/CloseModalButton.vue";
import showMapIcon from '@/assets/show-map-icon.svg';
import _ from 'lodash';
import { getConfigValue } from '@/contracts';

export default Vue.extend({
  components: { 
    SkillItem,
    CloseModalButton
  },
  name: 'SkillList',
  data() {
    let skillList: Array<SkillShopListing> = [];
    return {
      selectedReservation: undefined as Reservation | undefined,
      selectedTier: 0,
      showMapIcon,
      skillList: skillList,
      zonesPopulation: [],
      chunksPopulation: [],
      chunksIds: Array.from({length: 100}, (x, i) => i) as number[],
      zonesIds: Array.from({length: 100}, (x, i) => i),
      maxZonePopulation: 10000,
      selectedZone: undefined as any,
      reservedChunks: [] as string[],
      selectedChunk: undefined as any,
      selectedChunkPopulation: 0 as number,
      selectedChunkAvailable: false as boolean,
      maxChunkPopulation: 100 as number,
      reservations: [] as Reservation[],
      purchase: undefined as Land | undefined,
      canPurchaseLand: undefined as boolean | undefined,
      selectedCurrency: 1 as number,
      takenT3Chunks: [] as number[],
      checkPlayerReservedLandInterval: null as ReturnType<typeof setInterval> | null,
    };
  },
  async mounted() {
    const isLandSaleAllowed = await this.fetchIsLandSaleAllowed();
    const isReservationAllowed = await this.reservedSalesAllowed();

    if(this.isChainValidForShield()){
      this.skillList.push(
        ...shieldNFT()
      );
    }
  
    if(isLandSaleAllowed && this.isChainValidForLand()) {
      const t1LandPrice = fromWeiEther(await this.getCBKLandPrice({tier: 1}));
      const t2LandPrice = fromWeiEther(await this.getCBKLandPrice({tier: 2}));
      const t3LandPrice = fromWeiEther(await this.getCBKLandPrice({tier: 3}));

      this.skillList.push(
        ...specialOffersNftList(isLandSaleAllowed, isReservationAllowed, {
          Tier1: +t1LandPrice,
          Tier2: +t2LandPrice,
          Tier3: +t3LandPrice
        } as LandPrice)
      );
    }
    
    const snapshotQuery = this.$route.query;
    
    if(snapshotQuery.category){
      this.setSkillShopFilter({
        skillShopFilter: snapshotQuery.category.toString().split(',')
      });
    }

    this.skillList.push(
      ...nftList()
    );
    this.$root.$on('map-modal', () => {
      this.closeModal("map-modal");
    });
    this.$root.$on('zone-modal', () => {
      this.closeModal("zone-modal");
    });
    this.$root.$on('t2-claim-map-modal', () => {
      this.closeModal("t2-claim-map-modal");
    });
    this.$root.$on('t2-claim-zone-modal', () => {
      this.closeModal("t2-claim-zone-modal");
    });
    this.$root.$on('t3-claim-map-modal', () => {
      this.closeModal("t3-claim-map-modal");
    });
    this.$root.$on('t3-zone-modal', () => {
      this.closeModal("t3-zone-modal");
    });
    this.$root.$on('buy-t1', async () => {
      const price = await this.getCBKLandPrice({tier: 1});
      await this.purchaseT1CBKLand({price,  currency: this.selectedCurrency});
    });
    this.$root.$on('buy-t2', () => {
      this.selectedTier = 2;
      this.showMapModal();
    });
    this.$root.$on('buy-t3', () => {
      this.selectedTier = 3;
      this.showMapModal();
    });
    this.$root.$on('claim-t2', async () => {
      this.selectedTier = 2;
      this.showT2MapModal();
    });
    this.$root.$on('claim-t3', async () => {
      this.selectedTier = 2;
      this.showT3MapModal();
    });
    
    await this.fetchReservations();
    this.checkPlayerReservedLandInterval = setInterval(await this.fetchReservations , 3000);

    this.reservedChunks = await this.getReservedChunksIds();
  },
  computed:{
    ...mapGetters(['getGlobalFilter','getSkillShopFilter']),
    skillListFiltered(): Array<SkillShopListing> {
      let items: Array<SkillShopListing> = [];
      if(this.getSkillShopFilter.category.length > 0){
        if(this.getSkillShopFilter.category.includes('weapon')){
          items = items.concat(this.skillList.filter((item: any) => item.type.toLowerCase().includes('weapon')));
        }
        if(this.getSkillShopFilter.category.includes('character')){
          items = items.concat(this.skillList.filter((item: any) => item.type.toLowerCase().includes('character')));
        }
        if(this.getSkillShopFilter.category.includes('shield')){
          items = items.concat(this.skillList.filter((item: any) => item.type.toLowerCase().includes('shield')));
        }
      }else{
        items = this.skillList;
      }

      if(this.$route.matched[0].name !== 'Buy'){
        return items = items.filter(item => item.type !== 'shield');
      }

      return items;

    }
  },
  methods:{
    ...mapActions(['claimPlayerReservedLand','reservedSalesAllowed','getChunksOfReservation','getTakenT3Chunks','getPlayerReservedLand','purchaseT3CBKLand','purchaseT2CBKLand','getPurchase','purchaseT1CBKLand','fetchIsLandSaleAllowed', 'getCBKLandPrice', 'getAllZonesPopulation', 'getChunkPopulation', 'getReservedChunksIds','checkIfChunkAvailable']),
    ...mapMutations(['setSkillShopFilter']),
    isChainValidForLand(){
      const featureFlag = getConfigValue('featureSupport');

      return featureFlag.land;
    },
    isChainValidForShield(){
      const featureFlag = getConfigValue('featureSupport');

      return featureFlag.shield;
    },
    async claimLand(chunkId: number) {
      if (!this.selectedReservation) {
        return;
      }

      await this.claimPlayerReservedLand({
        reservationId: this.selectedReservation.id,
        chunkId,
        tier: this.selectedReservation.tier
      });
      if (this.selectedZone !== undefined) {
        await this.updateChunksPopulation(this.selectedZone);
      }
      (this.$refs['t2-claim-zone-modal'] as BModal).hide();
      (this.$refs['t2-claim-map-modal'] as BModal).hide();
      (this.$refs['t3-claim-zone-modal'] as BModal).hide();
      (this.$refs['t3-claim-map-modal'] as BModal).hide();
    },
    closeModal(modalName: string) {
      const modal = this.$refs[`${modalName}`] as BModal;
      if (modal) {
        modal.hide();
      }
    },
    selectAvailableChunk(chunkId: number, index: number) {
      this.selectedChunk = chunkId;
      this.selectedChunkPopulation = this.chunksPopulation[index];
      this.selectedReservation = this.reservations.find(reservation => reservation.chunks.includes(chunkId));
      this.selectedChunkAvailable = true;
    },
    reservationsIncludeZoneIdForTier(zoneId: number, tier: number) {
      return this.reservations.filter(reservation => reservation.tier === tier).flatMap(reservation => reservation.zones).includes(zoneId);
    },
    clearMapSelections() {
      this.selectedZone = undefined;
      this.selectedTier = 0;
    },
    async showT2MapModal() {
      this.zonesPopulation = await this.getAllZonesPopulation();
      const modal = this.$refs['t2-claim-map-modal'] as BModal;
      if (modal) {
        modal.show();
      }
    },

    async showT3MapModal() {
      this.zonesPopulation = await this.getAllZonesPopulation();
      const modal = this.$refs['t3-claim-map-modal'] as BModal;
      if (modal) {
        modal.show();
      }
    },
    calculateZoneId(chunkId: number) {
      const chunkIdNumbers = this.splitNum(chunkId);
      if(chunkId < 100) {
        return Math.floor(chunkId / 10);
      } else if (chunkId >= 100 && chunkId < 1000) {
        return chunkIdNumbers[1];
      } else {
        return Math.floor(+(chunkIdNumbers[0].toString() + chunkIdNumbers[2].toString()));
      }
    },
    async fetchReservations() {
      const playerReservedLand = await this.getPlayerReservedLand();
      if (playerReservedLand) {
        this.takenT3Chunks = await this.getTakenT3Chunks();
        const t2Reservations: Reservation[] = await Promise.all(playerReservedLand.t2Reservations.map(async (reservationId: number) => {
          return await this.mapToReservation(reservationId, 2);
        }));

        const t3Reservations: Reservation[] = await Promise.all(playerReservedLand.t3Reservations.map(async (reservationId: number) => {
          return await this.mapToReservation(reservationId, 3);
        }));
        this.reservations = [...t2Reservations, ...t3Reservations];
      }
    },
    async mapToReservation(id: number, tier: number) {
      let chunks = await this.getChunksOfReservation({reservationId: id});
      if(tier === 3 ) {
        chunks = _.without(chunks.flat(), ...this.takenT3Chunks);
      }
      const zones = [...new Set(chunks.map((chunkId: number) => this.calculateZoneId(chunkId)))];
      chunks = chunks.map(Number);
      return {id, tier, chunks, zones,} as Reservation;
    },
    async purchaseLand(chunkId: number) {
      const chunkAvailable = await this.checkIfChunkAvailable({tier: this.selectedTier, chunkId});
      if (!chunkAvailable) {
        console.error('Chunk not available');
        return;
      }
      const price = await this.getCBKLandPrice({tier: this.selectedTier, currency: this.selectedCurrency});
      if(this.selectedTier === 2) {
        await this.purchaseT2CBKLand({price, chunkId, currency: this.selectedCurrency}).then(() => {
          if(this.selectedZone !== undefined) {
            this.updateChunksPopulation(this.selectedZone);
          }
          (this.$refs['zone-modal'] as BModal).hide();
          (this.$refs['map-modal'] as BModal).hide();
        });
      }
      if(this.selectedTier === 3) {
        await this.purchaseT3CBKLand({price, chunkId, currency: this.selectedCurrency}).then(() => {
          if(this.selectedZone !== undefined) {
            this.updateChunksPopulation(this.selectedZone);
          }
          (this.$refs['zone-modal'] as BModal).hide();
          (this.$refs['map-modal'] as BModal).hide();
        });
      }
    },
    reservationsIncludeChunkIdForTier(chunkId: number, tier: number) {
      return this.reservations.filter(reservation => reservation.tier === tier).flatMap(reservation => reservation.chunks).includes(chunkId);
    },
    async selectChunk(chunkId: number, index: number) {
      if(this.reservedChunks.includes(chunkId.toString())) {
        this.selectedChunk = undefined;
        return;
      }
      this.selectedChunk = chunkId;
      this.selectedChunkPopulation = this.chunksPopulation[index];
      if(this.selectedTier){
        this.selectedChunkAvailable = await this.checkIfChunkAvailable({tier: this.selectedTier, chunkId});
      }
    },
    async showMapModal() {
      this.zonesPopulation = await this.getAllZonesPopulation();
      const modal = this.$refs['map-modal'] as BModal;
      if (modal) {
        modal.show();
      }
      
    },
    async showT2ZoneModal(zoneId: number) {
      this.selectedZone = zoneId;
      await this.updateChunksPopulation(zoneId);
      const modal = this.$refs['t2-claim-zone-modal'] as BModal;
      if (modal) {
        modal.show();
      }
    },
    async showT3ZoneModal(zoneId: number) {
      this.selectedZone = zoneId;
      await this.updateChunksPopulation(zoneId);
      const modal = this.$refs['t3-claim-zone-modal'] as BModal;
      if (modal) {
        modal.show();
      }
    },
    async showZoneModal(zoneId: number) {
      this.selectedZone = zoneId;
      await this.updateChunksPopulation(zoneId);
      const modal = this.$refs['zone-modal'] as BModal;
      if (modal) {
        modal.show();
      }
    },
    async updateChunksPopulation(zoneId: number) {
      this.calculateChunksIds(zoneId);
      this.chunksPopulation = await this.getChunkPopulation({chunkIds: this.chunksIds});
    },
    calculateChunksIds(zoneId: number) {
      const chunksIds = [] as number[];
      for (let i = 0; i < 10; i++) {
        for (let j = 0; j < 10; j++) {
          let chunkId = 0;
          if(+zoneId < 10) {
            chunkId = i * 100 + j + +zoneId * 10;
          }
          else if (+zoneId >= 10 && +zoneId < 100) {
            const zoneIdNumbers = this.splitNum(+zoneId);
            chunkId = i * 100 + j + zoneIdNumbers[0] * 1000 + zoneIdNumbers[1] * 10;
          }
          chunksIds.push(chunkId);
        }
      }
      this.chunksIds = chunksIds;
    },
    splitNum(num: number) {
      return String(num).split('').map(Number);
    },
  },
  beforeDestroy(){
    if(this.checkPlayerReservedLandInterval){
      clearInterval(this.checkPlayerReservedLandInterval);
    }
  }
});
