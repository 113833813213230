












import Vue from 'vue';
import {BModal} from "bootstrap-vue";

interface Data {
  title: string;
  action: (() => void) | undefined;
}

export default Vue.extend({
  data() {
    return {
      title: "",
      action: undefined,
    } as Data;
  },
  methods: {
    closeModal() {
      (this.$refs['confirm-modal'] as BModal).hide();
    },
    async confirmAction() {
      if (this.action) {
        await this.action();
        this.closeModal();
      }
    }
  },
  mounted() {
    this.$root.$on('confirm-modal', (title: string, action: () => void) => {
      const modal = this.$refs['confirm-modal'] as BModal;
      if (modal) {
        this.title = title;
        this.action = action;
        modal.show();
      }
    });
  }
});

