import {getElementNumberToName} from "@/default/element.default";
import {IShield} from "@/interface/shield.interface";
import {
  getWeaponTraitFromProperties,
  getStat1Trait,
  getStat2Trait,
  getStat3Trait,
  getStatPatternFromProperties,
  statNumberToName
} from "./contract";
import {Nft} from "@/interface/nft.interface";

export function shieldFromContract(id: string | number, data: string[]): IShield {
  const properties = data[0];
  const stat1 = data[1];
  const stat2 = data[2];
  const stat3 = data[3];

  const stat1Value = +stat1;
  const stat2Value = +stat2;
  const stat3Value = +stat3;

  const statPattern = getStatPatternFromProperties(+properties);
  const stat1Type = getStat1Trait(statPattern);
  const stat2Type = getStat2Trait(statPattern);
  const stat3Type = getStat3Trait(statPattern);

  const traitNum = getWeaponTraitFromProperties(+properties);

  const shieldStars = (+properties) & 0x7;
  return {
    price: 0,
    sellerAddress: "",
    shieldStars,
    id: +id,
    shieldId: id,
    shieldElement: getElementNumberToName(traitNum),
    stat1Value,
    stat1Element: statNumberToName(stat1Type),
    stat2Value,
    stat2Element: statNumberToName(stat2Type),
    stat3Value,
    stat3Element: statNumberToName(stat3Type)
  };
}

export interface IShieldApi {
  shieldId: string;
  shieldStars: number;
  shieldElement: string;
  stat1Element: string;
  stat1Value: number;
  stat2Element: string;
  stat2Value: number;
  stat3Element: string;
  stat3Value: number;
  price: number;
  timestamp: number;
  sellerAddress: string;
  network: string;
}

export function shieldFromAPI(shieldApi: IShieldApi): Nft {
  const id = +shieldApi.shieldId;
  const stars = shieldApi.shieldStars;
  const element = shieldApi.shieldElement;
  const stat1 = shieldApi.stat1Element;
  const stat1Value = shieldApi.stat1Value;
  const stat2 = shieldApi.stat2Element;
  const stat2Value = shieldApi.stat2Value;
  const stat3 = shieldApi.stat3Element;
  const stat3Value = shieldApi.stat3Value;
  const price = shieldApi.price;
  const sellerAddress = shieldApi.sellerAddress;

  return {
    id,
    stars,
    element,
    stat1,
    stat2,
    stat3,
    stat1Value,
    stat2Value,
    stat3Value,
    price,
    sellerAddress,
  } as Nft;
}
