
































import Vue from 'vue';
import SellSideBar from '../components/sell/SellSideBar.vue';
import SellInventory from "@/components/sell/SellInventory.vue";
import SellMyListings from "@/components/sell/SellMyListings.vue";
import SellTransactionHistory from "@/components/sell/SellTransactionHistory.vue";
import {mapGetters} from "vuex";

export interface Tab {
  name: string;
  component: typeof SellInventory | typeof SellMyListings | typeof SellTransactionHistory;
}

export const tabs: Tab[] = [
  {
    name: 'Inventory',
    component: SellInventory,
  },
  {
    name: 'MyListings',
    component: SellMyListings,
  },
  {
    name: 'Transaction History',
    component: SellTransactionHistory,
  },
];

export default Vue.extend({
  data() {
    return {
      currentTab: tabs[0],
      filterIsToggled: false,
      isLoading: false,
    };
  },
  computed:{
    ...mapGetters(['getProjects']),
  },
  components: {
    SellSideBar,
  },
  mounted() {
    this.$root.$on('filter-value', (data: boolean) => {
      this.filterIsToggled = data;
    });
  },
  methods: {
    handleOnSelect(tab: Tab) {
      this.currentTab = tab;
    },
  },
});
