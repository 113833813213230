import {IMarketFilter, IMarketMerchandiseFilter} from '@/interface/filters.interface';
import {IWeb3Instance} from '@/interface/web3instance.interface';
import Vue from 'vue';
import Vuex from 'vuex';
import Web3 from 'web3';
import createKeccakHash from 'keccak';
import {market_blockchain as useBlockchain, stakeOnly as featureFlagStakeOnly,} from './../utils/feature-flags';

import {Contract, Contracts} from '../interface/contract.interface';
import {getConfigValue, setUpContracts} from '../contracts';

import {marketFilterToQueryDict, marketSortToQueryDict, objToQueryParams} from '../utils/route.utils';
import {Dictionary} from "vue-router/types/router";
import {filterToApiFields} from '@/utils/filter.utils';
import {getCharacterNameFromSeed} from '@/utils/character-name';
import {mergeQueryParams} from '@/utils/query-params';
import {weaponFromContract} from '@/utils/weapon.utils';
import {IWeapon} from '@/interface/weapon.interface';
import {characterFromContract} from '@/utils/character.utils';
import {ICharacter} from '@/interface/character.interface';
import {apiUrl, convertSkillToWei, currentChainSupportsMarket, fromWeiEther, getChainName} from '@/utils/common';
import {shieldFromContract} from "@/utils/shield.utils";
import {truncateDecimals} from '@/utils/currency-converter';
import {getWeaponNameFromSeed} from '../weapon-names';
import {DEFAULT_TARGET_BUYER, PAGE_SIZE_LIMIT} from '@/default/common.default';
import {getElementNumberValueByName} from '@/default/element.default';

import {CHARACTER_DEFAULT_MAX_LEVEL_FILTER, CHARACTER_DEFAULT_MIN_LEVEL_FILTER} from '@/default/character.default';

import _ from 'lodash';
import router from '@/router';
import {IShield} from '@/interface/shield.interface';
import {IJunk} from '@/interface/junk.interface';
import {IERC721} from '../../build/abi-interfaces';
import {Interface} from '@ethersproject/abi';
import {getNFTCall} from '@/utils/multicall';
import {abi as charactersAbi} from '../../build/contracts/Characters.json';
import {abi as weaponsAbi} from '../../build/contracts/Weapons.json';
import {abi as shieldsAbi} from '../../build/contracts/Shields.json';
import {abi as marketAbi} from '../../build/contracts/NFTMarket.json';
import {abi as erc20Abi} from '../../build/contracts/IERC20.json';
import {abi as junkAbi} from '../../build/contracts/Junk.json';
import {approveFeeDynamic, approveFeeWalletOnly} from '@/contract-call-utils';
import {IGlobalSort} from './../interface/sort.interface';
import genericConfig from '../../data/generic-config.json';
import {propsFromContractByType} from '@/utils/preprocessor.util';
import {nftImageByType} from '../utils/arts-generator.util';
import {NftSellStatusEnum} from './../interface/nft.interface';
import {abi as priceOracleAbi} from '../../build/contracts/IPriceOracle.json';
import {
  Quest,
  Rarity,
  ReputationLevelRequirements,
  RequirementType,
  RewardType
} from '@/interface/simple-quests.interface';
import BigNumber from 'bignumber.js';
import {junkFromContract} from '@/utils/junk.utils';
import {CartEntry, ProcessedProductsData, Product} from '@/interface/merchandise.interface';
import {merchList} from '@/default/merch-list.default';
import config from '../../app-config.json';

let web3Instance : IWeb3Instance;
let web3: Web3;
const ethProvider = (window as any).ethereum;

Vue.use(Vuex);

const defaultCallOptions = (state: IState) => ({ from: state.defaultAccount });

function toChecksumAddress(address: string) {
  address = address.toLowerCase().replace('0x', '');
  const hash = createKeccakHash('keccak256').update(address).digest('hex');
  let ret = '0x';

  for (let i = 0; i < address.length; i++) {
    if (parseInt(hash[i], 16) >= 8) {
      ret += address[i].toUpperCase();
    } else {
      ret += address[i];
    }
  }

  return ret;
}

function getGasPrice() {
  const gasPrice: string = getConfigValue('gasPrice');
  if(gasPrice) {
    return web3.utils.toWei(gasPrice, 'gwei');
  } else return '';
}

export interface IBasePagination {
  currentPage: number;
  pageSize: number;
  totalItems: number;
}

export interface IGlobalFilter {
  type: string;
  subtype?: string;
  minPrice?: number;
  maxPrice?: number;
  sortBy?: string;
  sortDir?: number;
  network: string;
  seller?: string;
}
export interface ITxnHistoryItem{
  _id? : number;
  item : IWeapon | IShield | ICharacter;
}

export interface ISearchFilter{
  type: string;
  isAddress?: boolean;
}

export interface IState {
  contracts: Contracts;
  defaultAccount: string | null;
  currentWalletAddress : string;
  currentSkillBalance: number;
  currentSkillRewards: number;
  currentBNBBalance  : number;
  currentWeapon: string;
  currentCharacter: string;
  chainId: string;
  maxStamina: number;
  ownedCharacterIds: number[];
  ownedWeaponIds: number[];
  ownedJunkIds: number[];
  ownedShieldIds: number[];
  metamaskConnected: boolean;
  characters: Record<number, ICharacter>;
  characterPowers: Record<number, number>;
  globalBuyMarketFilter: IGlobalFilter;
  globalSort: IGlobalSort;
  nfts: Record<string, Record<number, any>>;
  weapons: Record<number, IWeapon>;
  shields: Record<number, IShield>;
  junks: Record<number, IJunk>;
  weaponDurabilities: Record<number, number>;
  nftListFilter: IMarketFilter;
  weaponListFilter: IMarketFilter;
  shieldListFilter: IMarketFilter;
  skillShopFilter: IMarketFilter;
  characterListFilter: IMarketFilter;
  junkListFilter: IMarketFilter;
  merchFilter: IMarketMerchandiseFilter;
  transactionHistoryPagination: IBasePagination;
  weaponListPagination: IBasePagination;
  nftListPagination: Record<string,IBasePagination>;
  shieldAndArmorListPagination: IBasePagination;
  characterListPagination: IBasePagination;
  junkListPagination: IBasePagination;
  merchListPagination: IBasePagination;
  shieldList: Array<IShield>;
  characterRenames: any;
  weaponRenames: Record<number, string>;
  isFetchWeaponListLoading: boolean;
  isLoading: Record<string, boolean>;
  isFetchShieldAndArmorListLoading: boolean;
  isFetchMerchListLoading: boolean;
  isCharacterListLoading: boolean;
  characterStaminas: any;
  txnHistory: Record<number,any>;
  skillRewards: string;
  shieldDurabilities: Record<number, number>;
  totalItems: number;
  minLevel: number;
  maxLevel: number;
  currentChainSupportsMarket: boolean;
  projects: Array<string>;
  nftConfigs: any;
  ownedNftIds: Record<string, number[]>;
  cartEntries: CartEntry[];
  merchandisePage: number;
  specialWeaponArts: string[];
  inGameOnlyFunds: string;
}

export const store = new Vuex.Store<IState>({
  state: {
    inGameOnlyFunds: '0',
    projects: [],
    merchandisePage: 0,
    totalItems: 0,
    shieldDurabilities: {},
    txnHistory: {},
    contracts: null!,
    defaultAccount: '',
    chainId: '',
    currentWalletAddress: '',
    currentBNBBalance : 0.00,
    currentSkillBalance : 0.00,
    currentSkillRewards : 0.00,
    currentWeapon: '',
    currentCharacter: '',
    metamaskConnected: false,
    maxStamina: 0,
    ownedCharacterIds: [],
    ownedWeaponIds: [],
    ownedJunkIds: [],
    ownedShieldIds: [],
    nfts: {},
    weapons: {},
    shields: {},
    junks: {},
    cartEntries: [],
    weaponDurabilities: {},
    characters: {},
    characterPowers: {},
    globalSort: {
      sortBy: '',
      sortDir: 1,
    },
    globalBuyMarketFilter: {
      minPrice: 0,
      maxPrice: Number.MAX_SAFE_INTEGER,
      network: localStorage.getItem('currentChain') || "BNB",
      seller: '',
      type: '',
      subtype: '',
    },
    nftListFilter: {
      elementFilter: [],
      rarityFilter: [],
      maxPrice: Number.MAX_SAFE_INTEGER,
      minPrice: 0
    },
    weaponListFilter: {
      elementFilter: [],
      rarityFilter: [],
      maxPrice: Number.MAX_SAFE_INTEGER,
      minPrice: 0
    },
    shieldListFilter: {
      elementFilter: [],
      rarityFilter: [],
      maxPrice: Number.MAX_SAFE_INTEGER,
      minPrice: 0
    },
    characterListFilter: {
      elementFilter: [],
      minLevel: CHARACTER_DEFAULT_MIN_LEVEL_FILTER,
      maxLevel: CHARACTER_DEFAULT_MAX_LEVEL_FILTER
    },
    skillShopFilter: {
      category: []
    },
    merchFilter: {
      category: [],
      subcategory: []
    },
    junkListFilter: {
      elementFilter: [],
      rarityFilter: [],
      maxPrice: Number.MAX_SAFE_INTEGER,
      minPrice: 0
    },
    shieldList: [],
    nftListPagination: {},
    transactionHistoryPagination:{
      currentPage: 1,
      pageSize: 60,
      totalItems: 0
    },
    weaponListPagination: {
      currentPage: 1,
      pageSize: 60,
      totalItems: 0
    },
    shieldAndArmorListPagination: {
      currentPage: 1,
      pageSize: 60,
      totalItems: 0
    },
    characterListPagination: {
      currentPage: 1,
      pageSize: 60,
      totalItems: 0
    },
    junkListPagination: {
      currentPage: 1,
      pageSize: 60,
      totalItems: 0
    },
    merchListPagination: {
      currentPage: 1,
      pageSize: 60,
      totalItems: 0
    },
    isFetchWeaponListLoading: false,
    isFetchMerchListLoading: false,
    isCharacterListLoading: false,
    isFetchShieldAndArmorListLoading: false,
    isLoading: {},
    characterRenames: {},
    weaponRenames: {},
    characterStaminas: {},
    skillRewards: '0',
    minLevel: 0,
    maxLevel: CHARACTER_DEFAULT_MAX_LEVEL_FILTER,
    currentChainSupportsMarket: false,
    nftConfigs: [],
    ownedNftIds: {},
    specialWeaponArts: [],
  },
  mutations: {
    updateInGameOnlyFunds(state, { inGameOnlyFunds }: Pick<IState, 'inGameOnlyFunds'>) {
      state.inGameOnlyFunds = inGameOnlyFunds;
    },
    setNftConfigs(state, config){
      state.nftConfigs = config;
    },
    setProjects(state, projects){
      state.projects = projects;
    },
    setGlobalSort(state, payload: IGlobalFilter) {
      state.globalSort = {
        ...state.globalSort,
        ...payload
      };
    },
    setMerchandisePage(state: IState, merchandisePage){
      state.merchandisePage = merchandisePage;
    },
    addCartEntry(state: IState, cartEntry: CartEntry) {
      const duplicatedEntry = state.cartEntries.find(entry => entry.variant.id === cartEntry.variant.id);
      if (duplicatedEntry) {
        const entryIndex = state.cartEntries.indexOf(duplicatedEntry);
        state.cartEntries.splice(entryIndex, 1);
      }
      state.cartEntries.push(cartEntry);
    },

    removeCartEntry(state: IState, cartEntry: CartEntry) {
      state.cartEntries.splice(state.cartEntries.indexOf(cartEntry), 1);
    },

    clearCartEntries(state: IState) {
      state.cartEntries = [];
    },

    setSkillShopFilter(state: IState, payload){
      state.skillShopFilter = payload.skillShopFilter;
    },
    setMerchFilter(state: IState, payload){
      state.merchFilter = payload.merchFilter;
    },
    setTotalItems(state: IState, totalItems: number){
      state.totalItems = totalItems;
    },
    setNftList(state: IState, nfts){
      state.nfts = nfts;
    },
    setShieldList(state: IState , shields){
      state.shields = shields;
    },
    setCharacterList(state: IState , characters){
      state.characters = characters;
    },
    setWeaponList(state: IState , weapons){
      state.weapons = weapons;
    },
    updateUserDetails(state: IState, {type, ids}) {
      Vue.set(state.ownedNftIds, type, ids);
    },
    setTxnHistoryList(state: IState, txnHistoryList) {
      state.txnHistory = txnHistoryList;
    },
    updateNftList(state: IState, {type, nfts}){
      Vue.set(state.nfts, type, nfts);
    },
    updateCharacter(state: IState, { characterId, character }) {
      Vue.set(state.characters, characterId, character);
    },
    updateShieldDurability(state: IState, { shieldId, durability }) {
      Vue.set(state.shieldDurabilities, shieldId, durability);
    },
    updateWeaponDurability(state: IState, { weaponId, durability }) {
      Vue.set(state.weaponDurabilities, weaponId, durability);
    },
    clearCharacters(state: IState) {
      state.characters = {};
    },
    clearWeapons(state: IState) {
      state.weapons = {};
    },
    clearNfts(state: IState, {type}) {
      state.nfts[type] = {};
    },
    clearShields(state: IState) {
      state.shields = {};
    },
    clearJunks(state: IState) {
      state.junks = {};
    },
    clearTxnHistoryItems(state: IState) {
      state.txnHistory = {};
    },
    updateWeapon(state: IState, { weaponId, weapon }) {
      Vue.set(state.weapons, weaponId, weapon);
    },
    updateJunk(state: IState, { junkId, junk }) {
      Vue.set(state.junks, junkId, junk);
    },
    updateShield(state: IState, { shieldId, shield }) {
      Vue.set(state.shields, shieldId, shield);
    },
    setShieldListFilter(state, payload) {
      state.shieldListFilter = {
        ...state.shieldListFilter,
        ...payload.filter
      };
    },
    setGlobalFilter(state, payload: IGlobalFilter) {
      state.globalBuyMarketFilter = {
        ...state.globalBuyMarketFilter,
        ...payload
      };
    },
    setNftListFilter(state, payload){
      state.nftListFilter = {
        ...state.nftListFilter,
        ...payload.filter
      };
    },
    setWeaponListFilter(state, payload) {
      state.weaponListFilter = {
        ...state.weaponListFilter,
        ...payload.filter
      };
    },
    setJunkListFilter(state, payload) {
      state.junkListFilter = {
        ...state.junkListFilter,
        ...payload.filter
      };
    },
    setCharacterListFilter(state, payload) {
      state.characterListFilter = payload.filter;
    },
    setCurrentSkillBalance(state, payload) {
      state.currentSkillBalance = payload;
    },
    setCurrentSkillRewards(state, payload) {
      state.currentSkillRewards = payload;
    },
    setLoadingState(state: IState, { type, isLoading }) {
      Vue.set(state.isLoading, type, isLoading);
    },
    setFetchWeaponListLoadingState(state, payload) {
      state.isFetchWeaponListLoading = payload;
    },
    setFetchCharacterListLoadingState(state, payload) {
      state.isCharacterListLoading = payload;
    },
    setFetchShieldListLoadingState(state, payload) {
      state.isFetchShieldAndArmorListLoading = payload;
    },
    setNftListCurrentPage(state, {type, page}) {
      state.nftListPagination[type].currentPage = Math.max(page, 1);
    },
    setCharacterListCurrentPage(state, payload) {
      state.characterListPagination.currentPage = Math.max(payload, 1);
    },
    setShieldAndArmorListCurrentPage(state, payload) {
      state.shieldAndArmorListPagination.currentPage = Math.max(payload, 1);
    },
    setTransactionHistoryListCurrentPage(state, payload) {
      state.transactionHistoryPagination.currentPage = Math.max(payload, 1);
    },
    setWeaponListCurrentPage(state, payload) {
      state.weaponListPagination.currentPage = Math.max(payload, 1);
    },
    setNftListPagination(state: IState, {type, pagination}) {
      state.nftListPagination[type] = {
        ...state.nftListPagination[type],
        pageSize: pagination.pageSize,
        totalItems: pagination.totalItems
      };
    },
    setMerchListCurrentPage(state, payload) {
      state.merchListPagination.currentPage = Math.max(payload, 1);
    },
    setWeaponListPagination(state, payload) {
      state.weaponListPagination = {
        ...state.weaponListPagination,
        pageSize: payload.pageSize,
        totalItems: payload.totalItems
      };
    },
    setTransactionHistoryPagination(state, payload) {
      state.transactionHistoryPagination = {
        ...state.transactionHistoryPagination,
        pageSize: payload.pageSize,
        totalItems: payload.totalItems
      };
    },
    setShieldAndArmorListPagination(state, payload) {
      state.shieldAndArmorListPagination = {
        ...state.shieldAndArmorListPagination,
        pageSize: payload.pageSize,
        totalItems: payload.totalItems
      };
    },
    setCharacterListPagination(state, payload) {
      state.characterListPagination = {
        ...state.characterListPagination,
        pageSize: payload.pageSize,
        totalItems: payload.totalItems,
      };
    },
    setJunkListPagination(state, payload) {
      state.junkListPagination = {
        ...state.junkListPagination,
        pageSize: payload.pageSize,
        totalItems: payload.totalItems
      };
    },
    setMerchListPagination(state, payload) {
      state.merchListPagination = {
        ...state.merchListPagination,
        pageSize: payload.pageSize,
        totalItems: payload.totalItems
      };
    },
    setCurrentBNBBalance(state, payload) {
      state.currentBNBBalance = payload;
    },
    setCurrentWalletAddress (state, payload) {
      state.currentWalletAddress = payload;
    },
    setDefaultAccount (state, payload) {
      state.defaultAccount = payload;
    },
    setChainId (state, payload) {
      state.chainId = payload;
    },
    setMetamaskConnected (state, payload) {
      state.metamaskConnected = payload;
    },
    clearWeaponListFilter(state) {
      state.weaponListFilter = {
        elementFilter: [],
        rarityFilter: [],
        maxPrice: Number.MAX_SAFE_INTEGER,
        minPrice: 0
      };
    },
    clearJunkListFilter(state) {
      state.junkListFilter = {
        elementFilter: [],
        rarityFilter: [],
        maxPrice: Number.MAX_SAFE_INTEGER,
        minPrice: 0
      };
    },
    setContracts(state: IState, payload) {
      state.contracts = payload;
    },
    setCurrentWeapon(state: IState, payload){
      state.currentWeapon = payload;
    },
    setCurrentCharacter(state: IState, payload){
      state.currentCharacter = payload;
    },
    setMinMaxLevel(state: IState, payload){
      state.minLevel = payload.minLevel;
      state.maxLevel = payload.maxLevel;
    },
    updateCurrentChainSupportsMarket(state: IState) {
      state.currentChainSupportsMarket = currentChainSupportsMarket();
    },
    updateSpecialWeaponArt(state: IState, {eventId, art}) {
      Vue.set(state.specialWeaponArts, eventId, art);
    },
  },
  actions: {
    async fetchInGameOnlyFunds({ state, commit }) {
      const { CryptoBlades } = state.contracts;
      if(!CryptoBlades || !state.defaultAccount) return;

      const inGameOnlyFunds = await CryptoBlades.methods
        .inGameOnlyFunds(state.defaultAccount)
        .call(defaultCallOptions(state));

      const payload: Pick<IState, 'inGameOnlyFunds'> = { inGameOnlyFunds };
      commit('updateInGameOnlyFunds', payload);
    },
    async getAccountNftsFromContractByType({ state, dispatch, commit }, { type }) {
      try{
        store.commit('setLoadingState', {type: type, isLoading: true});
        const ownedNftIds = await dispatch('getAccountNftIds', {nftType: type});
        commit('updateNftList', {
          type: type,
          nfts: await dispatch('fetchNftInfo', {
            nftIds: ownedNftIds,
            nftType: type,
          })
        });
        
        Object.values(state.nfts[type]).forEach((nft: any) => {
          nft.sellStatus = NftSellStatusEnum.NotListed;
        });
        commit('setTotalItems', ownedNftIds.length);
        store.commit('setLoadingState', {type: type, isLoading: false});
      }catch (error) {
        store.commit('setLoadingState', {type: type, isLoading: false});
        console.error(error);
      }
    },
    async getAccountNftIds({state, getters}, payload) {
      const { NFTMarket } = state.contracts;
      if(!state.defaultAccount && !NFTMarket) return;
      const nftInfo: any = getters.getNftConfigs[payload.nftType];
      const abi = require(`../../data/abi/nfts/${payload.nftType.split('-')[0]}/${nftInfo.abi.filename}`);
      const env = window.location.href.startsWith('https://testmarketplace') ? 'test' : 'production';
      const chain = localStorage.getItem('currentChain') || 'BNB';
      const contract: any = new web3.eth.Contract(
        abi,
        nftInfo.token.contractAddress[env][
          chain
        ]
      );
      const numberOfNfts = parseInt(await contract?.methods.balanceOf(state.defaultAccount).call(defaultCallOptions(state)) || '', 10);
      const nfts = await Promise.all(
        [...Array(numberOfNfts).keys()].map((_, i) =>
          contract?.methods.tokenOfOwnerByIndex(state.defaultAccount || "", i).call(defaultCallOptions(state)))
      );
      return nfts;
    },
    async initializeProjectTools({ getters, commit, state }) {
      for(const key in getters.getNftConfigs) {
        //route initialization
        router.addRoute('Buy',{
          path: getters.getNftConfigs[key].type,
          name: 'Buy' + getters.getNftConfigs[key].name,
          components: {
            'nft-list': require('@/components/NftList.vue').default
          },
          meta: {
            tabName: getters.getNftConfigs[key].type,
          }
        });
        router.addRoute('Inventory',{
          path: getters.getNftConfigs[key].type,
          name: 'Inventory' + getters.getNftConfigs[key].name,
          component: require('@/components/NftList.vue').default,
          meta: {
            tabName: getters.getNftConfigs[key].type,
          }
        });
        //pagination intialization
        Vue.set(state.nftListPagination, getters.getNftConfigs[key].type, {
          currentPage: 1,
          pageSize: 60,
          totalItems: 0
        });
      }
    },
    async fetchContractFromConfig({ state }, payload) {
      const abi = require(`../../data/abi/nfts/${payload.nftType.split('-')[0]}/${payload.nftInfo.abi.filename}`);
      const env = window.location.href.startsWith('https://testmarketplace') ? 'test' : 'production';
      const chain = localStorage.getItem('currentChain') || 'BNB';
      return new web3.eth.Contract(
        abi,
        payload.nftInfo.token.contractAddress[env][
          chain
        ]
      );
    },
    async fetchNftInfo({ state, getters, dispatch, commit }, payload) {
      const { NFTMarket, Characters } = state.contracts;
      if(!NFTMarket || !Characters) return;
      const nftInfo: any = getters.getNftConfigs[payload.nftType];
      const abi = require(`../../data/abi/nfts/${payload.nftType.split('-')[0]}/${nftInfo.abi.filename}`);
      const contract = await dispatch('fetchContractFromConfig', {nftType: payload.nftType, nftInfo: nftInfo});
      const nftsDetails = await dispatch('multicall', getNFTCall(abi, contract?.options.address, nftInfo.token.method, payload.nftIds.map((id: any) => [id])));
      const nftsPrice = await dispatch('multicall', getNFTCall(marketAbi, NFTMarket?.options.address, 'getFinalPrice', payload.nftIds.map((id: any) => [contract?.options.address,id])));
      const nftsSeller = await dispatch('multicall', getNFTCall(marketAbi, NFTMarket?.options.address, 'getSellerOfNftID', payload.nftIds.map((id: any) => [contract?.options.address, id])));
      const nftsBuyer = await dispatch('multicall', getNFTCall(marketAbi, NFTMarket?.options.address, 'getTargetBuyer', payload.nftIds.map((id: any) => [contract?.options.address, id])));
      const nftsSellerStatus = await dispatch('fetchSellerStatus', nftsSeller.map((seller: boolean[]) => seller[0]));
      let shieldFlagIds: any = [];
      let charactersVersionData: any = [];
      if(payload.nftType === 'cb-shield'){
        shieldFlagIds = await dispatch('fetchShieldsFlagByIds', payload.nftIds);
      } else if (payload.nftType === 'cb-character') {
        const NFTVAR_NON_GENESIS_VERSION = 3;
        charactersVersionData = await dispatch('multicall', getNFTCall(charactersAbi, Characters.options.address, 'getNftVar', payload.nftIds.map((id: any) => [id, NFTVAR_NON_GENESIS_VERSION])));
      }
      payload.nftIds.forEach((nftId: number, i: number) => {
        nftsDetails[i] = propsFromContractByType(
          nftInfo.preprocessor,
          nftId,
          nftsDetails[i]
        );
        const price = +truncateDecimals(fromWeiEther(nftsPrice[i]));
        nftsDetails[i].price = price;
        nftsDetails[i].sellerAddress = nftsSeller[i][0];
        nftsDetails[i].targetBuyer = nftsBuyer[i][0];
        nftsDetails[i].sellerStatus = nftsSellerStatus[i][0];
        if(payload.nftType === 'cb-shield'){
          nftsDetails[i].shieldFlag = Number(shieldFlagIds[i]);
        }
        if(payload.nftType === 'cb-character'){
          nftsDetails[i].version = +charactersVersionData[i];
        }
        if(nftInfo.type === 'cb-weapon' && nftsDetails[i].weaponType > 0){
          nftsDetails[i].image = state.specialWeaponArts[nftsDetails[i].weaponType];
        }else{
          nftsDetails[i].image = nftImageByType(
            nftInfo.type,
            nftsDetails[i]
          );
        }
      });
      return nftsDetails;
    },
    loadProjects({state, getters, commit}){
      commit('setProjects', genericConfig.packages);
    },
    loadProjectConfigs({state, getters, commit, dispatch}){
      const configs: any = {};
      for (const project of state.projects) {
        configs[`${project}`] = require(`../../data/nfts/${project}.json`);
      }
      commit('setNftConfigs', configs);
    },
    async fetchNfts({state, getters, dispatch, commit}, {type, seller}) {
      store.commit('setLoadingState', {type: type, isLoading: true});
      try {
        store.commit('setGlobalFilter', {
          type: type,
          seller: seller,
          network: localStorage.getItem('currentChain') || 'BNB'
        });
        const paginationFilter = `pageNum=${getters.getNftListPagination[type].currentPage}`;
        let filterDict: Dictionary<string | (string | null)[]> = {};
        let filterParams = "";
        const apiFields: any = filterToApiFields(state.nftListFilter, getters.getNftConfigs[type]);
        apiFields.forEach((filter: any, index: number) => {
          filterDict[filter.name] = filter.value;
          filterParams += objToQueryParams(filterDict) + (apiFields.length === index + 1 ? '' : '&');
          filterDict = {};
        });
        const marketFilter = objToQueryParams(state.globalBuyMarketFilter);
        const sorts = objToQueryParams(marketSortToQueryDict(state.globalSort));

        const myListingFilter = objToQueryParams(getters.getMyListingsFilter);

        const queryParams = router.currentRoute.name === 'MyListings' ? mergeQueryParams(myListingFilter) : mergeQueryParams(filterParams, paginationFilter, marketFilter, sorts);
        const response = await fetch(apiUrl(`nfts${queryParams}`));
        const data = await response.json();
        
        commit('setNftListPagination', {
          type: type,
          pagination:{
            pageSize: data.page.pageSize,
            totalItems: data.page.total - 1
          }
        });
        store.commit('clearNfts' , {type: type});
        commit('updateNftList', {
          type: type,
          nfts: await dispatch('fetchNftInfo', {
            nftIds: data.idResults,
            nftType: type,
          })
        });
        commit('setTotalItems', data.page.total);
        store.commit('setLoadingState', {type: type, isLoading: false});
      } catch (error) {
        store.commit('setLoadingState', {type: type, isLoading: false});
      }
    },
    async lookupSellerStatus({ getters, dispatch },id: number) {
      if (!getters.weaponContractAddress) return;

      return await dispatch('fetchMarketNftSellerStatus', {
        nftContractAddr: getters.weaponContractAddress,
        tokenId: id,
      });
    },
    // eslint-disable-next-line no-empty-pattern
    async fetchProcessedProducts({}, {payload}){
      
      const merchs = merchList(); 

      const processedProductsData: ProcessedProductsData[] = [];

      const filteredProductsData: ProcessedProductsData[] = [];

      payload.products.forEach((product: Product) => {
        merchs.forEach((merch) => {
          if (merch.item_description.toLowerCase() === product.name.toLowerCase()) {

            processedProductsData.push({
              id: product.id,
              external_id: product.external_id,
              name: product.name,
              variants: product.variants,
              synced: product.synced,
              thumbnail_url: product.thumbnail_url,
              is_ignored: product.is_ignored,
              category: merch.category,
              subcategory: merch.subcategory,
              tags: merch.tags
            });
          }
        });
      });

      if(payload.filters.subcategory.length > 0 || payload.filters.category.length > 0){

        processedProductsData.forEach((product)=>{
          if(payload.filters.subcategory.includes(product.subcategory) || payload.filters.category.includes(product.category)){
            filteredProductsData.push(product);
          }
        });
        
        const productsPerPage = filteredProductsData.slice(
          (payload.currentPage - 1) * payload.perPage,
          payload.currentPage * payload.perPage
        );

        return productsPerPage;
      }

      const productsPerPage = processedProductsData.slice(
        (payload.currentPage - 1) * payload.perPage,
        payload.currentPage * payload.perPage
      );
      return productsPerPage;

    },
    async fetchSpecialWeaponArts({ state, commit }) {
      const { SpecialWeaponsManager } = state.contracts;
      if(!SpecialWeaponsManager || !state.defaultAccount) return;
      const eventCount = await SpecialWeaponsManager.methods.eventCount().call(defaultCallOptions(state));

      for(let eventId = 1; eventId <= +eventCount; eventId++){
        const art = await SpecialWeaponsManager.methods.specialWeaponArt(eventId).call(defaultCallOptions(state));
        commit('updateSpecialWeaponArt', { eventId, art });
      }
    },
    
    async fetchSkillItemPriceSeries({state}, payload){
      const { Blacksmith } = state.contracts;
      if(!Blacksmith) return;
      const price = await Blacksmith.methods.itemSeriesFlatPrices(payload.itemId, payload.seriesIndex).call(defaultCallOptions(state));
      return price;
    },
    async fetchSkillItemPrice({state}, itemId){
      const { Blacksmith } = state.contracts;
      if(!Blacksmith) return;
      const price = await Blacksmith.methods.itemFlatPrices(itemId).call(defaultCallOptions(state));
      return price;
    },
    async fetchSellerOfNft({ state }, { nftContractAddr, tokenId }: { nftContractAddr: string; tokenId: string }) {
      // getSellerOfNftID
      const { NFTMarket } = state.contracts;
      if(!NFTMarket) return;

      const sellerAddr = await NFTMarket.methods
        .getSellerOfNftID(nftContractAddr, tokenId)
        .call(defaultCallOptions(state));

      return sellerAddr;
    },
    async claimPlayerReservedLand({state}, {reservationId, chunkId, tier}) {
      const {CBKLandSale} = state.contracts;
      if(!CBKLandSale || !state.defaultAccount) return;

      return await CBKLandSale.methods
        .claimPlayerReservedLand(reservationId, chunkId, tier)
        .send({
          from: state.defaultAccount,
          gasPrice: getGasPrice(),
        });
    },
    async getPlayerReservedLand({state}) {
      const {CBKLandSale} = state.contracts;
      if(!CBKLandSale || !state.defaultAccount) return;

      return await CBKLandSale.methods
        .getPlayerReservedLand(state.defaultAccount)
        .call(defaultCallOptions(state));
    },
    async getOwnedLands({state}) {
      const {CBKLand} = state.contracts;
      if(!CBKLand || !state.defaultAccount) return;

      const landsIds = await CBKLand.methods
        .getOwned(state.defaultAccount)
        .call(defaultCallOptions(state));

      return await Promise.all(landsIds.map(landId => CBKLand.methods.get(landId).call(defaultCallOptions(state))));
    },
    async getChunksOfReservation({state}, {reservationId}) {
      const {CBKLandSale} = state.contracts;
      if(!CBKLandSale || !state.defaultAccount) return;

      return await CBKLandSale.methods
        .getChunksOfReservations(reservationId)
        .call(defaultCallOptions(state));
    },
    async getTakenT3Chunks({state}) {
      const {CBKLandSale} = state.contracts;
      if(!CBKLandSale || !state.defaultAccount) return;

      return await CBKLandSale.methods
        .getTakenT3Chunks()
        .call(defaultCallOptions(state));
    },
    async getAvailableLand({state}) {
      const {CBKLandSale} = state.contracts;
      if(!CBKLandSale || !state.defaultAccount) return;

      const res = await CBKLandSale.methods
        .getAvailableLand()
        .call(defaultCallOptions(state));

      const t1Land = res[0];
      const t2Land = res[1];
      const t3Land = res[2];

      return { t1Land, t2Land, t3Land };
    },
    async purchaseT3CBKLand({state}, {price, chunkId, currency}) {
      const { CryptoBlades, Blacksmith, SkillToken } = state.contracts;
      if(!CryptoBlades || !Blacksmith || !SkillToken || !state.defaultAccount) return;

      if(currency === 0) {
        await SkillToken.methods
          .approve(CryptoBlades.options.address, price)
          .send({
            from: state.defaultAccount,
            gasPrice: getGasPrice(),
          });
      } else {
        const tokenAddress = await Blacksmith.methods
          .getCurrency(currency)
          .call(defaultCallOptions(state));

        await new web3.eth.Contract(erc20Abi as any[], tokenAddress).methods
          .approve(Blacksmith.options.address, price)
          .send({
            from: state.defaultAccount,
            gasPrice: getGasPrice(),
          });
      }

      return await Blacksmith.methods
        .purchaseT3CBKLand(price, chunkId, currency).send({
          from: state.defaultAccount,
          gasPrice: getGasPrice(),
        });
    },
    async purchaseT2CBKLand({state}, {price, chunkId, currency}) {
      const { CryptoBlades, Blacksmith, SkillToken } = state.contracts;
      if(!CryptoBlades || !Blacksmith || !SkillToken || !state.defaultAccount) return;

      if(currency === 0) {
        await SkillToken.methods
          .approve(CryptoBlades.options.address, price)
          .send({
            from: state.defaultAccount,
            gasPrice: getGasPrice(),
          });
      } else {
        const tokenAddress = await Blacksmith.methods
          .getCurrency(currency)
          .call(defaultCallOptions(state));

        await new web3.eth.Contract(erc20Abi as any[], tokenAddress).methods
          .approve(Blacksmith.options.address, price)
          .send({
            from: state.defaultAccount,
            gasPrice: getGasPrice(),
          });
      }

      return await Blacksmith.methods
        .purchaseT2CBKLand(price, chunkId, currency).send({
          from: state.defaultAccount,
          gasPrice: getGasPrice(),
        });
    },
    async getPurchase({state}) {
      const {CBKLandSale} = state.contracts;
      if(!CBKLandSale || !state.defaultAccount) return;

      const res = await CBKLandSale.methods
        .getPurchase()
        .call(defaultCallOptions(state));

      const tier = res[0];
      const chunkId = res[1];

      return { tier, chunkId };
    },
    async purchaseT1CBKLand({state}, {price, currency}) {
      const { CryptoBlades, Blacksmith, SkillToken } = state.contracts;
      if(!CryptoBlades || !Blacksmith || !SkillToken || !state.defaultAccount) return;

      if(currency === 0) {
        await SkillToken.methods
          .approve(CryptoBlades.options.address, price)
          .send({
            from: state.defaultAccount,
            gasPrice: getGasPrice(),
          });
      } else {
        const tokenAddress = await Blacksmith.methods
          .getCurrency(currency)
          .call(defaultCallOptions(state));

        await new web3.eth.Contract(erc20Abi as any[], tokenAddress).methods
          .approve(Blacksmith.options.address, price)
          .send({
            from: state.defaultAccount,
            gasPrice: getGasPrice(),
          });
      }
      

      return await Blacksmith.methods
        .purchaseT1CBKLand(price, currency)
        .send({
          from: state.defaultAccount,
          gasPrice: getGasPrice(),
        });
    },
    async checkIfChunkAvailable({state}, {tier, chunkId}) {
      const {CBKLandSale} = state.contracts;
      if(!CBKLandSale) return;

      return await CBKLandSale.methods
        .checkIfChunkAvailable(tier, chunkId)
        .call(defaultCallOptions(state));
    },
    async getReservedChunksIds({state}) {
      const {CBKLandSale} = state.contracts;
      if(!CBKLandSale) return;

      return await CBKLandSale.methods
        .getReservedChunksIds()
        .call(defaultCallOptions(state));
    },
    async getChunkPopulation({state}, {chunkIds}) {
      const {CBKLandSale} = state.contracts;
      if(!CBKLandSale) return;

      return await CBKLandSale.methods
        .getChunkPopulation(chunkIds)
        .call(defaultCallOptions(state));
    },
    async getAllZonesPopulation({state}) {
      const {CBKLandSale} = state.contracts;
      if(!CBKLandSale) return;

      return await CBKLandSale.methods
        .getAllZonesPopulation()
        .call(defaultCallOptions(state));
    },
    async updateNftListingPrice({dispatch}, {selectedNftId, listingSellPrice, contractAddress, type}) {
      if(selectedNftId === null) return;

      const val = Math.min(+listingSellPrice, 10000);
      if(val <= 0 || !val || isNaN(val)) return;

      const results = await dispatch('changeMarketListingPrice', {
        nftContractAddr: contractAddress,
        tokenId: type === 'weapon' || type === 'character' || type === 'shield' || type === 'junk'
          ? selectedNftId
          : selectedNftId.split('.')[1],
        newPrice: convertSkillToWei(val.toString())
      });

      return results;
    },
    async changeMarketListingPrice({ state }, { nftContractAddr, tokenId, newPrice }: { nftContractAddr: string; tokenId: string; newPrice: string }) {
      const { CryptoBlades, SkillToken, NFTMarket } = state.contracts;
      if(!CryptoBlades || !SkillToken || !NFTMarket || !state.defaultAccount) return;

      await approveFeeDynamic(
        CryptoBlades,
        NFTMarket,
        SkillToken,
        state.defaultAccount,
        getGasPrice(),
        state.skillRewards,
        defaultCallOptions(state),
        defaultCallOptions(state),
        (nftMarketFuctions: { changeFee: () => any }) => nftMarketFuctions.changeFee(),
        { allowInGameOnlyFunds: false, allowSkillRewards: false },
      );

      const res = await NFTMarket.methods
        .changeListingPrice(nftContractAddr, tokenId, newPrice)
        .send({
          from: state.defaultAccount,
          gasPrice: getGasPrice(),
        });

      const {
        seller,
        nftID
      } = res.events.ListingPriceChange.returnValues;

      return { seller, nftID, newPrice } as { seller: string; nftID: string; newPrice: string };
    },
    async fetchShieldsFromContracts({state, commit}){
      const [
        ownedShieldIds,
      ] = await Promise.all([
        state.contracts.Shields?.methods.getOwned().call(defaultCallOptions(state)),
      ]);

      commit('updateUserDetails', {
        ownedShieldIds: Array.from(ownedShieldIds || []),
      });
    },
    async fetchCharactersFromContracts({state, commit, dispatch}){
      const [
        ownedCharacterIds,
        maxStamina,
      ] = await Promise.all([
        dispatch('getAccountCharacters'),
        state.contracts.Characters?.methods.maxStamina().call(defaultCallOptions(state)),
      ]);

      commit('updateUserDetails', {
        ownedCharacterIds: Array.from(ownedCharacterIds),
        maxStamina: parseInt(maxStamina || "", 10),
      });
    },
    async getTotalCharacterPower({ state }, characterId) {
      if (!state.defaultAccount) return;

      const characterPower = await state.contracts.Characters?.methods.getTotalPower(characterId).call({
        from: state.defaultAccount
      });

      return characterPower;
    },
    async getBaseCharacterPower({ state }, characterId) {
      if (!state.defaultAccount) return;
      const characterPower = await state.contracts.Characters?.methods.getPower(characterId).call({ 
        from: state.defaultAccount
      });

      return characterPower;
    },
    async fetchWeaponsFromContracts({state, commit, dispatch}){
      const [
        ownedWeaponIds,
        maxDurability,
      ] = await Promise.all([
        await dispatch('getAccountWeapons'),
        await state.contracts.Weapons?.methods.maxDurability().call(defaultCallOptions(state)),
      ]);
      commit('updateUserDetails', {
        ownedWeaponIds: Array.from(ownedWeaponIds),
        maxDurability: parseInt(maxDurability || "", 10),
      });
    },
    txnHistoryListUpdate({ commit, getters }, txnHistoryList) {
      commit('setTxnHistoryList', txnHistoryList);
    },
    async fetchJunkFromContracts({state, commit}){
      const [
        ownedJunkIds,
      ] = await Promise.all([
        state.contracts.Junk?.methods.getOwned().call(defaultCallOptions(state)),
      ]);

      commit('updateUserDetails', {
        ownedJunkIds: Array.from(ownedJunkIds || []),
      });
    },
    weaponListUpdate({ commit }, weaponList){
      commit('setWeaponList', weaponList);
    },
    characterListUpdate({ commit }, characterList){
      commit('setCharacterList', characterList);
    },
    shieldListUpdate({ commit }, shieldList){
      commit('setShieldList', shieldList);
    },
    junkListUpdate({ commit }, junkList){
      commit('setJunkList', junkList);
    },
    async fetchOwnedWeaponCosmetics({ state }, {cosmetic}) {
      const { WeaponCosmetics } = state.contracts;
      if(!WeaponCosmetics || !state.defaultAccount) return;
      return await WeaponCosmetics.methods.getCosmeticCount(cosmetic).call(defaultCallOptions(state));
    },
    async fetchOwnedCharacterCosmetics({ state }, {cosmetic}) {
      const { CharacterCosmetics } = state.contracts;
      if(!CharacterCosmetics || !state.defaultAccount) return;
      return await CharacterCosmetics.methods.getCosmeticCount(cosmetic).call(defaultCallOptions(state));
    },
    async purchaseCharacterCosmetic({ state, dispatch }, {cosmetic, price}) {
      const { CryptoBlades, SkillToken, CharacterCosmetics, Blacksmith } = state.contracts;
      if(!CryptoBlades || !CharacterCosmetics || !Blacksmith || !state.defaultAccount) return;

      try {
        await SkillToken.methods
          .approve(CryptoBlades.options.address, web3.utils.toWei('' + price, 'ether'))
          .send({
            from: state.defaultAccount,
            gasPrice: getGasPrice(),
          });
      } catch(err) {
        console.error(err);
      }

      await Blacksmith.methods.purchaseCharacterCosmetic(cosmetic, Web3.utils.toWei('' + price)).send({
        from: state.defaultAccount,
        gas: '500000',
        gasPrice: getGasPrice(),
      });

      await Promise.all([
        dispatch('getAccountBalance'),
      ]);
    },
    async purchaseWeaponCosmetic({ state, dispatch }, {cosmetic, price}) {
      const { CryptoBlades, SkillToken, WeaponCosmetics, Blacksmith } = state.contracts;
      if(!CryptoBlades || !WeaponCosmetics || !Blacksmith || !state.defaultAccount) return;

      try {
        await SkillToken.methods
          .approve(CryptoBlades.options.address, web3.utils.toWei('' + price, 'ether'))
          .send({
            from: state.defaultAccount,
            gasPrice: getGasPrice(),
          });
      } catch(err) {
        console.error(err);
      }

      await Blacksmith.methods.purchaseWeaponCosmetic(cosmetic, Web3.utils.toWei('' + price)).send({
        from: state.defaultAccount,
        gas: '500000',
        gasPrice: getGasPrice(),
      });

      await Promise.all([
        dispatch('getAccountBalance'),
      ]);
    },
    async fetchTotalCharacterLightningTraitChanges({ state }) {
      const { CharacterLightningTraitChangeConsumables } = state.contracts;
      if(!CharacterLightningTraitChangeConsumables || !state.defaultAccount) return;
      return await CharacterLightningTraitChangeConsumables.methods.getItemCount().call(defaultCallOptions(state));
    },
    async purchaseCharacterLightningTraitChange({ state, dispatch }, {price}) {
      const { CryptoBlades, SkillToken, CharacterLightningTraitChangeConsumables, Blacksmith } = state.contracts;
      if(!CryptoBlades || !CharacterLightningTraitChangeConsumables || !Blacksmith || !state.defaultAccount) return;

      try {
        await SkillToken.methods
          .approve(CryptoBlades.options.address, web3.utils.toWei('' + price, 'ether'))
          .send({
            from: state.defaultAccount,
            gasPrice: getGasPrice(),
          });
      } catch(err) {
        console.error(err);
      }

      await Blacksmith.methods.purchaseCharacterLightningTraitChange(Web3.utils.toWei('' + price)).send({
        from: state.defaultAccount,
        gas: '500000',
        gasPrice: getGasPrice(),
      });

      await Promise.all([
        dispatch('getAccountBalance'),
        dispatch('fetchTotalCharacterLightningTraitChanges')
      ]);
    },
    async fetchTotalCharacterWaterTraitChanges({ state }) {
      const { CharacterWaterTraitChangeConsumables } = state.contracts;
      if(!CharacterWaterTraitChangeConsumables || !state.defaultAccount) return;
      return await CharacterWaterTraitChangeConsumables.methods.getItemCount().call(defaultCallOptions(state));
    },
    async purchaseCharacterWaterTraitChange({ state, dispatch }, {price}) {
      const { CryptoBlades, SkillToken, CharacterWaterTraitChangeConsumables, Blacksmith } = state.contracts;
      if(!CryptoBlades || !CharacterWaterTraitChangeConsumables || !Blacksmith || !state.defaultAccount) return;

      try {
        await SkillToken.methods
          .approve(CryptoBlades.options.address, web3.utils.toWei('' + price, 'ether'))
          .send({
            from: state.defaultAccount,
            gasPrice: getGasPrice(),
          });
      } catch(err) {
        console.error(err);
      }

      await Blacksmith.methods.purchaseCharacterWaterTraitChange(Web3.utils.toWei('' + price)).send({
        from: state.defaultAccount,
        gas: '500000',
        gasPrice: getGasPrice(),
      });

      await Promise.all([
        dispatch('getAccountBalance'),
        dispatch('fetchTotalCharacterWaterTraitChanges')
      ]);
    },
    async fetchTotalCharacterFireTraitChanges({ state }) {
      const { CharacterFireTraitChangeConsumables } = state.contracts;
      if(!CharacterFireTraitChangeConsumables || !state.defaultAccount) return;
      return await CharacterFireTraitChangeConsumables.methods.getItemCount().call(defaultCallOptions(state));
    },
    async purchaseCharacterFireTraitChange({ state, dispatch }, {price}) {
      const { CryptoBlades, SkillToken, CharacterFireTraitChangeConsumables, Blacksmith } = state.contracts;
      if(!CryptoBlades || !CharacterFireTraitChangeConsumables || !Blacksmith || !state.defaultAccount) return;

      try {
        await SkillToken.methods
          .approve(CryptoBlades.options.address, web3.utils.toWei('' + price, 'ether'))
          .send({
            from: state.defaultAccount,
            gasPrice: getGasPrice(),
          });
      } catch(err) {
        console.error(err);
      }

      await Blacksmith.methods.purchaseCharacterFireTraitChange(Web3.utils.toWei('' + price)).send({
        from: state.defaultAccount,
        gas: '500000',
        gasPrice: getGasPrice(),
      });

      await Promise.all([
        dispatch('getAccountBalance'),
        dispatch('fetchTotalCharacterFireTraitChanges')
      ]);
    },
    async fetchTotalCharacterEarthTraitChanges({ state }) {
      const { CharacterEarthTraitChangeConsumables } = state.contracts;
      if(!CharacterEarthTraitChangeConsumables || !state.defaultAccount) return;
      return await CharacterEarthTraitChangeConsumables.methods.getItemCount().call(defaultCallOptions(state));
    },
    async purchaseCharacterEarthTraitChange({ state, dispatch }, {price}) {
      const { CryptoBlades, SkillToken, CharacterEarthTraitChangeConsumables, Blacksmith } = state.contracts;
      if(!CryptoBlades || !CharacterEarthTraitChangeConsumables || !Blacksmith || !state.defaultAccount) return;

      try {
        await SkillToken.methods
          .approve(CryptoBlades.options.address, web3.utils.toWei('' + price, 'ether'))
          .send({
            from: state.defaultAccount,
            gasPrice: getGasPrice(),
          });
      } catch(err) {
        console.error(err);
      }

      await Blacksmith.methods.purchaseCharacterEarthTraitChange(Web3.utils.toWei('' + price)).send({
        from: state.defaultAccount,
        gas: '500000',
        gasPrice: getGasPrice(),
      });

      await Promise.all([
        dispatch('getAccountBalance'),
        dispatch('fetchTotalCharacterEarthTraitChanges')
      ]);
    },
    async purchaseWeaponRenameTag({ state, dispatch }, {price}) {
      const { CryptoBlades, SkillToken, WeaponRenameTagConsumables, Blacksmith } = state.contracts;
      if(!CryptoBlades || !WeaponRenameTagConsumables || !Blacksmith || !state.defaultAccount) return;

      try {
        await SkillToken.methods
          .approve(CryptoBlades.options.address, web3.utils.toWei('' + price, 'ether'))
          .send({
            from: state.defaultAccount,
            gasPrice: getGasPrice(),
          });
      } catch(err) {
        console.error(err);
      }

      await Blacksmith.methods.purchaseWeaponRenameTag(Web3.utils.toWei('' + price)).send({
        from: state.defaultAccount,
        gas: '500000',
        gasPrice: getGasPrice(),
      });

      await Promise.all([
        dispatch('getAccountBalance'),
        dispatch('fetchTotalWeaponRenameTags')
      ]);
    },
    async purchaseWeaponRenameTagDeal({ state, dispatch }, {price}) {
      const { CryptoBlades, SkillToken, WeaponRenameTagConsumables, Blacksmith } = state.contracts;
      if(!CryptoBlades || !WeaponRenameTagConsumables || !Blacksmith || !state.defaultAccount) return;

      try {
        await SkillToken.methods
          .approve(CryptoBlades.options.address, web3.utils.toWei('' + price, 'ether'))
          .send({
            from: state.defaultAccount,
            gasPrice: getGasPrice(),
          });
      } catch(err) {
        console.error(err);
      }

      await Blacksmith.methods.purchaseWeaponRenameTagDeal(Web3.utils.toWei('' + price)).send({
        from: state.defaultAccount,
        gas: '500000',
        gasPrice: getGasPrice(),
      });

      await Promise.all([
        dispatch('getAccountBalance'),
        dispatch('fetchTotalRenameTags')
      ]);
    },
    async purchaseRenameTagDeal({ state, dispatch }, {price}) {
      const { CryptoBlades, SkillToken, CharacterRenameTagConsumables, Blacksmith } = state.contracts;
      if(!CryptoBlades || !CharacterRenameTagConsumables || !Blacksmith || !state.defaultAccount) return;

      try {
        await SkillToken.methods
          .approve(CryptoBlades.options.address, web3.utils.toWei('' + price, 'ether'))
          .send({
            from: state.defaultAccount,
            gasPrice: getGasPrice(),
          });
      } catch(err) {
        console.error(err);
      }

      await Blacksmith.methods.purchaseCharacterRenameTagDeal(Web3.utils.toWei('' + price)).send({
        from: state.defaultAccount,
        gas: '500000',
        gasPrice: getGasPrice(),
      });

      await Promise.all([
        dispatch('getAccountBalance'),
        dispatch('fetchTotalRenameTags')
      ]);
    },
    async purchaseRenameTag({ state, dispatch }, {price}) {
      const { CryptoBlades, SkillToken, CharacterRenameTagConsumables, Blacksmith } = state.contracts;
      if(!CryptoBlades || !CharacterRenameTagConsumables || !Blacksmith || !state.defaultAccount) return;

      try {
        await SkillToken.methods
          .approve(CryptoBlades.options.address, web3.utils.toWei('' + price, 'ether'))
          .send({
            from: state.defaultAccount,
            gasPrice: getGasPrice(),
          });
      } catch(err) {
        console.error(err);
      }

      await Blacksmith.methods.purchaseCharacterRenameTag(Web3.utils.toWei('' + price)).send({
        from: state.defaultAccount,
        gas: '500000',
        gasPrice: getGasPrice(),
      });

      await Promise.all([
        dispatch('getAccountBalance'),
        dispatch('fetchTotalRenameTags')
      ]);
    },
    async fetchTotalWeaponRenameTags({ state }) {
      const { WeaponRenameTagConsumables } = state.contracts;
      if(!WeaponRenameTagConsumables || !state.defaultAccount) return;
      return await WeaponRenameTagConsumables.methods.getItemCount().call(defaultCallOptions(state));
    },
    async fetchTotalRenameTags({ state }) {
      const { CharacterRenameTagConsumables } = state.contracts;
      if(!CharacterRenameTagConsumables || !state.defaultAccount) return;
      return await CharacterRenameTagConsumables.methods.getItemCount().call(defaultCallOptions(state));
    },
    async purchaseShield({ state, dispatch }, {price}) {
      try{
        const { CryptoBlades, SkillToken, Blacksmith } = state.contracts;
        if(!CryptoBlades || !Blacksmith || !state.defaultAccount) return;
        
        await SkillToken.methods
          .approve(CryptoBlades.options.address, web3.utils.toWei('' + price, 'ether'))
          .send({
            from: state.defaultAccount,
            gas: getGasPrice(),
          });

        const SHIELD_SEED = await Blacksmith.methods.SHIELD_SEED().call(defaultCallOptions(state));
        const VAR_PURCHASE_SHIELD_TYPE = await Blacksmith.methods.VAR_PURCHASE_SHIELD_TYPE().call(defaultCallOptions(state));
        const shieldType = await Blacksmith.methods.numberParameters(VAR_PURCHASE_SHIELD_TYPE).call(defaultCallOptions(state));

        if (!await Blacksmith.methods.hasSeed(SHIELD_SEED, shieldType).call(defaultCallOptions(state))) {
          await Blacksmith.methods.generateShieldSeed().send(defaultCallOptions(state));
        }
          
        await Blacksmith.methods.claimShield().send({
          from: state.defaultAccount,
          gas: getGasPrice(),
        });

        await Promise.all([
          dispatch('getAccountBalance'),
          dispatch('fetchTotalShieldSupply'),
          dispatch('updateShieldIds'),
        ]);
      // eslint-disable-next-line no-empty
      }catch(e){}
    },
    async updateShieldIds({ state, dispatch, commit }) {
      if(featureFlagStakeOnly) return;

      const ownedShieldIds = await state.contracts.Shields?.methods.getOwned().call(defaultCallOptions(state)) || [];
      commit('updateUserDetails', {
        ownedShieldIds: Array.from(ownedShieldIds)
      });
      await dispatch('fetchShields', ownedShieldIds);
    },
    async fetchTotalShieldSupply({ state }) {
      const { Shields } = state.contracts;
      if(!Shields || !state.defaultAccount) return;

      return await Shields.methods.totalSupply().call(defaultCallOptions(state));
    },
    async fetchList({dispatch}) {
      switch(router.currentRoute.params.tabName){
      case 'weapon':
        await dispatch('fetchWeaponsThroughAPIV2');
        break;
      case 'character':
        await dispatch('fetchCharactersThroughAPIV2');
        break;
      case 'shield':
        await dispatch('fetchShieldsAndArmorsThroughAPIV2');
        break;
      case 'junk':
        useBlockchain ? await dispatch('fetchJunkThroughChain') : await dispatch('fetchJunkThroughAPI');
      }
    },
    async fetchShieldsAndArmorsThroughChain({state,getters,dispatch,commit}) {
      commit('setFetchShieldListLoadingState', true);

      try {
        const shieldFilterParams = marketFilterToQueryDict(state.shieldListFilter);

        const totals = await dispatch('fetchNumberOfShieldListings', {
          nftContractAddr: getters.shieldContractAddress,
          trait: getElementNumberValueByName(shieldFilterParams.element.toString()),
          minLevel: CHARACTER_DEFAULT_MIN_LEVEL_FILTER,
          maxLevel: CHARACTER_DEFAULT_MAX_LEVEL_FILTER
        });

        const results = await dispatch('fetchAllMarketShieldNftIdsPage', {
          nftContractAddr: getters.shieldContractAddress,
          limit: PAGE_SIZE_LIMIT,
          pageNumber: getters.getShieldAndArmorListPagination.currentPage,
          trait: getElementNumberValueByName(shieldFilterParams.element.toString()),
          minLevel: CHARACTER_DEFAULT_MIN_LEVEL_FILTER,
          maxLevel: CHARACTER_DEFAULT_MAX_LEVEL_FILTER
        });
        
        const filteredResults = await dispatch('filterOutTargetBuyers',{list: results, contractAddress: getters.shieldContractAddress});

        commit('setShieldAndArmorListPagination', {
          pageSize: PAGE_SIZE_LIMIT,
          totalItems: totals
        });

        await dispatch('fetchShields',filteredResults);
        
        commit('setFetchShieldListLoadingState', false);
      } catch (e) {
        console.error(e);
        commit('setFetchShieldListLoadingState', false);
      }
    },
    
    async fetchWeaponsThroughChain({state,getters,dispatch,commit}) {
      commit('setFetchWeaponListLoadingState', true);
      const filterParams = marketFilterToQueryDict(state.weaponListFilter);
      
      try {
        const totals = await dispatch('fetchNumberOfWeaponListings',{
          nftContractAddr: getters.weaponContractAddress,
          trait: getElementNumberValueByName(filterParams.element.toString()),
          stars: filterParams.maxStars
        });
        const results = await dispatch('fetchAllMarketWeaponNftIdsPage',{
          nftContractAddr: getters.weaponContractAddress,
          limit: PAGE_SIZE_LIMIT,
          pageNumber: getters.getWeaponListPagination.currentPage,
          trait: getElementNumberValueByName(filterParams.element.toString()),
          stars: filterParams.maxStars
        });


        const filteredResults = await dispatch('filterOutTargetBuyers',{list: results, contractAddress: getters.weaponContractAddress});


        commit('setWeaponListPagination', {
          pageSize: PAGE_SIZE_LIMIT,
          totalItems: totals
        });
        
        await dispatch('fetchWeapons',filteredResults);

        commit('setFetchWeaponListLoadingState', false);
      } catch(e) {
        commit('setFetchWeaponListLoadingState', false);
      }
    },
    async fetchWeaponsThroughAPIV2({state, getters, dispatch, commit}) {
      store.commit('setFetchWeaponListLoadingState', true);

      try {
        store.commit('setGlobalFilter', {
          type: 'cb',
          subtype: 'weapon',
          seller: '',
          network: '',
        });
        const paginationFilter = `pageNum=${getters.getWeaponListPagination.currentPage}`;
        const filterParams = objToQueryParams(marketFilterToQueryDict(state.weaponListFilter));
        const marketFilter = objToQueryParams(state.globalBuyMarketFilter);
        const sorts = objToQueryParams(marketSortToQueryDict(state.globalSort));

        const queryParams = mergeQueryParams(filterParams, paginationFilter, marketFilter, sorts);
        const response = await fetch(apiUrl(`nfts${queryParams}`));
        const data = await response.json();
            
        commit('setWeaponListPagination', {
          pageSize: data.page.pageSize,
          totalItems: data.page.total
        });
        await dispatch('fetchWeapons', data.idResults);
        commit('setTotalItems', data.page.total);
        commit('setFetchWeaponListLoadingState', false);
      } catch (error) {
        commit('setFetchWeaponListLoadingState', false);
      }
    },
    async fetchJunkThroughChain({state,getters,dispatch,commit}) {
      commit('setFetchJunkListLoadingState', true);
      const filterParams = marketFilterToQueryDict(state.junkListFilter);
      
      try {
        const totals = await dispatch('fetchNumberOfJunkListings',{
          nftContractAddr: getters.junkContractAddress,
          trait: getElementNumberValueByName(filterParams.element.toString()),
          stars: filterParams.maxStars
        });
        const results = await dispatch('fetchAllMarketJunkNftIdsPage',{
          nftContractAddr: getters.junkContractAddress,
          limit: PAGE_SIZE_LIMIT,
          pageNumber: getters.getJunkListPagination.currentPage - 1,
          stars: filterParams.maxStars
        });


        const filteredResults = await dispatch('filterOutTargetBuyers',{list: results, contractAddress: getters.junkContractAddress});


        commit('setJunkListPagination', {
          pageSize: PAGE_SIZE_LIMIT,
          totalItems: totals
        });
        
        await dispatch('fetchJunks',filteredResults);

        commit('setFetchJunkListLoadingState', false);
      } catch(e) {
        commit('setFetchJunkListLoadingState', false);
      }
    },
    async fetchJunkThroughAPI({state, getters, dispatch, commit}) {
      store.commit('setFetchJunkListLoadingState', true);

      try {
        const paginationFilter = `pageNum=${getters.getJunkListPagination.currentPage - 1}`;
        const filterParams = objToQueryParams(marketFilterToQueryDict(state.junkListFilter));
        const marketFilter = objToQueryParams(state.globalBuyMarketFilter);

        const queryParams = mergeQueryParams(filterParams, paginationFilter, marketFilter);
        const response = await fetch(apiUrl(`static/market/junk${queryParams}`));
        const data = await response.json();
            
        commit('setJunkListPagination', {
          pageSize: data.page.pageSize,
          totalItems: data.page.total - 1
        });
        await dispatch('fetchJunks', data.idResults);
        commit('setTotalItems', data.page.total);
        commit('setFetchJunkListLoadingState', false);
      } catch (error) {
        commit('setFetchJunkListLoadingState', false);
      }
    },
    async lookupWeaponPrice({getters, dispatch},id: string) {
      if (!getters.weaponContractAddress) return;

      return await dispatch('fetchMarketNftPrice',{
        nftContractAddr: getters.weaponContractAddress,
        tokenId: id,
      });
    },
    async lookupCharacterPrice({getters, dispatch},id: string) {
      if (!getters.characterContractAddress) return;

      return await dispatch('fetchMarketNftPrice', {
        nftContractAddr: getters.characterContractAddress,
        tokenId: id,
      });
    },
    async lookupShieldPrice({getters, dispatch},id: string) {
      if (!getters.shieldContractAddress) return;

      return await dispatch('fetchMarketNftPrice',{
        nftContractAddr: getters.shieldContractAddress,
        tokenId: id,
      });
    },
    async lookupJunkPrice({getters, dispatch},id: string) {
      if (!getters.junkContractAddress) return;

      return await dispatch('fetchMarketNftPrice',{
        nftContractAddr: getters.junkContractAddress,
        tokenId: id,
      });
    },
    async purchaseWeapon({dispatch}, weaponId: string){
      const price = await dispatch('lookupWeaponPrice', weaponId) ;
      if(!price) return;

      await dispatch('purchaseWeaponListing',{
        tokenId: weaponId,
        maxPrice: price
      });
    },
    async filterOutTargetBuyers({state,dispatch},{list, contractAddress}) {
      if(!contractAddress) return [];
      const results: string[] = [];

      await Promise.all(list.map(async (nftId: string) => {
        const targetBuyer = await dispatch('lookupNftTargetBuyer', {tokenId: nftId, contractAddress: contractAddress});
        if(targetBuyer === DEFAULT_TARGET_BUYER || targetBuyer === state.defaultAccount) {
          results.push(nftId);
        }
      }));
      return results;
    },
    async lookupNftTargetBuyer({dispatch},{tokenId, contractAddress}) {
      if(!contractAddress) return;

      return await dispatch('fetchMarketNftTargetBuyer',{
        nftContractAddr: contractAddress,
        tokenId: tokenId,
      });
    },
    async initialize({ dispatch }) {
      await dispatch('setUpContracts');
    },
    async getMetamaskProvider({ dispatch }) {
      // check window ethereum provider
      if (ethProvider) {
        web3 = new Web3(ethProvider);

        try {
          await ethProvider.enable();
          web3Instance = web3 as any;
          await Promise.all([
            dispatch('initialize'),
            dispatch('loadProjects'),
            dispatch('loadProjectConfigs'),
            dispatch('initializeProjectTools'),
            dispatch('fetchUserGameDetails'),
          ]);
          
        // eslint-disable-next-line no-empty
        } catch(error) {}
      }
    },

    async getMetamaskInformation({ dispatch }) {
      // step 1: check window ethereum provider
      await dispatch('getMetamaskProvider');
      if (!web3Instance) {
        console.error('please install metamask');
        return;
      }

      // step 2: get account
      await dispatch('getMetamaskAccount');
    },

    async currentSkillPrice({ state }) {
      const { Merchandise } = state.contracts;
      if(!Merchandise || !state.defaultAccount) return;

      const skillOracle = await Merchandise.methods.skillOracle().call(defaultCallOptions(state));
      return await new web3.eth.Contract(priceOracleAbi as any[], skillOracle).methods
        .currentPrice().call(defaultCallOptions(state));
    },

    async canUserAfford({ state }, {payingAmount}) {
      const { CryptoBlades } = state.contracts;
      if(!CryptoBlades || !state.defaultAccount) return;

      const unclaimedSkill = await CryptoBlades.methods
        .getTokenRewardsFor(state.defaultAccount)
        .call(defaultCallOptions(state));

      const walletSkill = state.currentSkillBalance;

      const totalSkill = +unclaimedSkill + +walletSkill;

      return totalSkill >= payingAmount;
    },

    async createOrder({ state, dispatch }, {orderNumber, payingAmount}) {
      const { CryptoBlades, SkillToken, Merchandise } = state.contracts;
      if(!CryptoBlades || !SkillToken || !Merchandise || !state.defaultAccount) return;

      const skillNeeded = await CryptoBlades.methods
        .getSkillNeededFromUserWallet(state.defaultAccount, payingAmount, true)
        .call(defaultCallOptions(state));

      await approveFeeWalletOnly(
        CryptoBlades,
        SkillToken,
        state.defaultAccount,
        getGasPrice(),
        defaultCallOptions(state),
        defaultCallOptions(state),
        new BigNumber(skillNeeded)
      );

      await Merchandise.methods
        .createOrder(orderNumber, payingAmount)
        .send({
          from: state.defaultAccount,
          gasPrice: getGasPrice(),
        });

      dispatch('getAccountBalance');
    },

    async cancelMarketListing({ state }, { nftContractAddr, tokenId }: { nftContractAddr: string; tokenId: string }) {
      const { NFTMarket, Weapons, Characters, Shields, Junk } = state.contracts;
      if(!NFTMarket || !Weapons || !Characters || !Shields || !Junk) return;

      const res = await NFTMarket.methods
        .cancelListing(nftContractAddr, tokenId)
        .send({
          from: state.defaultAccount,
          gasPrice: getGasPrice(),
        });

      const {
        seller,
        nftID
      } = res.events.CancelledListing.returnValues;

      return { seller, nftID } as { seller: string; nftID: string };
    },

    async fetchAllMarketCharacterNftIdsPage({ state }, { nftContractAddr, limit, pageNumber, trait, minLevel, maxLevel }) {
      const { NFTMarket } = state.contracts;
      if(!NFTMarket) return;

      return await NFTMarket.methods
        .getCharacterListingIDsPage(
          nftContractAddr,
          limit,
          pageNumber,
          trait,
          minLevel,
          maxLevel
        )
        .call(defaultCallOptions(state));
    },
    async fetchNumberOfCharacterListings({ state }, { nftContractAddr, trait, minLevel, maxLevel }) {
      const { NFTMarket } = state.contracts;
      if(!NFTMarket) return;

      // returns an array of bignumbers (these are nft IDs)
      return await NFTMarket.methods
        .getNumberOfCharacterListings(
          nftContractAddr,
          trait,
          minLevel,
          maxLevel
        )
        .call(defaultCallOptions(state));
    },
    async fetchShieldsByIds({ state, dispatch }, shieldIds: (string | number)[]) {
      let shields: IShield[] = [];
      const { Shields } = state.contracts;
      const shieldsData = await dispatch('multicall', getNFTCall(shieldsAbi, Shields?.options.address, 'get', shieldIds.map(shieldId => [shieldId])));
      shields = shieldIds.map((id, i) => {
        return shieldFromContract(
          id,
          shieldsData[i]
        );
      });
      return shields;
    },
    async fetchShieldsPriceByIds({ state, dispatch }, shieldIds: (string | number)[]) {
      const { Shields, NFTMarket } = state.contracts;
      const shieldsPrice = await dispatch('multicall', getNFTCall(marketAbi, NFTMarket?.options.address, 'getFinalPrice', shieldIds.map(shieldId => [Shields?.options.address, shieldId])));
      return shieldsPrice;
    },
    async fetchShieldsSellerByIds({ state, dispatch }, shieldIds: (string | number)[]) {
      const { Shields, NFTMarket } = state.contracts;
      const shieldsSeller = await dispatch('multicall', getNFTCall(marketAbi, NFTMarket?.options.address, 'getSellerOfNftID', shieldIds.map(shieldId => [Shields?.options.address, shieldId])));
      return shieldsSeller;
    },
    async fetchShieldsBuyerByIds({ state, dispatch }, shieldIds: (string | number)[]) {
      const { Shields, NFTMarket } = state.contracts;
      const shieldsBuyer = await dispatch('multicall', getNFTCall(marketAbi, NFTMarket?.options.address, 'getTargetBuyer', shieldIds.map(shieldId => [Shields?.options.address, shieldId])));
      return shieldsBuyer;
    },
    async fetchShieldsFlagByIds({ state, dispatch }, shieldIds: (string | number)[]) {
      const { Shields } = state.contracts;
      const shieldsFlag = await dispatch('multicall', getNFTCall(shieldsAbi, Shields?.options.address, 'getNftVar', shieldIds.map(shieldId => [shieldId, 2])));
      return shieldsFlag;
    },
    async fetchShields({ commit, dispatch, getters }, shieldIds: (string | number)[]) {
      commit('clearShields');     
      const shields = await dispatch('fetchShieldsByIds', shieldIds);      
      const shieldsPrice = await dispatch('fetchShieldsPriceByIds', shieldIds);
      const shieldsSeller = await dispatch('fetchShieldsSellerByIds', shieldIds);
      const shieldsBuyer = await dispatch('fetchShieldsBuyerByIds', shieldIds);
      const shieldsFlag = await dispatch('fetchShieldsFlagByIds', shieldIds);
      const sellersStatus = await dispatch('fetchSellerStatus', shieldsSeller.map((seller: boolean[]) => seller[0]));
      const tempShields: IShield[] = [];

      shieldIds.forEach((shieldId, i) => {
        const price = +truncateDecimals(fromWeiEther(shieldsPrice[i]));
        shields[i].price = price;
        shields[i].sellerAddress = shieldsSeller[i][0];
        shields[i].targetBuyer = shieldsBuyer[i][0];
        shields[i].sellerStatus = sellersStatus[i][0];
        shields[i].shieldFlag = Number(shieldsFlag[i]);
        tempShields.push(shields[i]);
      });
      commit('setShieldList', tempShields);
      commit('setTotalItems', _.size(getters.shields));
    },
    async fetchShield({ state, commit, getters, dispatch }, shieldId: string | number) {
      const { Shields, NFTMarket } = state.contracts;
      if(!Shields || !NFTMarket) return;

      await Promise.all([
        (async () => {
          const shield = shieldFromContract(
            shieldId,
            await Shields.methods.get('' + shieldId).call(defaultCallOptions(state))
          );
          shield.price = +await NFTMarket.methods
            .getFinalPrice(
              Shields.options.address,
              shieldId
            )
            .call(defaultCallOptions(state));

          const price = +truncateDecimals(fromWeiEther(await dispatch('fetchMarketNftPrice',{nftContractAddr: getters.shieldContractAddress,tokenId: shieldId})));
          if(getters.getGlobalFilter.minPrice && getters.getGlobalFilter.maxPrice){
            if(price >= getters.getGlobalFilter.minPrice && price <= getters.getGlobalFilter.maxPrice){
              commit('updateShield', { shieldId, shield });
            }
          }else{
            commit('updateShield', { shieldId, shield });
          }
        })(),
      ]);
      
      dispatch('fetchShieldDurability', shieldId);
    },
    async fetchShieldDurability({ state, commit }, shieldId: number) {
      if(featureFlagStakeOnly) return;

      const durabilityString = await state.contracts.Shields?.methods
        .getDurabilityPoints('' + shieldId)
        .call(defaultCallOptions(state));

      const durability = parseInt(durabilityString || "", 10);
      if (state.shieldDurabilities[shieldId] !== durability) {
        commit('updateShieldDurability', { shieldId, durability });
      }
    },
    async getMetamaskAccount({ dispatch }) {
      web3 = new Web3(ethProvider);
      web3Instance = web3 as any;
      await web3Instance.eth.getAccounts()
        .then(async (accounts:any) => {
          if (accounts.length > 0) {
            await dispatch('getAccountBalance', accounts[0]);
            // 'Success to connect account'
          } else {
            // Failed to connect account'
          }
        })
        .catch((error: string) => {
          throw error;
        });
    },
    async getAccountBalance({ state, dispatch, commit }, account) {
      const accountToPass = account || state.defaultAccount;
      await dispatch('initialize');
      await web3Instance.eth.getBalance(toChecksumAddress(accountToPass))
        .then(async (balance: number) => {
          commit('setCurrentBNBBalance', Math.round(balance / (Math.pow(10, 18)) * 100) / 100);
          const skillBalance = await state.contracts.SkillToken?.methods
            .balanceOf(accountToPass)
            .call(defaultCallOptions(state));
          const skillRewards = await state.contracts.CryptoBlades?.methods
            .getTokenRewards()
            .call(defaultCallOptions(state));
          await dispatch('fetchInGameOnlyFunds');
          if (skillBalance) {
            commit('setCurrentSkillBalance',skillBalance);
          }
          if (skillRewards) {
            commit('setCurrentSkillRewards', skillRewards);
          }
        })
        .catch((error: string) => {
          throw error;
        });
    },
    async setUpContracts({ commit }) {
      const contracts = await setUpContracts(web3);
      commit('setContracts', contracts);
    },

    async purchaseWeaponListing({ state, dispatch }, { tokenId, maxPrice }: { nftContractAddr: string; tokenId: string; maxPrice: string }) {
      const { SkillToken,  NFTMarket, Weapons } = state.contracts;
      if(!SkillToken || !Weapons || !NFTMarket) return;

      await SkillToken.methods
        .approve(NFTMarket.options.address, maxPrice)
        .send({
          from: state.defaultAccount,
          gasPrice: getGasPrice(),
        });

      const res = await NFTMarket.methods
        .purchaseListing(Weapons.options.address, tokenId, maxPrice)
        .send({
          from: state.defaultAccount,
          gasPrice: getGasPrice(),
        });

      const {
        seller,
        nftID,
        price
      } = res.events.PurchasedListing.returnValues;

      await dispatch('fetchWeaponsFromContracts');
      await dispatch('fetchWeaponsThroughChain');

      return { seller, nftID, price } as { seller: string; nftID: string; price: string };
    },

    async purchaseJunkListing({ state, dispatch }, { tokenId, maxPrice }: { nftContractAddr: string; tokenId: string; maxPrice: string }) {
      const { SkillToken,  NFTMarket, Junk } = state.contracts;
      if(!SkillToken || !Junk || !NFTMarket) return;

      await SkillToken.methods
        .approve(NFTMarket.options.address, maxPrice)
        .send({
          from: state.defaultAccount,
          gasPrice: getGasPrice(),
        });

      const res = await NFTMarket.methods
        .purchaseListing(Junk.options.address, tokenId, maxPrice)
        .send({
          from: state.defaultAccount,
          gasPrice: getGasPrice(),
        });

      const {
        seller,
        nftID,
        price
      } = res.events.PurchasedListing.returnValues;

      await dispatch('fetchJunkFromContracts');
      await dispatch('fetchJunkThroughChain');

      return { seller, nftID, price } as { seller: string; nftID: string; price: string };
    },
    async getCBKLandPrice({state}, {tier}) {
      const { Blacksmith } = state.contracts;
      if(!Blacksmith) return;

      return await Blacksmith?.methods
        .getCBKLandPrice(tier, 1)
        .call(defaultCallOptions(state));
    },
    async fetchAllMarketWeaponNftIdsPage({ state }, { nftContractAddr, limit, pageNumber, trait, stars }) {
      const { NFTMarket } = state.contracts;
      if(!NFTMarket) return;

      return await NFTMarket.methods
        .getWeaponListingIDsPage(
          nftContractAddr,
          limit,
          pageNumber,
          trait,
          stars
        )
        .call(defaultCallOptions(state));
    },
    async fetchMarketNftIdsBySeller({ state }, { nftContractAddr, sellerAddr }) {
      const { NFTMarket } = state.contracts;
      if(!NFTMarket) return;

      // returns an array of bignumbers (these are nft IDs)
      return await NFTMarket.methods
        .getListingIDsBySeller(
          nftContractAddr,
          sellerAddr
        )
        .call(defaultCallOptions(state));
    },
    async fetchAllMarketShieldNftIdsPage({ state }, { nftContractAddr, limit, pageNumber, trait, stars }) {
      const { NFTMarket } = state.contracts;
      if(!NFTMarket) return;

      void trait;
      void stars;
      const res = await NFTMarket.methods
        .getListingSlice(
          nftContractAddr,
          pageNumber*limit, // startIndex
          limit // length
        )
        .call(defaultCallOptions(state));
      // returned values are: uint256 returnedCount, uint256[] ids, address[] sellers, uint256[] prices
      // res[1][] refers to ids, which is what we're looking for
      // this slice function returns the full length even if there are no items on that index
      // we must cull the nonexistant items
      const ids = [];
      for(let i = 0; i < res[1].length; i++) {
        if(res[1][i] !== '0' || res[3][i] !== '0') // id and price both 0, it's invalid
          ids.push(res[1][i]);
      }
      return ids;
    },
    async fetchAllMarketJunkNftIdsPage({ state }, { nftContractAddr, limit, pageNumber, stars }) {
      const { NFTMarket } = state.contracts;
      if(!NFTMarket) return;

      void stars;
      const res = await NFTMarket.methods
        .getListingSlice(
          nftContractAddr,
          pageNumber*limit, // startIndex
          limit // length
        )
        .call(defaultCallOptions(state));
      // returned values are: uint256 returnedCount, uint256[] ids, address[] sellers, uint256[] prices
      // res[1][] refers to ids, which is what we're looking for
      // this slice function returns the full length even if there are no items on that index
      // we must cull the nonexistant items
      const ids = [];
      for(let i = 0; i < res[1].length; i++) {
        if(res[1][i] !== '0' || res[3][i] !== '0') // id and price both 0, it's invalid
          ids.push(res[1][i]);
      }
      return ids;
    },
    async fetchNumberOfWeaponListings({state}, {nftContractAddr, trait, stars}) {
      const {NFTMarket} = state.contracts;
      if (!NFTMarket) return;

      // returns an array of bignumbers (these are nft IDs)
      return await NFTMarket.methods
        .getNumberOfWeaponListings(
          nftContractAddr,
          trait,
          stars
        ).call(defaultCallOptions(state));
    },
    async fetchNumberOfJunkListings({state}, {nftContractAddr, stars}) {
      const {NFTMarket} = state.contracts;
      if (!NFTMarket) return;

      void stars;
      return await NFTMarket.methods
        .getNumberOfListingsForToken(nftContractAddr)
        .call(defaultCallOptions(state));
    },
    async fetchNumberOfShieldListings({state}, {nftContractAddr, trait, stars}) {
      const {NFTMarket} = state.contracts;
      if (!NFTMarket) return;

      void trait;
      void stars;
      return await NFTMarket.methods
        .getNumberOfListingsForToken(nftContractAddr)
        .call(defaultCallOptions(state));
    },
    async reservedSalesAllowed({state}) {
      const { CBKLandSale } = state.contracts;
      if (!CBKLandSale) return;

      return await CBKLandSale.methods
        .reservedSalesAllowed()
        .call(defaultCallOptions(state));
    },
    async fetchIsLandSaleAllowed({state}) {
      const { CBKLandSale } = state.contracts;
      if (!CBKLandSale) return;

      return await CBKLandSale.methods
        .salesAllowed()
        .call(defaultCallOptions(state));
    },
    async fetchMarketNftPrice({ state }, { nftContractAddr, tokenId }) {
      const { NFTMarket } = state.contracts;
      if(!NFTMarket) return;

      return await NFTMarket.methods
        .getFinalPrice(
          nftContractAddr,
          tokenId
        )
        .call(defaultCallOptions(state));
    },
    async fetchWeaponsNftPrice({ state }, { tokenId }) {
      const { Weapons, NFTMarket } = state.contracts;
      if(!Weapons || !NFTMarket) return;

      return await NFTMarket.methods
        .getFinalPrice(
          Weapons.options.address,
          tokenId
        )
        .call(defaultCallOptions(state));
    },
    async fetchMarketNftTargetBuyer({ state }, { nftContractAddr, tokenId }) {
      const { NFTMarket } = state.contracts;
      if(!NFTMarket) return;

      // returns the listing's target buyer address
      return await NFTMarket.methods
        .getTargetBuyer(
          nftContractAddr,
          tokenId
        )
        .call(defaultCallOptions(state));
    },
    async fetchMarketNftSellerStatus({ state, dispatch }, { nftContractAddr, tokenId }) {
      const { NFTMarket } = state.contracts;
      if(!NFTMarket) return;

      const seller = await dispatch('fetchSellerOfNft', {nftContractAddr, tokenId});

      // returns the listing's seller status
      return await NFTMarket.methods
        .isUserBanned(seller)
        .call(defaultCallOptions(state));
    },
    async fetchUserGameDetails({ dispatch }) {
      if(featureFlagStakeOnly) return;

      await dispatch('fetchWeaponsFromContracts');
      await dispatch('fetchCharactersFromContracts');
      await dispatch('fetchShieldsFromContracts');
      await dispatch('fetchSpecialWeaponArts');
    },
    async getAccountCharacters({state}) {
      if(!state.defaultAccount) return;
      const numberOfCharacters = parseInt(await state.contracts.Characters?.methods.balanceOf(state.defaultAccount).call(defaultCallOptions(state)) || '', 10);
      const characters = await Promise.all(
        [...Array(numberOfCharacters).keys()].map((_, i) =>
          state.contracts.Characters?.methods.tokenOfOwnerByIndex(state.defaultAccount || "", i).call(defaultCallOptions(state)))
      );
      return characters;
    },
    async getAccountWeapons({state}) {
      if(!state.defaultAccount) return;
      const numberOfWeapons = parseInt(await state.contracts.Weapons?.methods.balanceOf(state.defaultAccount).call(defaultCallOptions(state)) || "", 10);
      const weapons = await Promise.all(
        [...Array(numberOfWeapons).keys()]
          .map((_, i) => state.contracts.Weapons?.methods.tokenOfOwnerByIndex(state.defaultAccount || "", i).call(defaultCallOptions(state)))
      );
      return weapons;
    },
    async purchaseCharactersListing({ state, dispatch }, { tokenId, maxPrice }: { nftContractAddr: string; tokenId: string; maxPrice: string }) {
      const { SkillToken,  NFTMarket, Characters } = state.contracts;
      if(!SkillToken || !Characters || !NFTMarket) return;

      await SkillToken.methods
        .approve(NFTMarket.options.address, maxPrice)
        .send({
          from: state.defaultAccount,
          gasPrice: getGasPrice(),
        });

      const res = await NFTMarket.methods
        .purchaseListing(Characters.options.address, tokenId, maxPrice)
        .send({
          from: state.defaultAccount,
          gasPrice: getGasPrice(),
        });

      const {
        seller,
        nftID,
        price
      } = res.events.PurchasedListing.returnValues;
      
      await dispatch('fetchCharactersFromContracts');
      await dispatch('fetchCharactersThroughChain');

      return { seller, nftID, price } as { seller: string; nftID: string; price: string };
    },
    async fetchCharactersNftPrice({ state }, { tokenId }) {
      const { Characters, NFTMarket } = state.contracts;
      if(!Characters || !NFTMarket) return;

      return await NFTMarket.methods
        .getFinalPrice(
          Characters.options.address,
          tokenId
        )
        .call(defaultCallOptions(state));
    },
    async fetchCharactersByIds({ state, dispatch }, characterIds: (string | number)[]) {
      let characters: ICharacter[] = [];
      const { Characters } = state.contracts;
      const charactersData = await dispatch('multicall', getNFTCall(charactersAbi, Characters?.options.address, 'get', characterIds.map(characterId => [characterId])));
      const NFTVAR_NON_GENESIS_VERSION = 3;
      const charactersVersionData = await dispatch('multicall', getNFTCall(charactersAbi, Characters?.options.address, 'getNftVar', characterIds.map(characterId => [characterId, NFTVAR_NON_GENESIS_VERSION])));
      characters = characterIds.map((id, i) => {
        return characterFromContract(
          id,
          charactersData[i],
          charactersVersionData[i]
        );
      });
      return characters;
    },    
    async fetchCharactersPriceByIds({ state, dispatch }, characterIds: (string | number)[]) {
      const { Characters, NFTMarket } = state.contracts;
      const charactersPrice = await dispatch('multicall', getNFTCall(marketAbi, NFTMarket?.options.address, 'getFinalPrice', characterIds.map(characterId => [Characters?.options.address, characterId])));
      return charactersPrice;
    },
    async fetchCharactersSellerByIds({ state, dispatch }, characterIds: (string | number)[]) {
      const { Characters, NFTMarket } = state.contracts;
      const charactersSeller = await dispatch('multicall', getNFTCall(marketAbi, NFTMarket?.options.address, 'getSellerOfNftID', characterIds.map(characterId => [Characters?.options.address, characterId])));
      return charactersSeller;
    },
    async fetchCharactersBuyerByIds({ state, dispatch }, characterIds: (string | number)[]) {
      const { Characters, NFTMarket } = state.contracts;
      const charactersBuyer = await dispatch('multicall', getNFTCall(marketAbi, NFTMarket?.options.address, 'getTargetBuyer', characterIds.map(characterId => [Characters?.options.address, characterId])));
      return charactersBuyer;
    },
    async fetchCharacters({ dispatch, commit, getters }, characterIds: (string | number)[]) {
      commit('clearCharacters'); 
      const characters = await dispatch('fetchCharactersByIds', characterIds);      
      const charactersPrice = await dispatch('fetchCharactersPriceByIds', characterIds);
      const charactersSeller = await dispatch('fetchCharactersSellerByIds', characterIds);
      const charactersBuyer = await dispatch('fetchCharactersBuyerByIds', characterIds);
      const sellersStatus = await dispatch('fetchSellerStatus', charactersSeller.map((seller: boolean[]) => seller[0]));
      const tempCharacters: ICharacter[] = [];

      characterIds.forEach((characterId, i) => {
        const price = +truncateDecimals(fromWeiEther(charactersPrice[i]));
        characters[i].price = price;
        characters[i].sellerAddress = charactersSeller[i][0];
        characters[i].targetBuyer = charactersBuyer[i][0];
        characters[i].sellerStatus = sellersStatus[i][0];
        tempCharacters.push(characters[i]);
      });
      
      commit('setCharacterList', tempCharacters);
      commit('setTotalItems', _.size(getters.characters));
    },
    async fetchCharacter({ state, commit, getters, dispatch }, characterId: string | number) {
      const { Characters } = state.contracts;
      if(!Characters) return;
      const NFTVAR_NON_GENESIS_VERSION = 3;
      await Promise.all([
        (async () => {
          const character = characterFromContract(
            characterId,
            await Characters.methods.get('' + characterId).call(defaultCallOptions(state)),
            await Characters.methods.getNftVar('' + characterId, NFTVAR_NON_GENESIS_VERSION).call(defaultCallOptions(state))
          );
          const price = +truncateDecimals(fromWeiEther(await dispatch('fetchMarketNftPrice',{nftContractAddr: getters.characterContractAddress,tokenId: characterId})));
          if(getters.getGlobalFilter.minPrice && getters.getGlobalFilter.maxPrice){
            if(price >= getters.getGlobalFilter.minPrice && price <= getters.getGlobalFilter.maxPrice){
              commit('updateCharacter', { characterId, character });
            }
          }else{
            commit('updateCharacter', { characterId, character });
          }
        })(),
      ]);
    },
    async purchaseShieldListing({ state, dispatch }, { tokenId, maxPrice }: { nftContractAddr: string; tokenId: string; maxPrice: string }) {
      const { SkillToken,  NFTMarket, Shields } = state.contracts;
      if(!SkillToken || !Shields || !NFTMarket) return;

      await SkillToken.methods
        .approve(NFTMarket.options.address, maxPrice)
        .send({
          from: state.defaultAccount,
          gasPrice: getGasPrice(),
        });

      const res = await NFTMarket.methods
        .purchaseListing(Shields.options.address, tokenId, maxPrice)
        .send({
          from: state.defaultAccount,
          gasPrice: getGasPrice(),
        });

      const {
        seller,
        nftID,
        price
      } = res.events.PurchasedListing.returnValues;
      
      await dispatch('fetchShieldsFromContracts');
      await dispatch('fetchShieldsAndArmorsThroughChain');

      return { seller, nftID, price } as { seller: string; nftID: string; price: string };
    },
    async fetchShieldsNftPrice({ state }, { tokenId }) {
      const { Shields, NFTMarket } = state.contracts;
      if(!Shields || !NFTMarket) return;

      return await NFTMarket.methods
        .getFinalPrice(
          Shields.options.address,
          tokenId
        )
        .call(defaultCallOptions(state));
    },
    async fetchWeaponsByIds({ state, dispatch }, weaponIds: (string | number)[]) {
      let weapons: IWeapon[] = [];
      const { Weapons } = state.contracts;
      const weaponsData = await dispatch('multicall', getNFTCall(weaponsAbi, Weapons?.options.address, 'get', weaponIds.map(weaponId => [weaponId])));
      weapons = weaponIds.map((id, i) => {
        return weaponFromContract(
          id,
          weaponsData[i]
        );
      });
      return weapons;
    },
    async fetchJunksByIds({ state, getters, dispatch }, junkIds: (string | number)[]) {
      let junks: IJunk[] = [];
      const { Junk } = state.contracts;
      const junkData = await dispatch('multicall', getNFTCall(junkAbi, Junk?.options.address, 'get', junkIds.map(junkId => [junkId])));
      junks = junkIds.map((id, i) => {
        return junkFromContract(
          id,
          junkData[i]
        );
      });
      return junks;
    },
    async fetchJunksPriceByIds({ state, dispatch }, junkIds: (string | number)[]) {
      const { Junk, NFTMarket } = state.contracts;
      const junksPrice = await dispatch('multicall', getNFTCall(marketAbi, NFTMarket?.options.address, 'getFinalPrice', junkIds.map(junkId => [Junk?.options.address, junkId])));
      return junksPrice;
    },
    async fetchJunksSellerByIds({ state, dispatch }, junkIds: (string | number)[]) {
      const { Junk, NFTMarket } = state.contracts;
      const junksSeller = await dispatch('multicall', getNFTCall(marketAbi, NFTMarket?.options.address, 'getSellerOfNftID', junkIds.map(junkId => [Junk?.options.address, junkId])));
      return junksSeller;
    },
    async fetchJunksBuyerByIds({ state, dispatch }, junkIds: (string | number)[]) {
      const { Junk, NFTMarket } = state.contracts;
      const junksBuyer = await dispatch('multicall', getNFTCall(marketAbi, NFTMarket?.options.address, 'getTargetBuyer', junkIds.map(junkId => [Junk?.options.address, junkId])));
      return junksBuyer;
    },
    async fetchWeaponsPriceByIds({ state, dispatch }, weaponIds: (string | number)[]) {
      const { Weapons, NFTMarket } = state.contracts;
      const weaponsPrice = await dispatch('multicall', getNFTCall(marketAbi, NFTMarket?.options.address, 'getFinalPrice', weaponIds.map(weaponId => [Weapons?.options.address, weaponId])));
      return weaponsPrice;
    },
    async fetchWeaponsSellerByIds({ state, dispatch }, weaponIds: (string | number)[]) {
      const { Weapons, NFTMarket } = state.contracts;
      const weaponsSeller = await dispatch('multicall', getNFTCall(marketAbi, NFTMarket?.options.address, 'getSellerOfNftID', weaponIds.map(weaponId => [Weapons?.options.address, weaponId])));
      return weaponsSeller;
    },
    async fetchWeaponsBuyerByIds({ state, dispatch }, weaponIds: (string | number)[]) {
      const { Weapons, NFTMarket } = state.contracts;
      const weaponsBuyer = await dispatch('multicall', getNFTCall(marketAbi, NFTMarket?.options.address, 'getTargetBuyer', weaponIds.map(weaponId => [Weapons?.options.address, weaponId])));
      return weaponsBuyer;
    },
    async fetchSellerStatus({ state, dispatch }, sellers: (string)[]) {
      const { NFTMarket } = state.contracts;
      const sellersStatus = await dispatch('multicall', getNFTCall(marketAbi, NFTMarket?.options.address, 'isUserBanned', sellers.map(seller => [seller])));
      return sellersStatus;
    },
    async fetchWeapons({ commit, dispatch, getters }, weaponIds: (string | number)[]) {
      commit('clearWeapons');  
      
      const weapons = await dispatch('fetchWeaponsByIds', weaponIds);      
      const weaponsPrice = await dispatch('fetchWeaponsPriceByIds', weaponIds);
      const weaponsSeller = await dispatch('fetchWeaponsSellerByIds', weaponIds);
      const weaponsBuyer = await dispatch('fetchWeaponsBuyerByIds', weaponIds);
      const sellersStatus = await dispatch('fetchSellerStatus', weaponsSeller.map((seller: boolean[]) => seller[0]));
      const tempWeapons: IWeapon[] = [];

      weaponIds.map((weaponId, i) => {
        const price = +truncateDecimals(fromWeiEther(weaponsPrice[i]));
        weapons[i].price = price;
        weapons[i].sellerAddress = weaponsSeller[i][0];
        weapons[i].targetBuyer = weaponsBuyer[i][0];
        weapons[i].sellerStatus = sellersStatus[i][0];
        tempWeapons.push(weapons[i]);
      });
      commit('setWeaponList', tempWeapons);
      
      commit('setTotalItems', _.size(getters.weapons));
    },
    async fetchWeapon({ state, commit, dispatch, getters }, weaponId: string | number) {
      const { Weapons } = state.contracts;
      if(!Weapons) return;

      await Promise.all([
        (async () => {
          const weapon = weaponFromContract(
            weaponId,
            await Weapons.methods.get('' + weaponId).call(defaultCallOptions(state))
          );
          const price = +truncateDecimals(fromWeiEther(await dispatch('fetchMarketNftPrice',{nftContractAddr: getters.weaponContractAddress,tokenId: weaponId})));
          if(getters.getGlobalFilter.minPrice && getters.getGlobalFilter.maxPrice){
            if(price >= getters.getGlobalFilter.minPrice && price <= getters.getGlobalFilter.maxPrice){
              commit('updateWeapon', { weaponId, weapon });
            }
          }else{
            commit('updateWeapon', { weaponId, weapon });
          }
        })()
      ]);
      
      dispatch('fetchWeaponDurability', weaponId);
    },
    async fetchWeaponDurability({ state, commit }, weaponId: number) {
      if(featureFlagStakeOnly) return;

      const durabilityString = await state.contracts.Weapons?.methods
        .getDurabilityPoints('' + weaponId)
        .call(defaultCallOptions(state));

      const durability = parseInt(durabilityString || "", 10);
      if (state.weaponDurabilities[weaponId] !== durability) {
        commit('updateWeaponDurability', { weaponId, durability });
      }
    },

    async addMarketListing({ state}, { nftContractAddr, tokenId, price, targetBuyer }:
      { nftContractAddr: string; tokenId: string; price: string; targetBuyer: string }) {
      const { CryptoBlades, SkillToken, NFTMarket, Weapons, Characters, Shields, Junk } = state.contracts;
      if(!CryptoBlades || !SkillToken || !NFTMarket || !Weapons || !Characters || !Shields || !Junk || !state.defaultAccount) return;

      const NFTContract: Contract<IERC721> =
          nftContractAddr === Weapons.options.address
            ? Weapons : nftContractAddr === Characters.options.address
              ? Characters : nftContractAddr === Shields.options.address ? Shields : Junk;

      await approveFeeDynamic(
        CryptoBlades,
        NFTMarket,
        SkillToken,
        state.defaultAccount,
        getGasPrice(),
        state.skillRewards,
        defaultCallOptions(state),
        defaultCallOptions(state),
        (nftMarketFuctions: { addFee: () => any }) => nftMarketFuctions.addFee(),
        { allowInGameOnlyFunds: false, allowSkillRewards: false },
      );
      
      await NFTContract.methods
        .approve(NFTMarket.options.address, tokenId)
        .send({
          from: state.defaultAccount,
          gasPrice: getGasPrice(),
        });
        

      const res = await NFTMarket.methods
        .addListing(nftContractAddr, tokenId, price, targetBuyer)
        .send({
          from: state.defaultAccount,
          gasPrice: getGasPrice(),
        });        

      const {
        seller,
        nftID
      } = res.events.NewListing.returnValues;

      return { seller, nftID, price } as { seller: string; nftID: string; price: string };
    },
    async configureMetaMask({ dispatch }) {
      web3 = new Web3(ethProvider);
      const currentNetwork = await web3.eth.net.getId();
      
      if(currentNetwork === +getConfigValue('VUE_APP_NETWORK_ID')) return;
      dispatch('configureChainNet', {
        networkId: +getConfigValue('VUE_APP_NETWORK_ID'),
        chainId: getConfigValue('chainId'),
        chainName: getConfigValue('VUE_APP_EXPECTED_NETWORK_NAME'),
        currencyName: getConfigValue('currencyName'),
        currencySymbol: getConfigValue('currencySymbol'),
        currencyDecimals: +getConfigValue('currencyDecimals'),
        rpcUrls: getConfigValue('rpcUrls'),
        blockExplorerUrls: getConfigValue('blockExplorerUrls'),
        skillAddress: getConfigValue('VUE_APP_SKILL_TOKEN_CONTRACT_ADDRESS')
      });
    },
    async configureChainNet(
      { commit },
      { networkId, chainId, chainName, currencyName, currencySymbol, currencyDecimals, rpcUrls, blockExplorerUrls, skillAddress }:
      { networkId: number;
        chainId: string;
        chainName: string;
        currencyName: string;
        currencySymbol: string;
        currencyDecimals: number;
        rpcUrls: string[];
        blockExplorerUrls: string[];
        skillAddress: string;
      })
    {
      web3 = new Web3(ethProvider);
      
      try {
        await (web3.currentProvider as any).request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId }],
        });
      } catch (switchError) {
        try {
          await (web3.currentProvider as any).request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId,
                chainName,
                nativeCurrency: {
                  name: currencyName,
                  symbol: currencySymbol,
                  decimals: currencyDecimals,
                },
                rpcUrls,
                blockExplorerUrls,
              },
            ],
          });
        } catch (addError) {
          console.error(addError);
          return;
        }
      }

      try {
        await (web3.currentProvider as any).request({
          method: 'wallet_watchAsset',
          params: {
            type: 'ERC20',
            options: {
              address: skillAddress,
              symbol: 'SKILL',
              decimals: 18,
              image: 'https://app.cryptoblades.io/android-chrome-512x512.png',
            },
          },
        });
      } catch (error) {
        console.error(error);
      }

      window.location.reload();
    },
    async fetchReputationLevelRequirements({state}) {
      const {SimpleQuests} = state.contracts;
      if (!SimpleQuests || !state.defaultAccount) return;

      const VAR_REPUTATION_LEVEL_2 = await SimpleQuests.methods.VAR_REPUTATION_LEVEL_2().call(defaultCallOptions(state));
      const VAR_REPUTATION_LEVEL_3 = await SimpleQuests.methods.VAR_REPUTATION_LEVEL_3().call(defaultCallOptions(state));
      const VAR_REPUTATION_LEVEL_4 = await SimpleQuests.methods.VAR_REPUTATION_LEVEL_4().call(defaultCallOptions(state));
      const VAR_REPUTATION_LEVEL_5 = await SimpleQuests.methods.VAR_REPUTATION_LEVEL_5().call(defaultCallOptions(state));

      const requirementsRaw = await SimpleQuests.methods.getVars([
        VAR_REPUTATION_LEVEL_2,
        VAR_REPUTATION_LEVEL_3,
        VAR_REPUTATION_LEVEL_4,
        VAR_REPUTATION_LEVEL_5,
      ]).call(defaultCallOptions(state));
      return {
        level2: +requirementsRaw[0],
        level3: +requirementsRaw[1],
        level4: +requirementsRaw[2],
        level5: +requirementsRaw[3]
      } as ReputationLevelRequirements;
    },
    async fetchCharacterQuestData({ state }, {characterId}) {
      const { SimpleQuests } = state.contracts;
      if(!SimpleQuests || !state.defaultAccount) return;

      const questId = await SimpleQuests.methods.characterQuest(characterId).call(defaultCallOptions(state));

      const quest = await SimpleQuests.methods.quests(questId).call(defaultCallOptions(state)) as unknown as {
        progress: string | number;
        id: string;
        tier: string;
        requirementType: string;
        requirementRarity: string;
        requirementAmount: string;
        requirementExternalAddress: string;
        rewardType: string;
        rewardRarity: string;
        rewardAmount: string;
        rewardExternalAddress: string;
        reputationAmount: string;
      };

      const charQuestDataRaw = await SimpleQuests.methods.getCharacterQuestData(characterId).call(defaultCallOptions(state));
      const emptyAccount = '0x0000000000000000000000000000000000000000';
      quest.progress = +charQuestDataRaw[0];
      if(quest.requirementExternalAddress !== emptyAccount
        && ((quest.requirementType && +quest.requirementType as RequirementType === RequirementType.EXTERNAL)
          || (quest.requirementType && +quest.requirementType as RequirementType === RequirementType.EXTERNAL_HOLD))) {
        const currencyContract = new web3.eth.Contract(erc20Abi as any[], quest.requirementExternalAddress);
        try{
          const currencyDecimals = await currencyContract.methods.decimals().call(defaultCallOptions(state));
          quest.requirementAmount = new BigNumber(quest.requirementAmount).div(new BigNumber(10 ** currencyDecimals)).toFixed();
          quest.progress = new BigNumber(quest.progress).div(new BigNumber(10 ** currencyDecimals)).toFixed();
        } catch {
          // Contract does not support decimals
        }
      }

      if(quest.rewardExternalAddress !== emptyAccount
        && +quest.rewardType as RewardType === RewardType.EXTERNAL) {
        const currencyContract = new web3.eth.Contract(erc20Abi as any[], quest.rewardExternalAddress);
        try{
          const currencyDecimals = await currencyContract.methods.decimals().call(defaultCallOptions(state));
          quest.rewardAmount = new BigNumber(quest.rewardAmount).div(new BigNumber(10 ** currencyDecimals)).toFixed();
        } catch {
          // Contract does not support decimals
        }
      }

      return {
        progress: +quest.progress,
        type: +charQuestDataRaw[1] as RequirementType,
        reputation: +charQuestDataRaw[2],
        id: +quest.id,
        tier: +quest.tier as Rarity,
        requirementType: +quest.requirementType as RequirementType,
        requirementRarity: +quest.requirementRarity as Rarity,
        requirementAmount: +quest.requirementAmount,
        requirementExternalAddress: quest.requirementExternalAddress,
        rewardType: +quest.rewardType as RewardType,
        rewardRarity: +quest.rewardRarity as Rarity,
        rewardAmount: +quest.rewardAmount,
        rewardExternalAddress: quest.rewardExternalAddress,
        reputationAmount: +quest.reputationAmount,
      } as Quest;
    },

    async multicall({state}, {abi, calls}) {
      const { MultiCall } = state.contracts;
      const itf = new Interface(abi);
      const data = calls.map((call: any) => [
        call.address.toLowerCase(),
        itf.encodeFunctionData(call.name, call.params),
      ]);
      const { returnData } = await MultiCall.methods.aggregate(data).call(defaultCallOptions(state));
      const res = returnData.map((call, i) => itf.decodeFunctionResult(calls[i].name, call));
      return res;
    },

    async getListingFee({state}) {
      const { NFTMarket } = state.contracts;
      return await NFTMarket?.methods.usdToSkill(await NFTMarket?.methods.addFee().call(defaultCallOptions(state))).call(defaultCallOptions(state));
    },

    async fetchUserRequiredGiveawayNft({state}, id) {
      const { PartnerGiveaways } = state.contracts;
      if(!PartnerGiveaways || !state.defaultAccount) return;

      const nftAddress = await PartnerGiveaways.methods.giveawayIdToAddress(id).call(defaultCallOptions(state));
      if(nftAddress === DEFAULT_TARGET_BUYER) return;
      // using weaponsAbi as a standard nft abi (IERC721 doesn't have tokenOfOwnerByIndex function)
      const nftContract = new web3.eth.Contract(weaponsAbi as any[], nftAddress);
      const userNftBalance = await nftContract.methods.balanceOf(state.defaultAccount).call(defaultCallOptions(state));
      if(+userNftBalance === 0) return '';
      return await nftContract.methods.tokenOfOwnerByIndex(state.defaultAccount, 0).call(defaultCallOptions(state));
    },

    async fetchUserClaimGiveawayInfo({state}, {giveawayId, nftId}) {
      const { PartnerGiveaways } = state.contracts;
      if(!PartnerGiveaways || !state.defaultAccount) return;

      return await PartnerGiveaways.methods.getGiveawayClaimInfoForUser(giveawayId, nftId).call(defaultCallOptions(state));
    },

    async claimGiveawayReward({state}, {giveawayId, nftId}) {
      const { PartnerGiveaways } = state.contracts;
      if(!PartnerGiveaways || !state.defaultAccount) return;

      await PartnerGiveaways.methods.claimReward(giveawayId, nftId).send({
        from: state.defaultAccount,
        gasPrice: getGasPrice(),
      });
    },

    async fetchGiveawayEndTime({state}, id) {
      const { PartnerGiveaways } = state.contracts;
      if(!PartnerGiveaways || !state.defaultAccount) return;
      
      return await PartnerGiveaways.methods.giveawayEndTime(id).call(defaultCallOptions(state));
    },

    async updateJunkIds({ state, dispatch, commit }) {
      if(featureFlagStakeOnly || !state.defaultAccount) return;

      const ownedJunkIds = await state.contracts.Junk?.methods.getOwned().call(defaultCallOptions(state)) || [];
      commit('updateUserDetails', {
        ownedShieldIds: Array.from(ownedJunkIds)
      });
      await dispatch('fetchJunks', ownedJunkIds);
    },

    async fetchJunks({ commit, dispatch, getters }, junkIds: (string | number)[]) {
      commit('clearJunks');  
      
      const junks = await dispatch('fetchJunksByIds', junkIds);      
      const junksPrice = await dispatch('fetchJunksPriceByIds', junkIds);
      const junksSeller = await dispatch('fetchJunksSellerByIds', junkIds);
      const junksBuyer = await dispatch('fetchJunksBuyerByIds', junkIds);
      const sellersStatus = await dispatch('fetchSellerStatus', junksSeller.map((seller: boolean[]) => seller[0]));
      const tempWeapons: IJunk[] = [];

      junkIds.map((junkId, i) => {
        const price = +truncateDecimals(fromWeiEther(junksPrice[i]));
        junks[i].price = price;
        junks[i].sellerAddress = junksSeller[i][0];
        junks[i].targetBuyer = junksBuyer[i][0];
        junks[i].sellerStatus = sellersStatus[i][0];
        tempWeapons.push(junks[i]);
      });
      commit('setJunkList', tempWeapons);
      
      commit('setTotalItems', _.size(getters.junks));
    },
  },
  getters : {
    getIGOFunds(state){
      return state.inGameOnlyFunds;
    },
    getMyListingsFilter(state){
      return {
        seller: state.globalBuyMarketFilter.seller,
        type: state.globalBuyMarketFilter.type,
        network: state.globalBuyMarketFilter.network,
      };
    },
    getNftConfigs(state){
      return state.nftConfigs;
    },
    getProjects(state){
      return state.projects;
    },
    getMerchandisePage(state) {
      return state.merchandisePage;
    },
    getCartEntries(state) {
      return state.cartEntries;
    },
    getSkillShopFilter(state){
      return state.skillShopFilter;
    },
    getMerchFilter(state){
      return state.merchFilter;
    },
    getMinMaxLevel(state){
      return {
        minLevel: state.minLevel >= 1 ? state.minLevel-1 : CHARACTER_DEFAULT_MIN_LEVEL_FILTER,
        maxLevel: state.maxLevel >= 1 ? state.maxLevel-1 : CHARACTER_DEFAULT_MAX_LEVEL_FILTER
      };
    },
    getTotalItems(state){
      return state.totalItems;
    },
    getGlobalFilter(state){
      return state.globalBuyMarketFilter;
    },
    getWeaponName(state: IState) {
      return (weaponId: number, stars: number) => {
        if(state.weaponRenames[weaponId] !== undefined) {
          return state.weaponRenames[weaponId];
        }

        return getWeaponNameFromSeed(weaponId, stars);
      };
    },
    getShieldDurability(state: IState) {
      return (shieldId: number) => {
        return state.shieldDurabilities[shieldId];
      };
    },
    getWeaponDurability(state: IState) {
      return (weaponId: number) => {
        return state.weaponDurabilities[weaponId];
      };
    },
    weaponContractAddress(state: IState) {
      return state.contracts && state.contracts.Weapons ? state.contracts.Weapons.options.address : null;
    },
    characterContractAddress(state: IState) {
      return state.contracts && state.contracts.Characters ? state.contracts.Characters.options.address : null;
    },
    shieldContractAddress(state: IState) {
      return state.contracts && state.contracts.Shields ? state.contracts.Shields.options.address : null;
    },
    junkContractAddress(state: IState) {
      return state.contracts && state.contracts.Junk ? state.contracts.Junk.options.address : null;
    },
    getCharacterName(state: IState) {
      return (characterId: number) => {
        if(state.characterRenames[characterId] !== undefined){
          return state.characterRenames[characterId];
        }
        return getCharacterNameFromSeed(characterId);
      };
    },
    getShieldListFilterState(state){
      return state.shieldListFilter;
    },
    getFetchWeaponlistLoadingState(state){
      return state.isFetchWeaponListLoading;
    },
    getLoadingState(state){
      return state.isLoading;
    },
    getFetchCharacterlistLoadingState(state){
      return state.isCharacterListLoading;
    },
    getFetchShieldAndArmorListLoadingState(state){
      return state.isFetchShieldAndArmorListLoading;
    },
    getTransactionHistoryPagination(state){
      return state.transactionHistoryPagination;
    },
    getFetchMerchlistLoadingState(state){
      return state.isFetchMerchListLoading;
    },
    getWeaponListPagination(state){
      return state.weaponListPagination;
    },
    getCharacterListPagination(state){
      return state.characterListPagination;
    },
    getNftListPagination(state){
      return state.nftListPagination;
    },
    getShieldAndArmorListPagination(state){
      return state.shieldAndArmorListPagination;
    },
    getJunkListPagination(state){
      return state.junkListPagination;
    },
    getMerchListPagination(state){
      return state.merchListPagination;
    },
    getMetamaskConnected(state){
      return state.metamaskConnected;
    },
    defaultAccount(state){
      return state.defaultAccount;
    },
    currentWalletAddress(state){
      return state.currentWalletAddress;
    },
    currentBNBBalance(state){
      return state.currentBNBBalance;
    },
    currentSkillBalance(state){
      return state.currentSkillBalance;
    },
    currentSkillRewards(state){
      return state.currentSkillRewards;
    },
    getTxnHistory(state){
      return state.txnHistory;
    },
    nfts(state){
      return state.nfts;
    },
    weapons(state){
      return state.weapons;
    },
    characters(state){
      return state.characters;
    },
    allShields(state){
      return state.shieldList;
    },
    shields(state){
      return state.shields;
    },
    junks(state){
      return state.junks;
    },
    charactersWithIds(state) {
      return (characterIds: (string | number)[]) => {
        const characters = characterIds.map(id => state.characters[+id]);
        if (characters.some((c) => c === null)) return [];
        return characters;
      };
    },
    weaponsWithIds(state) {
      return (weaponIds: (string | number)[]) => {
        const weapons = weaponIds.map(id => state.weapons[+id]);
        if (weapons.some((w) => w === null)) return [];
        return weapons;
      };
    },
    shieldsWithIds(state) {
      return (shieldIds: (string | number)[]) => {
        const shields = shieldIds.map(id => state.shields[+id]);
        if (shields.some((s) => s === null)) return [];
        return shields;
      };
    },
    contracts(state: IState) {
      // our root component prevents the app from being active if contracts
      // are not set up, so we never need to worry about it being null anywhere else
      return state.contracts;
    },
    getCurrentWeapon(state: IState) {
      return state.currentWeapon;
    },
    getCurrentCharacter(state: IState) {
      return state.currentCharacter;
    },
    getCurrentChainSupportsMarket(state) {
      return state.currentChainSupportsMarket;
    },
    showMetamaskWarning() {
      web3 = new Web3(ethProvider);
      return web3.eth.currentProvider === null;
    },
  }
});
