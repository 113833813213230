















import Vue from 'vue';
import {ICharacter} from "@/interface/character.interface";
import CloseModalButton from "@/components/modal/CloseModalButton.vue";
import CharacterDisplay from "@/components/modal/CharacterDisplay.vue";
import {BModal} from "bootstrap-vue";
import { mapActions } from 'vuex';
import { fromWeiEther } from "@/utils/common";

export default Vue.extend({
  data() {
    return {
      character: {} as ICharacter,
      type: '' as 'listed' | 'price-updated' | 'purchased' | 'cancelled',
      isSellModal: false,
      isEditing: false,
      listingFee: '0',
    };
  },
  components: {
    CloseModalButton,
    CharacterDisplay,
  },
  methods: {
    ...mapActions(
      [
        'getListingFee'
      ]
    ),
  },
  mounted() {
    this.$root.$on('character-view-modal', async (character: ICharacter, type: 'listed' | 'price-updated' | 'purchased' | 'cancelled', isSellModal: boolean, isEditing: boolean) => {
      const modal = this.$refs['character-view-modal'] as BModal;
      if (modal) {
        this.character = character;
        this.type = type;
        this.isSellModal = isSellModal;
        this.isEditing = isEditing;
        if (isSellModal) {
          this.listingFee = parseFloat(fromWeiEther(await this.getListingFee())).toFixed(5);
        }
        if (character) {
          modal.show();
        } else {
          modal.hide();
        }
      }
    });
    this.$root.$on('close-modal', () => {
      const modal = this.$refs['character-view-modal'] as BModal;
      modal.hide();
    });
  }
});

