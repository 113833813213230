












































import Vue, { PropType } from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import { totalBattlePower, addCommas, setRarityName, setRarityColor, elementToStat } from '@/utils/item.utils';
import { IWeapon } from '@/interface/weapon.interface';
import ElementTrait from '../ElementTrait.vue';
import { getWeaponNameFromSeed } from '../../weapon-names';
import { getWeaponArt } from '../../weapon-arts-placeholder';
import SellItem from '@/components/modal/SellItem.vue';

export default Vue.extend({
  props: {
    isSellModal: {
      type: Boolean
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    weapon: {
      type: Object as PropType<IWeapon>,
      required: false
    },
  },
  computed:{
    ...mapState(['specialWeaponArts']),
    ...mapGetters(['weaponContractAddress', 'getWeaponDurability']),
  },
  components:{
    ElementTrait,
    SellItem
  },
  methods: {
    ...mapActions(['addMarketListing']),
    totalBattlePower,
    addCommas,
    setRarityName,
    setRarityColor,
    getWeaponNameFromSeed,
    elementToStat,
    getWeaponArt,
  },
  data() {
    return {
      listingSellPrice: '',
      targetBuyerAddress: '',
    };
  }
});
