







































































































































import Vue from "vue";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { getCleanName } from "@/utils/rename-censor";
import { getCharacterArt } from "@/utils/character-arts-placeholder";
import { CHARACTER_MAX_STAMINA } from "@/default/character.default";
import { fromWeiEther } from "@/utils/common";
import { BModal } from "bootstrap-vue";
import { timestampToStamina } from "@/utils/character.utils";
import CurrencyConverter from "./CurrencyConverter.vue";
import CBIcon from "./CBIcon.vue";
import ElementTrait from "./ElementTrait.vue";
import { ReputationTier, ReputationLevelRequirements } from '@/interface/simple-quests.interface';
import MilitaryRankIcon from '@/assets/9081218_military_rank_icon.png';

interface CharacterItem {
  sellerAddress: string;
  characterPower: number;
  baseCharacterPower: number;
  price: string;
  truncatePrice: string;
  untruncatedPrice: string;
  CHARACTER_MAX_STAMINA: number;
  reputation: number;
  reputationLevelRequirements: ReputationLevelRequirements;
  reputationIcon: string;
}
import { DEFAULT_TARGET_BUYER } from "@/default/common.default";
import { ICharacter,RequiredXp } from "@/interface/character.interface";

export default Vue.extend({
  name: "CharacterItem",
  components: { CurrencyConverter, CBIcon, ElementTrait },
  data() {
    return {
      characterPower: 0,
      baseCharacterPower: 0,
      truncatePrice: "loading..",
      untruncatedPrice: "loading..",
      CHARACTER_MAX_STAMINA: CHARACTER_MAX_STAMINA,
      reputation: 0,
      reputationLevelRequirements: '',
      DEFAULT_TARGET_BUYER,
      reputationIcon: MilitaryRankIcon,
    } as unknown as CharacterItem;
  },
  props: {
    nft:{
      type: Object,
      required: true
    },
    contractAddress:{
      type: String,
      required: true,
    },
    nftType:{
      type: String,
      required: true,
    }
  },
  async created() {
    await this.fetchReputation(this.nft.id);
    this.price = await this.lookupCharacterPrice(this.nft.id);
    this.totalCharacterPower();
    this.defaultCharacterPower();
  },
  methods: {
    RequiredXp,
    getPurchaseTooltipMsg(): string {
      if (this.nft.sellerStatus) {
        return "NFT is blacklisted";
      }
      if (!this.isValidForPurchase) {
        return "You are not the target buyer.";
      }
      return "";
    },
    ...mapActions([
      "purchaseCharactersListing",
      "fetchCharactersNftPrice",
      "fetchMarketNftPrice",
      "fetchMarketNftTargetBuyer",
      "fetchMarketNftSellerStatus",
      "cancelMarketListing",
      "lookupCharacterPrice",
      "getTotalCharacterPower",
      "getBaseCharacterPower",
      "fetchReputationLevelRequirements",
    ]),
    ...mapMutations(["setCurrentCharacter"]),

    async fetchReputation(characterId: number){
      const questData = await this.$store.dispatch('fetchCharacterQuestData', {characterId});

      this.reputationLevelRequirements = await this.fetchReputationLevelRequirements();

      this.reputation = questData.reputation;

    },
    showSellItemModal() {
      this.setCurrentCharacter(this.nft.id);
      (this.$refs["sellItem"] as BModal).show();
    },
    hideSellItemModal() {
      (this.$refs["sellItem"] as BModal).hide();
    },
    getCharacterArt,
    timestampToStamina(timestamp: number) {
      if (timestamp > Math.floor(Date.now() / 1000)) return 0;
      return +Math.min(
        (Math.floor(Date.now() / 1000) - timestamp) / 300,
        200
      ).toFixed(0);
    },
    openModal(
      modal: string,
      character: ICharacter,
      type: string,
      isSellModal: boolean,
      isEditing?: boolean
    ) {
      this.$root.$emit(modal, character, type, isSellModal, isEditing);
    },
    async lookupTargetBuyer(id: number) {
      if (!this.contractAddress) return;

      return await this.fetchMarketNftTargetBuyer({
        nftContractAddr: this.contractAddress,
        tokenId: id
      });
    },
    async lookupSellerStatus(id: number) {
      if (!this.contractAddress) return;

      return await this.fetchMarketNftSellerStatus({
        nftContractAddr: this.contractAddress,
        tokenId: id
      });
    },
    async purchaseCharacter() {
      const price = await this.lookupCharactersPrice(this.nft.id);
      const targetBuyer = await this.lookupTargetBuyer(this.nft.id);
      const sellerStatus = await this.lookupSellerStatus(this.nft.id);

      /* Call component modal */
      if (!price) {
        this.openModal("character-view-modal", this.nft, "sold", false);
        return;
      }
      if (Number(price) > Number(this.currentSkillBalance)) {
        return this.openModal(
          "character-view-modal",
          this.nft,
          "not-enough-skill-purchase",
          false
        );
      }
      if (targetBuyer !== DEFAULT_TARGET_BUYER) {
        if (targetBuyer.toUpperCase() !== this.defaultAccount.toUpperCase()) {
          return this.openModal(
            "character-view-modal",
            this.nft,
            "target-buyer",
            false
          );
        }
      }
      if (sellerStatus) {
        return this.openModal("character-view-modal", this.nft, "banned", false);
      }

      /* this is only called when all filters passed */
      try {
        await this.purchaseCharactersListing({
          tokenId: this.nft.id,
          maxPrice: price
        }).then(() => {
          this.openModal(
            "character-view-modal",
            this.nft,
            "purchased",
            false
          );
        });
        /* call error modal stating "An error occurred, please try again later." (must console log the error) */
        // eslint-disable-next-line no-empty
      } catch (error) {}
    },
    staminaToolTipHtml() {
      return `STA ${timestampToStamina(
        +this.nft.staminaTimestamp
      )} / ${CHARACTER_MAX_STAMINA}`;
    },
    getCleanCharacterName(id: number) {
      return getCleanName(this.getCharacterName(id));
    },
    async lookupCharactersPrice(id: number) {
      return await this.fetchCharactersNftPrice({
        tokenId: id
      });
    },
    convertWeiToSkill(wei: string) {
      return fromWeiEther(wei);
    },
    cancelListing() {
      this.$root.$emit(
        "confirm-modal",
        "Are you sure you want to cancel character listing?",
        async () => {
          let result = await this.cancelMarketListing({
            nftContractAddr: this.contractAddress,
            tokenId: this.nft.id
          });
          if (result) {
            this.$root.$emit(`refresh-${this.nftType}-inventory`);
            this.$root.$emit(
              `character-view-modal`,
              this.nft,
              "cancelled",
              false,
              false
            );
          }
        }
      );
    },
    async totalCharacterPower() {
      this.characterPower = await this.getTotalCharacterPower(
        this.nft.id
      );
      return this.characterPower;
    },
    async defaultCharacterPower() {
      this.baseCharacterPower = await this.getBaseCharacterPower(
        this.nft.id
      );
      return this.baseCharacterPower;
    }
  },
  computed: {
    ...mapState(["defaultAccount"]),
    ...mapGetters([
      "getCharacterName",
      "currentSkillBalance"
    ]),
    isValidForPurchase(): boolean {
      return (
        this.nft.targetBuyer === DEFAULT_TARGET_BUYER ||
        this.nft.targetBuyer.toUpperCase() ===
          this.defaultAccount.toUpperCase()
      );
      // return false;
    },
    getReputation(){
      if (!this.reputationLevelRequirements) return;
      if (this.reputation < this.reputationLevelRequirements.level2) {
        return ReputationTier.PEASANT;
      } else if (this.reputation < this.reputationLevelRequirements.level3) {
        return ReputationTier.TRADESMAN;
      } else if (this.reputation < this.reputationLevelRequirements.level4) {
        return ReputationTier.NOBLE;
      } else if (this.reputation < this.reputationLevelRequirements.level5) {
        return ReputationTier.KNIGHT;
      } else {
        return ReputationTier.KING;
      }
    },
    stamina(): number {
      return (
        (timestampToStamina(+this.nft.staminaTimestamp) /
          CHARACTER_MAX_STAMINA) *
        100
      );
    }
  }
});
