






















































































import Vue from "vue";
import CurrencyConverter from "@/components/CurrencyConverter.vue";
import { fromWeiEther } from "@/utils/common";
import { mapMutations } from "vuex";
import {
  CartEntry,
  Product,
  Variant,
  File,
  FileType
} from "@/interface/merchandise.interface";

interface StoreMappedMutations {
  addCartEntry(cartEntry: CartEntry): void;
}

interface Data {
  variants: Variant[];
  selectedVariant?: Variant;
  product?: Product;
  quantity: number;
  retailPriceInSkill: number;
  totalPrice: number;
  totalPriceInSkill: number;
  skillPrice: number;
  showModal: boolean;
}

export default Vue.extend({
  components: { CurrencyConverter },
  data() {
    return {
      variants: [],
      selectedVariant: undefined,
      product: undefined,
      quantity: 1,
      retailPriceInSkill: 0,
      totalPrice: 0,
      totalPriceInSkill: 0,
      skillPrice: 0,
      showModal: false
    } as Data;
  },

  props: {
    showFiatPrices: {
      type: Boolean
    }
  },

  computed: {
    isMinusButtonDisabled() {
      return this.$data.quantity <= 1;
    }
  },

  methods: {
    ...(mapMutations(["addCartEntry"]) as StoreMappedMutations),
    fromWeiEther,
    addToCart() {
      if (!this.product || !this.selectedVariant) return;

      const cartEntry = {
        product: this.product,
        variant: this.selectedVariant,
        quantity: this.quantity
      } as CartEntry;

      this.addCartEntry(cartEntry);
      this.quantity = 1;
      this.showModal = false;
    },
    loadPreviewThumbnail() {
      if (!this.selectedVariant) return;
      const image = this.selectedVariant.files.find(
        file =>
          this.isFileTypeDefault(file) ||
          this.isFileTypeBack(file) ||
          this.isFileTypeEmbroideryChestLeft(file) ||
          this.isFileTypeEmbroideryChestRight(file) ||
          this.isFileTypeEmbroideryChestCenter(file)
      );
      return image ? image.thumbnail_url : "";
    },
    isFileTypePreview(file: File) {
      return file.type === FileType.PREVIEW;
    },
    isFileTypeDefault(file: File) {
      return file.type === FileType.DEFAULT;
    },
    isFileTypeBack(file: File) {
      return file.type === FileType.BACK;
    },
    isFileTypeEmbroideryChestLeft(file: File) {
      return file.type === FileType.EMBROIDERY_CHEST_LEFT;
    },
    isFileTypeEmbroideryChestRight(file: File) {
      return file.type === FileType.EMBROIDERY_CHEST_RIGHT;
    },
    isFileTypeEmbroideryChestCenter(file: File) {
      return file.type === FileType.EMBROIDERY_CHEST_CENTER;
    },
    selectVariant(variant: Variant) {
      this.selectedVariant = variant;
      this.calculateTotalPrice();
    },
    subtractQuantity() {
      this.quantity--;
      this.calculateTotalPrice();
    },
    addQuantity() {
      this.quantity++;
      this.calculateTotalPrice();
    },
    calculateTotalPrice() {
      if (!this.selectedVariant) return;
      this.totalPrice = +this.selectedVariant.retail_price * this.quantity;
      this.calculateTotalSkillPrice();
    },
    calculateTotalSkillPrice() {
      if (!this.selectedVariant) return;
      this.retailPriceInSkill =
        +this.selectedVariant.retail_price * this.skillPrice;
      this.totalPriceInSkill = this.retailPriceInSkill * this.quantity;
    },
    usdToSkill(cartEntry: CartEntry) {
      if (!cartEntry?.variant) return;
      return +cartEntry.variant.retail_price * this.skillPrice;
    }
  },

  async mounted() {
    this.$root.$on(
      "merchandise-variant-modal",
      async (product: Product, variants: Variant[], skillPrice: number) => {
        this.product = product;
        if (this.product) {
          this.variants = variants;
          this.skillPrice = skillPrice;
          this.selectedVariant = this.variants[0];
          this.showModal = true;
          this.calculateTotalPrice();
        } else {
          this.variants = [];
          this.selectedVariant = undefined;
          this.showModal = false;
        }
      }
    );
  }
});
