



























































import { store } from '@/store';
import Vue, { PropType } from 'vue';
import { 
  getRarityValueByName,
  getRarityNameByValue
} from "./../default/rarity.default";

import { 
  IElementFilter,
  IFilterV2,
  IMarketFilter,
  IMarketSkillShopFilter,
  IMarketMerchandiseFilter
} from "./../interface/filters.interface";

import { mapMutations, mapActions, mapGetters } from 'vuex';
import { CHARACTER_DEFAULT_MAX_LEVEL_FILTER, CHARACTER_DEFAULT_MIN_LEVEL_FILTER } from '@/default/character.default';

import { SkillShopFilters } from '@/default/nft-shop-list.default';
import { INameValue } from '@/interface/common.interface';

import { MerchFilters } from '@/default/merch-list.default';

export const ELEMENT_FILTER_TOGGLE_CLASSNAME = "element-filter__toggle";
export const ELEMENT_CLASSNAME = "element-";
export const CHECKBOX_INPUT_CLASSNAME = "-checkbox__input";

//@TODO add interface for this
export default Vue.extend({
  props:{
    filters: {
      type: Object as PropType<Record<string, IFilterV2>>,
      required: false
    },
  },
  name: 'MarketFilter',
  store : store,

  data: function(){
    return{
      elements: [] as IElementFilter[],
      activeUppderMidTab: "w",
      activeBottomTab: 'f',
      rarityList: [] as Array<INameValue<number>>,
      skillShopFilters: SkillShopFilters,
      merchFilters: MerchFilters,
      selectedSkillShopFilter: [],
      skillShopFilter: {
        category: []
      } as IMarketSkillShopFilter,
      selectedCategory: [] as string[],
      selectedSubcategory: [] as string[],
      filter: {
        elementFilter: [],
        rarityFilter: [],
        minPrice: 0,
        maxPrice: Number.MAX_SAFE_INTEGER,
        minLevel: CHARACTER_DEFAULT_MIN_LEVEL_FILTER,
        maxLevel: CHARACTER_DEFAULT_MAX_LEVEL_FILTER,
        category: [],
      } as IMarketFilter,
      merchFilter: {
        category: [],
        subcategory: []
      } as IMarketMerchandiseFilter,
      className: {
        elementFilter: ELEMENT_FILTER_TOGGLE_CLASSNAME,
        checkboxInput: CHECKBOX_INPUT_CLASSNAME
      },
    };
  },
  mounted() {
    for (var key in this.filters) {
      if(key === 'element'){
        this.filters[key].props?.values?.forEach((element: string, index: number) => {
          this.elements.push({
            id: index,
            el: element.toLowerCase(),
            isToggled: false,
          });
        });
      }else if(key === 'stars'){
        this.filters[key].props?.values?.forEach((element: string) => {
          this.rarityList.push({
            name: element,
            value: this.getRarityValueByName(element)
          });
        });
      }

    }

    const tabName = this.$route?.meta?.tabName;
    //init data from local storage
    if(localStorage.getItem(tabName)){
      this.filter = JSON.parse(localStorage.getItem(tabName) || '');
    }
    if(localStorage.getItem("skillShopFilter")){
      const skillShopFilter = JSON.parse(localStorage.getItem("skillShopFilter") || '');
      this.selectedSkillShopFilter = skillShopFilter.category;
    }
    if(localStorage.getItem("merchFilter")){
      const merchFilter = JSON.parse(localStorage.getItem("merchFilter") || '');
      this.selectedCategory = merchFilter.category;
      this.selectedSubcategory = merchFilter.subcategory;
    }
  },
  computed:{
    ...mapGetters(['getFetchMerchlistLoadingState','getFetchWeaponlistLoadingState', 'getFetchCharacterlistLoadingState','getFetchShieldAndArmorListLoadingState']),
    isFetching(): boolean{
      return this.getFetchWeaponlistLoadingState || this.getFetchCharacterlistLoadingState || this.getFetchShieldAndArmorListLoadingState || this.getFetchMerchlistLoadingState;
    }
  },
  methods:{
    ...mapActions(['fetchList']),
    ...mapMutations(['setMinMaxLevel','setSkillShopFilter']), 
    getRarityValueByName,
    getRarityNameByValue,
    checkCategory(){
      let selectedSubcategory: string[] = [];

      const category = this.selectedCategory;
      const merchFilter = JSON.parse(localStorage.getItem("merchFilter") || '');

      if(category.length === 0){
        selectedSubcategory = merchFilter.subcategory;
      }

      if(category.includes('Apparel')){
        selectedSubcategory.push('Tops','Sweatshirts');
      }
      if(category.includes('Accessories')){
        selectedSubcategory.push('Hats','Bags','Socks','Stickers', 'Phone Cases', 'Mouse Pads', 'Notebooks');
      }
      if(category.includes('Home & Living')){
        selectedSubcategory.push('Drinkware','Blankets','Posters','Canvas Prints');
      }

      selectedSubcategory.push(...merchFilter.subcategory);


      const filteredSubcategory = selectedSubcategory.filter((subcategory, pos)=> selectedSubcategory.indexOf(subcategory) === pos);

      const updatedMerchFilter: IMarketMerchandiseFilter = {
        category: category,
        subcategory: filteredSubcategory
      };

      localStorage.setItem('merchFilter', JSON.stringify(updatedMerchFilter)); 
      this.selectedCategory = updatedMerchFilter.category;
      this.selectedSubcategory = updatedMerchFilter.subcategory;

      this.checkSubcategory();

    },

    checkSubcategory(){

      let apparelCount = 0;
      const subcategories = this.selectedSubcategory;
      const selectedCategory: string[] = [];


      MerchFilters[0].child.forEach((child)=>{
        if(subcategories.includes(child.name)){
          ++apparelCount;
        }
      });

      if(apparelCount === MerchFilters[0].child.length){
        selectedCategory.push('Apparel');
      } else {
        selectedCategory.filter((category)=> category !== 'Apparel');
      }

      const updatedMerchFilter: IMarketMerchandiseFilter = {
        category: selectedCategory,
        subcategory: subcategories
      };

      localStorage.setItem('merchFilter', JSON.stringify(updatedMerchFilter)); 
      
      this.selectedCategory = updatedMerchFilter.category;
      this.selectedSubcategory = updatedMerchFilter.subcategory;
      
    },
    capitalize(value: string){
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    hasSkillShopCategory(){
      return this.$route?.meta?.tabName === 'skillshop';
    },
    hasMerchCategory(){
      return this.$route.name === 'Merchandise';
    },
    clickedFilter(x:string){
      if(x==='f') {
        this.$root.$emit('filter-value', this.filter, true);
        this.$root.$emit('toggle', false);
      }
      if(this.$route?.meta?.tabName === 'skillshop'){
        const skillShopFilter: IMarketSkillShopFilter = {
          category: this.selectedSkillShopFilter
        };
        localStorage.setItem('skillShopFilter', JSON.stringify(skillShopFilter));
        store.commit('setSkillShopFilter', {skillShopFilter: skillShopFilter});
      }
      if(this.$route.name === 'Merchandise'){
        const merchFilter: IMarketMerchandiseFilter = {
          category: this.selectedCategory,
          subcategory: this.selectedSubcategory
        };
        localStorage.setItem('merchFilter', JSON.stringify(merchFilter));
        store.commit('setMerchFilter', {merchFilter: merchFilter});
        this.$root.$emit('filter-value-merchandise');
      }
      this.activeBottomTab=x;
    },
    setActive(tab:string,types:string){
      if(types==='upperMidBtn'){
        if(tab===this.activeUppderMidTab){
          return 'active-upper-mid-btn';
        }else{
          return 'inactive-upper-mid-btn';
        }
      }else if(types==='bottomBtn'){
        if(tab===this.activeBottomTab){
          return 'active-bottom-tab';
        }else{
          return 'inactive-bottom-tab';
        }
      }
    },
    getElement(stat:boolean,el:string){
      if(stat){
        return el;
      }else{
        return el+'-off';
      }
    },
    resetFilterValue() {
      this.filter = {
        elementFilter: [],
        rarityFilter: [],
        minPrice: 0,
        maxPrice: Number.MAX_SAFE_INTEGER,
      } as IMarketFilter;
    }
  },
});

