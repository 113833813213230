import { IShield } from "@/interface/shield.interface";
import { IWeapon } from "@/interface/weapon.interface";

export function setRarityName(rarity: number): string | undefined {
  switch(rarity) {
  case 4: {
    return 'Legendary';
  }
  case 3: {
    return 'Mythical';
  }
  case 2: {
    return 'Epic';
  }
  case 1: {
    return 'Rare';
  }
  case 0: {
    return 'Normal';
  }
  default:{
    return 'Unknown';
  }
  }
}
  
export function setRarityColor(rarity: number): string | undefined {
  switch(rarity) {
  case 4: {
    return 'background-color:#D16100 !important';
  }
  case 3: {
    return 'background-color:#7C1EC1 !important';
  }
  case 2: {
    return 'background-color:#7ba224 !important';
  }
  case 1: {
    return 'background-color:#3997F5 !important';
  }
  case 0: {
    return 'background-color:#43506A !important';
  }
  default:{
    return 'background-color:#43506A !important';
  }
  }
}

export function addCommas(nStr: string): string {
  nStr += '';
  const x = nStr.split('.');
  let x1 = x[0];
  const x2 = x.length > 1 ? '.' + x[1] : '';
  const rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1' + ',' + '$2');
  }
  return x1 + x2;
}

export function totalBattlePower(weapon: IWeapon | IShield): number {
  return (+weapon.stat1Value) + (+weapon.stat2Value) + (+weapon.stat3Value);
}

export function elementToStat(element: string) : string {
  switch(element){
  case 'PWR':
    return 'power';
    break;
  case 'STR':
    return 'fire';
    break;
  case 'DEX':
    return 'earth';
    break;
  case 'CHA':
    return 'lightning';
    break;
  case 'INT':
    return 'water';
    break;
  default:
    return '';
    break;
  }
}
