















































































































































































import Vue from "vue";
import api from "@/api";
import { fromWeiEther } from "@/utils/common";
import CurrencyConverter from "@/components/CurrencyConverter.vue";
import {
  CartEntry,
  Country,
  State,
  Recipient
} from "@/interface/merchandise.interface";

interface Data {
  selectedCountry?: Country;
  selectedState?: State;
  countries: Country[];
  recipient: Recipient;
  cartEntries: CartEntry[];
  showModal: boolean;
}

export default Vue.extend({
  components: { CurrencyConverter },

  data() {
    return {
      selectedCountry: undefined,
      selectedState: undefined,
      countries: [],
      recipient: {} as Recipient,
      cartEntries: [],
      showModal: false
    } as Data;
  },

  props: {
    totalPriceInSkill: {
      type: Number
    }
  },

  computed: {
    emailState() {
      return (
        this.$data.recipient.email && this.$data.recipient.email.includes("@")
      );
    },
    shippingInformationComplete(): boolean {
      return (
        this.$data.recipient.name &&
        this.$data.recipient.email &&
        this.$data.recipient.phone &&
        ((this.$data.selectedCountry &&
          (!this.$data.selectedCountry.states ||
            this.$data.selectedCountry.states.length === 0)) ||
          (this.$data.selectedCountry &&
            this.$data.selectedCountry.states &&
            this.$data.selectedCountry.states.length !== 0 &&
            this.$data.selectedState)) &&
        this.$data.recipient.address1 &&
        this.$data.recipient.city &&
        !!this.$data.recipient.zip &&
        ((this.$data.selectedCountry &&
          this.$data.selectedCountry.code !== "BR") ||
          (this.$data.recipient.tax_number &&
            this.$data.recipient.tax_number.length === 11))
      );
    }
  },

  methods: {
    fromWeiEther,

    countryChanged() {
      this.selectedState = undefined;
    },

    async fetchCountries() {
      const response = await api.getMerchandiseCountries();
      if (response.code !== 200) {
        return;
      }
      this.countries = response.result;
    },

    async buyItem() {
      if (!this.selectedCountry) return;
      this.recipient.country_code = this.selectedCountry.code;
      this.recipient.state_code = this.selectedState?.code;
      const taxNumber = this.recipient.tax_number;
      if (taxNumber) {
        this.recipient.tax_number = this.convertToCPFFormat(taxNumber);
      }
      this.$root.$emit("order-summary-modal", this.recipient, this.cartEntries);
    },

    convertToCPFFormat(taxNumber: string) {
      const taxNumberArray = Array.from(taxNumber);
      taxNumberArray.splice(3, 0, ".");
      taxNumberArray.splice(7, 0, ".");
      taxNumberArray.splice(11, 0, "-");
      return taxNumberArray.join("");
    }
  },

  async mounted() {
    await this.fetchCountries();
  }
});
