










































































import { getConfigValue } from '@/contracts';
import { secondsToDDHHMMSS } from '@/utils/common';
import Vue from 'vue';
import {mapActions, mapGetters, mapState} from "vuex";
import Spinner from '../dumb/cryptoblades-spinner.vue';

export default Vue.extend({
  components: {
    Spinner
  },

  data() {
    return {
      ownedNftId: '',
      isClaiming: false,
      isLoading: false,
      isRewardClaimedForUser: false,
      isRewardClaimedForNft: false,
      giveawayEndTime: -1,
      endsIn: 0,
      updateEndTimeInverval: undefined as ReturnType<typeof setInterval> | undefined
    };
  },
  computed:{
    ...mapState(
      [
        'defaultAccount',
      ]
    ),
    ...mapGetters(['characterContractAddress','getTxnHistory']),

    isEligible(): boolean {
      return !!this.ownedNftId;
    },

    endsInFormatted(): string {
      return secondsToDDHHMMSS(+this.endsIn.toFixed());
    },

    networkId(): string {
      return getConfigValue('VUE_APP_NETWORK_ID');
    }
  },
  methods:{
    ...mapActions(['fetchUserRequiredGiveawayNft', 'fetchUserClaimGiveawayInfo',
      'claimGiveawayReward', 'fetchGiveawayEndTime']),

    async updateIsRewardClaimed() {
      try {
        this.isLoading = true;
        this.giveawayEndTime = +await this.fetchGiveawayEndTime(0);
        if(!this.giveawayEndTime) return;
        this.endsIn = this.giveawayEndTime - Date.now()/1000;
        this.ownedNftId = await this.fetchUserRequiredGiveawayNft(0);
        if(!this.ownedNftId) return;
        const claimedInfo = await this.fetchUserClaimGiveawayInfo({giveawayId: 0, nftId: this.ownedNftId});
        this.isRewardClaimedForUser = claimedInfo.didUserClaim;
        this.isRewardClaimedForNft = claimedInfo.didNftClaim;
      }
      finally {
        this.isLoading = false;
      }
    },

    async claimReward() {
      try {
        this.isClaiming = true;
        await this.claimGiveawayReward({giveawayId: 0, nftId: this.ownedNftId});
      }
      finally {
        this.isClaiming = false;
        await this.updateIsRewardClaimed();
      }
    }
  },

  async mounted() {
    await this.updateIsRewardClaimed();
    this.updateEndTimeInverval = setInterval(() => {
      if(this.endsIn > 0) this.endsIn--;
    }, 1000);
  },

  destroyed() {
    if(this.updateEndTimeInverval) {
      clearInterval(this.updateEndTimeInverval);
    }
  }
});
