
















import Vue, { PropType } from 'vue';
import { mapGetters } from 'vuex';
import { addCommas, setRarityName, setRarityColor } from '@/utils/item.utils';
import { IJunk } from '@/interface/junk.interface';
import { getJunkArt } from '../../junk-arts-placeholder';
import SellItem from '@/components/modal/SellItem.vue';

export default Vue.extend({
  props: {
    isSellModal: {
      type: Boolean
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    junk: {
      type: Object as PropType<IJunk>,
      required: false
    },
  },
  computed:{
    ...mapGetters(['junkContractAddress']),
  },
  components:{
    SellItem
  },
  methods: {
    addCommas,
    setRarityName,
    setRarityColor,
    getJunkArt,
  }
});
