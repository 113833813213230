


















import Vue from 'vue';
import {mapActions, mapGetters, mapMutations} from 'vuex';
import config from './../../../app-config.json';
import { getConfigValue } from '@/contracts';
import { BModal } from 'bootstrap-vue';

export default Vue.extend({
  data() {
    return {
      config,
      selectedChain: ''
    };
  },
  computed:{
    ...mapGetters(['contracts', 'showMetamaskWarning', 'getLoadingState']),
    ...mapMutations(['updateCurrentChainSupportsMarket'])
  },
  methods: {
    ...mapActions(['configureMetaMask']),
    async checkChainAndParams(chain: string){
      this.selectedChain = chain;
      const supportedChains = config.supportedChains;
      if(supportedChains.includes(this.selectedChain)) 
      {
        localStorage.setItem('currentChain', this.selectedChain);
        await this.configureMetaMask(+getConfigValue('VUE_APP_NETWORK_ID'));
      } 
    }
  },
  mounted() {
    this.selectedChain = localStorage.getItem('currentChain') || 'BNB';

    this.$root.$on('options-modal', async () => {
      const modal = this.$refs['options-modal'] as BModal;
      modal.show();
    });
    this.$root.$on('close-modal', () => {
      const modal = this.$refs['options-modal'] as BModal;
      modal.hide();
    });
  }
});
  
