import { render, staticRenderFns } from "./cryptoblades-spinner.vue?vue&type=template&id=31878eac&scoped=true&"
import script from "./cryptoblades-spinner.vue?vue&type=script&lang=ts&"
export * from "./cryptoblades-spinner.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31878eac",
  null
  
)

export default component.exports