



import power from '@/assets/nav-icons/power.svg';
import earth from '@/assets/nav-icons/earth.png';
import fire from '@/assets/nav-icons/fire.png';
import lightning from '@/assets/nav-icons/lightning.png';
import water from '@/assets/nav-icons/water.png';
import Vue from 'vue';
export default Vue.extend({
  data(){
    return{
      imageSrc: '',
      power,
    };
  },
  props:{
    width:{
      type: Number,
      required: false,
      default: 10,
    },
    traitName:{
      type: String,
      required: false,
    },
    isPopover:{
      required: false,
      default: true,
      type: Boolean,
    }
  },
  created(){
    this.getElementImage();
  },
  methods:{
    getElementImage(){
      if(!this.traitName) return;
      switch(this.traitName.toLowerCase()){
      case 'power':
        this.imageSrc = power;
        break;
      case 'earth':
        this.imageSrc = earth;
        break; 
      case 'water':
        this.imageSrc = water;
        break; 
      case 'fire':
        this.imageSrc = fire;
        break; 
      case 'lightning':
        this.imageSrc = lightning;
        break; 
      default:
        break;
      }
      
    }
  }
});
