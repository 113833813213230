














import Vue from 'vue';
import detectEthereumProvider from "@metamask/detect-provider";
import {store} from "@/store";
import {Tab, tabs} from "@/views/Sell.vue";

export default Vue.extend({
  data() {
    return {
      tabs,
      currentTab: tabs[0],
    };
  },
  methods: {
    handleOnClick(tab: Tab) {
      this.currentTab = tab;
      this.$emit('selectEvent', tab);
    },
    async logOut() {
      try {
        const provider = await detectEthereumProvider() as any;
        if (provider) {
          const accounts = await provider.request({
            method: 'eth_requestAccounts',
            params: [{eth_accounts: {}}]
          });
          store.commit('setDefaultAccount', accounts[0]);
          store.commit('setMetamaskConnected', false);
          store.commit('clearWeapons');
        }
      } catch (err) {
        console.error('Logout Failure:', err);
      }
    },
  },
});
