

















































































































import Vue from 'vue';
import {
  mapActions,
  mapGetters,
  mapMutations
} from 'vuex';
import WalletBalance from './components/WalletBalance.vue';
import vSelect from 'vue-select';
import { nftList } from './default/nft-shop-list.default';
import Fuse from 'fuse.js';
import { BuyPageRouteParamEnum, BuyTabsRouteMap } from './utils/buy-page-routes';
import ConfirmModal from "@/components/modal/ConfirmModal.vue";
import CharacterDisplay from "@/components/modal/CharacterDisplay.vue";
import CloseModalButton from "@/components/modal/CloseModalButton.vue";
import ShieldDisplay from "@/components/modal/ShieldDisplay.vue";
import CharacterViewModal from "@/components/modal/CharacterViewModal.vue";
import ShieldViewModal from "@/components/modal/ShieldViewModal.vue";
import WeaponViewModal from "@/components/modal/WeaponViewModal.vue";
import JunkViewModal from "@/components/modal/JunkViewModal.vue";
import OptionsModal from "@/components/modal/OptionsModal.vue";
import Search from '@/components/Search.vue';
import config from '../app-config.json';
import { getConfigValue } from './contracts';
import MetaMaskOnboarding from '@metamask/onboarding';
Vue.component("v-select", vSelect);

type SellType = 'weapon' | 'character' | 'shield';

interface SearchResult {
  name: string;
  section: string;
  isSectionTitle?: boolean;
  id?: number | string;
}

enum Section {
  Character = 'character',
  Weapon = 'weapon',
  Shield = 'shield',
  Skill = 'skill'
}

export default Vue.extend({
  components: {
    Search,
    CloseModalButton,
    WalletBalance,
    CharacterDisplay,
    ShieldDisplay,
    CharacterViewModal,
    ShieldViewModal,
    WeaponViewModal,
    ConfirmModal,
    JunkViewModal,
    OptionsModal
  },
  name: 'App',
  data: () => {
    return{
      menuIsToggled: false,
      isSearch: false,
      modal: "",
      modalData: "",
      searchInput: "",
      characterSearchResults: [] as SearchResult[],
      weaponSearchResults: [] as SearchResult[],
      shieldSearchResults: [] as SearchResult[],
      skillShopSearchResults: [] as SearchResult[],
      selectedInSearch: null as SearchResult | null,
      isLoading: false,
      loaded: true,
      isConnected: false,
      config,
      selectedChain: 'Select chain',
      filters: false,
    };
  },
  computed:{
    ...mapGetters(['contracts', 'showMetamaskWarning', 'getLoadingState']),
    checkChainConfig(){
      const chain = localStorage.getItem('currentChain') || '';
      return config.supportedChains.includes(chain);
    }
  },
  mounted(){
    this.$root.$on('modal', (data:any) => {
      this.modal = data.modalName;
      this.modalData = data.modalData;
    });
  },
  methods:{
    ...mapActions(['fetchMarketNftPrice', 'fetchWeapon', 'configureMetaMask']),
    ...mapMutations(['updateCurrentChainSupportsMarket']),
    toggleFilter() {
      this.filters = !this.filters;
      this.$root.$emit('toggle', this.filters);
    },
    configChain(chain: string) {
      this.selectedChain = chain;
    },
    async startOnboarding() {
      const onboarding = new MetaMaskOnboarding();
      onboarding.startOnboarding();
    },
    async checkChainAndParams() {
      const currentChain = this.selectedChain;
      const lastConnectedChain = localStorage.getItem('currentChain');
      const supportedChains = config.supportedChains;

      if (currentChain || lastConnectedChain){
        if(supportedChains.includes(currentChain)) localStorage.setItem('currentChain', currentChain);
        await this.configureMetaMask(+getConfigValue('VUE_APP_NETWORK_ID'));
        this.updateCurrentChainSupportsMarket();
      }
    },
    showOptionsModal(){
      this.$root.$emit('options-modal');
    },
    toggleMenu(){
      this.menuIsToggled = !this.menuIsToggled;
    },
    showSearch(){
      this.isSearch = !this.isSearch;
    },
    closeModal(){
      this.modal = "";
    },
    isBuyPage() {
      return this.$route.matched[0]?.name === "Buy";
    },
    isMerch(){
      return this.$route.name === 'Merchandise';
    }
  },
  async created(){
    this.checkChainAndParams();
  }
});

