










import Vue from 'vue';

export default Vue.extend({
  props: [
    'totalRows', 
    'perPage',
    'page'
  ],
  name: 'CryptobladesPagination',
  components: {},
  data() {
    return { 
      itemsPerPage : this.perPage,
		  totalItems: this.totalRows,
		  curPage: this.page
	  };
  },
  methods: {
    onChangePage(page: number) {
      this.$emit('changes', page);
    }
  }
});
